import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faShare } from "@fortawesome/pro-light-svg-icons";
import { faClipboardCheck } from "@fortawesome/pro-light-svg-icons";
import { faCommentPlus } from "@fortawesome/pro-light-svg-icons";
import { faUserCheck } from "@fortawesome/pro-light-svg-icons";
import { faGavel } from "@fortawesome/pro-light-svg-icons";
import { faCalendarDay } from "@fortawesome/pro-light-svg-icons";
import { faPiggyBank } from "@fortawesome/pro-light-svg-icons";
import { faHourglassEnd } from "@fortawesome/pro-light-svg-icons";
import { faStopwatch } from "@fortawesome/pro-light-svg-icons";
import { faDice } from "@fortawesome/pro-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { faHandshakeAlt } from "@fortawesome/pro-solid-svg-icons";
import { faFlagCheckered } from "@fortawesome/pro-solid-svg-icons";
import { faHandHoldingUsd } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { GameTimeDisplay } from "jumbo/components/shared/game-time-display";
import ShareButtonLong2 from "jumbo/components/share-button-long2";
import Alert from "jumbo/components/alert";

import LoadingIndicator from "./loading-indicator";

export interface Props {
  game: Game;
  user?: JumboUser;
  stats?: Map<string, number>;
  userStats?: Map<string, number>;
  username?: string;
}

const GameLobby: React.FC<Props> = ({
  game,
  user,
  stats,
  userStats,
  username,
}) => {
  const isGameRunning = game.end_time
    ? new Date(game.end_time) > new Date()
    : true;
  const isOwner = user && game.user.id === user.id;
  const isAdmin = user && user.profile.is_admin;

  const hasPaypal = user && user.profile.paypal_username !== null;

  return (
    <>
      {user ? (
        <>
          {isGameRunning ? (
            <>
              {isOwner ? (
                <>
                  {game.props.length === 0 ? (
                    <Alert
                      className="alert-custom4c"
                      style={{ paddingBottom: 10, paddingTop: 10 }}
                    >
                      <div className="text-left">
                        <div
                          className="text-left  binary"
                          style={{ paddingTop: 10 }}
                        >
                          <FontAwesomeIcon
                            className="wager2"
                            size="lg"
                            icon={faStar}
                          />
                          <FontAwesomeIcon
                            className="star-opacity"
                            size="lg"
                            icon={faStar}
                          />
                          <FontAwesomeIcon
                            className="star-opacity"
                            size="lg"
                            icon={faStar}
                          />
                        </div>

                        <div
                          className="text-left binary"
                          style={{ paddingTop: 10 }}
                        >
                          <span className="nav-color-dull small-details-text">
                            Your Game Quality
                          </span>
                        </div>
                        <div
                          className="text-left lobbytext nav-love"
                          style={{ paddingTop: 15, paddingBottom: 10 }}
                        >
                          Let's set up your game! <br />
                          <br />
                          Tap&nbsp;&nbsp;
                          <b className="wager2">
                            <FontAwesomeIcon icon={faCommentPlus} />
                            &nbsp;Add&nbsp;
                          </b>{" "}
                          below to add your first question. <br />
                          <span className="small nav-color-dull">(2 of 4)</span>
                        </div>
                      </div>
                    </Alert>
                  ) : null}
                </>
              ) : null}
              {isOwner ? (
                <>
                  {game.props.length === 1 ? (
                    <Alert
                      className="alert-custom4c"
                      style={{ paddingBottom: 10, paddingTop: 10 }}
                    >
                      <div className="text-left">
                        <div
                          className="text-left  binary"
                          style={{ paddingTop: 10 }}
                        >
                          <FontAwesomeIcon
                            className="wager2"
                            size="lg"
                            icon={faStar}
                          />
                          <FontAwesomeIcon
                            className="wager2"
                            size="lg"
                            icon={faStar}
                          />
                          <FontAwesomeIcon
                            className="star-opacity"
                            size="lg"
                            icon={faStar}
                          />
                        </div>

                        <div
                          className="text-left binary"
                          style={{ paddingTop: 10 }}
                        >
                          <span className="nav-color-dull small-details-text">
                            Your Game Quality
                          </span>
                        </div>
                        <div
                          className="text-left lobbytext nav-love"
                          style={{ paddingTop: 15, paddingBottom: 10 }}
                        >
                          Clever! The best games have 2 or more questions.{" "}
                          <b className="wager2">Add&nbsp;one more</b> to
                          finish.&nbsp;
                          <br />
                          <span className="nav-color-dull small">(3 of 4)</span>
                        </div>
                      </div>
                    </Alert>
                  ) : null}
                </>
              ) : null}
              {isOwner ? (
                <>
                  {game.props.length > 1 ? (
                    <Alert
                      className="alert-custom4c"
                      style={{ paddingBottom: 10, paddingTop: 10 }}
                      dismissible
                    >
                      <div className="text-left">
                        <div
                          className="text-left  binary"
                          style={{ paddingTop: 10 }}
                        >
                          <FontAwesomeIcon
                            className="wager2"
                            size="lg"
                            icon={faStar}
                          />
                          <FontAwesomeIcon
                            className="wager2"
                            size="lg"
                            icon={faStar}
                          />
                          <FontAwesomeIcon
                            className="wager2"
                            size="lg"
                            icon={faStar}
                          />
                        </div>

                        <div
                          className="text-left binary"
                          style={{ paddingTop: 10 }}
                        >
                          <span className="nav-color-dull small-details-text">
                            Your Game Quality
                          </span>
                        </div>
                        <div
                          className="text-left lobbytext nav-love"
                          style={{ paddingTop: 15, paddingBottom: 10 }}
                        >
                          Your game is ready for action! Wagers from other
                          players will start coming in soon. <br />
                          <br />
                          Tap &nbsp;
                          <b className="wager2">
                            <FontAwesomeIcon icon={faShare} /> Invite
                          </b>{" "}
                          &nbsp;to share your game with friends.
                          <br />
                          <br />
                          Once the event is over, don't forget to come back and
                          &nbsp;
                          <FontAwesomeIcon
                            className="nav-love"
                            icon={faGavel}
                          />
                          <b className="nav-love">&nbsp;&nbsp;Grade</b> your
                          game!
                          <br />
                          <span className="small nav-color-dull">
                            &nbsp;&nbsp;(4 of 4)
                          </span>
                        </div>
                      </div>
                    </Alert>
                  ) : null}
                </>
              ) : null}
            </>
          ) : (
            <>
              {isOwner ? (
                <>
                  {stats ? (
                    <>
                      {stats.get("game_winner") ? (
                        <Alert className="alert-success" dismissible>
                          <div className="text-left">
                            <FontAwesomeIcon icon={faFlagCheckered} />
                            &nbsp;&nbsp;You've successfully graded your game!{" "}
                            <Link to={`/game/${game.id}/details`}>
                              <u>Tap here</u>
                            </Link>{" "}
                            to see the final results.
                          </div>
                        </Alert>
                      ) : (
                        <Alert className="alert-danger" dismissible>
                          <div
                            className="text-left"
                            style={{ marginBottom: 10 }}
                          >
                            <h2 style={{ marginBottom: 10, marginTop: 15 }}>
                              <b className="nav-love">Don't forget to grade</b>
                            </h2>

                            <span style={{ marginTop: 5 }}>
                              After the game, tap&nbsp;
                              <Link to={`/grade`}>
                                <FontAwesomeIcon
                                  icon={faGavel}
                                  className="nav-love"
                                />{" "}
                                &nbsp;
                                <b>Grade</b>
                              </Link>
                              &nbsp;&nbsp;to score the outcomes so players can
                              see their new balances.
                            </span>
                          </div>
                        </Alert>
                      )}
                    </>
                  ) : null}
                </>
              ) : null}
            </>
          )}
        </>
      ) : null}
      {stats ? (
        <>
          {!isGameRunning ? (
            <>
              {stats.get("game_winner") ? null : (
                <Alert className="alert-custom4c">
                  <div className="text-left" style={{ marginBottom: 10 }}>
                    <h2 style={{ marginBottom: 10, marginTop: 15 }}>
                      <b className="text-warning">No more bets!</b>
                    </h2>
                    &nbsp;
                    <span style={{ marginTop: 5 }}>
                      Your&nbsp;
                      <Link to={`/inbox`}>
                        <FontAwesomeIcon
                          icon={faPiggyBank}
                          className="nav-love"
                        />{" "}
                        &nbsp;
                        <b>Earnings</b>
                      </Link>
                      &nbsp;&nbsp;(bottom right)&nbsp;will update after the Host
                      grades this game.
                    </span>
                  </div>
                </Alert>
              )}
            </>
          ) : null}
        </>
      ) : (
        <LoadingIndicator />
      )}

      {isGameRunning ? (
        <>
          {userStats ? (
            <>
              {userStats.get("total_amount_wagered") !== 0 ? (
                <>
                  {!isOwner ? (
                    <>
                      <div>
                        <Alert className="alert-custom4c text-left">
                          <div>
                            <h4 style={{ marginBottom: 20, marginTop: 20 }}>
                              {user ? (
                                <b className="wager" style={{ marginTop: 20 }}>
                                  <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    className="td-promo"
                                  />
                                  &nbsp;&nbsp;Your picks are in
                                </b>
                              ) : (
                                <b className="wager" style={{ marginTop: 20 }}>
                                  <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    className="td-promo"
                                  />
                                  &nbsp;&nbsp;Your picks are in
                                </b>
                              )}
                            </h4>
                            <div style={{ marginTop: 0 }}>
                              <b>
                                <GameTimeDisplay endTime={game.end_time} /> til{" "}
                                {game.theme === "🏈 NFL" ? (
                                  "kickoff"
                                ) : (
                                  <>
                                    {game.theme === "🏈 NCAA" ? (
                                      "kickoff"
                                    ) : (
                                      <>
                                        {game.theme === "🏀 NBA" ? (
                                          "tipoff"
                                        ) : (
                                          <>
                                            {game.theme === "🏀 NCAA" ? (
                                              "tipoff"
                                            ) : (
                                              <>
                                                {game.theme === "⚾ MLB" ? (
                                                  "first pitch"
                                                ) : (
                                                  <>
                                                    {game.theme ===
                                                    "⚾ NCAA" ? (
                                                      "first pitch"
                                                    ) : (
                                                      <>
                                                        {game.theme ===
                                                        "🏒 NHL" ? (
                                                          "puck drop"
                                                        ) : (
                                                          <>
                                                            {game.theme ===
                                                            "🏒 NCAA" ? (
                                                              "puck drop"
                                                            ) : (
                                                              <>
                                                                {game.theme ===
                                                                "⛳ Golf" ? (
                                                                  "tee time"
                                                                ) : (
                                                                  <>gametime</>
                                                                )}
                                                              </>
                                                            )}
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                                !
                              </b>{" "}
                              {game.user.username} will be grading all picks on{" "}
                              <b className="wager">{game.name}.</b>
                              <br />{" "}
                              <div className="text-center">
                                <div
                                  className="text-right"
                                  style={{ marginBottom: 15, marginTop: 25 }}
                                >
                                  <ShareButtonLong2
                                    game={game}
                                    link={`${window.location.protocol}//${window.location.hostname}/game/${game.id}/play`}
                                  ></ShareButtonLong2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Alert>
                      </div>
                    </>
                  ) : null}
                </>
              ) : (
                <>{isOwner ? null : null}</>
              )}
            </>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default GameLobby;
