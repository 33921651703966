import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { faUserCrown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";

import Alert from "jumbo/components/alert";

import connectRoute from "./connect-route";

const ThankYouRoute: React.FC = () => {
  return (
    <>
      <Container>
        <div className="play-header">
          <span>&nbsp;&nbsp;</span>
          <span>
            <b></b>
          </span>

          <span>
            {" "}
            <Link to={`/throne-room`} className="quiet-link">
              <h4 className="text-right text-muted">
                <FontAwesomeIcon
                  size="lg"
                  className="spinner-pink"
                  icon={faTimes}
                />
              </h4>
            </Link>
          </span>
        </div>
        <br />
        <br />
        <br />
        <h1 className="text-center">
          <FontAwesomeIcon className="wager header-font" icon={faCheckCircle} />
        </h1>
        <br />
        <div className="text-center h4">
          <b className="binary shimmer">You've upgraded to Pro</b>
        </div>
        <br />
        <div className="text-left binary">
          You'll now be able to access all post-game analytics, receive faster
          payouts, and more. Thanks for supporting Wager Town!
        </div>
        <br />

        <br />
        <div className="text-center titlecenter">
          <LinkContainer to={`/throne-room`} className="quiet-link">
            <Button className="w-75 logout-button text-center modal-center">
              <div className="binary">Back to Your Balance</div>
            </Button>
          </LinkContainer>
          <br />
          <br />
          <br />
        </div>
        <div className="nav-color-dull text-center small">
          “It’s all about the journey, not the outcome.”
          <br />– Carl Lewis
        </div>
      </Container>
    </>
  );
};

export default connectRoute(ThankYouRoute, "Thank You");
