import moment from "moment";
import React from "react";
import Form from "react-bootstrap/Form";

import FinalFormWrapper, { FinalFormProps } from "./final-form-wrapper";
import GenericFieldWithFeedback, {
  GenericProps,
} from "./generic-field-with-feedback";

type Props = GenericProps;

export const DatetimeFieldWithFeedback: React.FC<Props & FinalFormProps> = (
  props
) => {
  return (
    <GenericFieldWithFeedback
      {...props}
      renderInput={({ input }) => (
        <Form.Control
          {...input}
          type="datetime-local"
          value={
            input.value
              ? moment(input.value).format("YYYY-MM-DDTHH:mm")
              : input.value
          }
        ></Form.Control>
      )}
    />
  );
};

export default FinalFormWrapper(DatetimeFieldWithFeedback) as React.FC<Props>;
