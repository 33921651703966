export const API_ROOT =
  process.env.NODE_ENV !== "production"
    ? "http://127.0.0.1:8000/api"
    : (window as any).API_URL;
export const VAPID_SERVER_PUBLIC_KEY =
  "BNUOgS1wGxyXoQ-PAcQucD5D9G5xZsPtt-UIPEwz5vqj_SgvdJZb2-iRzApOb_8ky3GD894QoFQj68XJyfdTa9c";
export const STRIPE_API_KEY =
  process.env.NODE_ENV !== "production"
    ? "pk_test_4VpEs42r5DhQjwWPTDl6ubVc00NO0BlkIJ"
    : "pk_live_E71ftd88xSH4rZQG6G2PVE9v005lilTMit";
