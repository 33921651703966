import {
  faDiscord,
  faFacebook,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";

const LOGO = "/static/images/wager-town-banner.png";
const WNJ =
  "https://firebasestorage.googleapis.com/v0/b/superjumbo-c413b.appspot.com/o/unnamed%20(58).jpg?alt=media&token=e57970a4-367d-4dc6-a7cd-72547a0a1cd7";

const AboutRoute: React.FC = () => {
  return (
    <Container className="jumbo-info-pages rules-page">
      <Container>
        <div className="text-right">
          <div className="text-right">
            <Link to={`/throne-room`} className="quiet-link">
              <FontAwesomeIcon
                icon={faTimes}
                className="spinner-pink"
                size="lg"
              />
            </Link>
          </div>
        </div>

        <div>
          <br />
          <div className="text-center">
            <LinkContainer to="/" className="header-logo">
              <img src={LOGO} />
            </LinkContainer>
          </div>
          <br />
          <h2 className="text-left">Hey there!</h2>
          <div className="small-details-text nav-love">
            <br />
            <b>Wager Town</b> is a free-to-play wagering game made by Super
            Jumbo, a Brooklyn-based indie game studio.
            <br />
            <br />
            A few friends came together to build Wager Town so that sports fans
            have a fun, safe way to "bet" on their favorite games without
            putting real money down. <br />
            <br />
            We are based out of Greenpoint, Brooklyn and love the Dolphins,
            pugs, and watching 90's music videos on repeat.
            <br />
            <br />
            <div className="text-left">
              <img
                src={WNJ}
                className="left wnj-logo"
                style={{ paddingBottom: 15 }}
              />
            </div>
            <div className="text-center nav-color-dull small">
              Meet the office doges, Willy & June!
            </div>
            <br />
            For any press inquiries, you can reach us by emailing hello AT wager
            DOT town. Or tap any of the below icons.
          </div>
        </div>
        <br />

        <br />
        <div
          style={{ paddingTop: 10, paddingBottom: 10 }}
          className="text-center"
        >
          <Badge className="badge-tertiary">
            <Link to={"/"} className="binary jeopardy">
              &nbsp;&nbsp;&nbsp;&nbsp;go to home&nbsp;&nbsp;&nbsp;&nbsp;
            </Link>
          </Badge>
        </div>
        <br />
        <hr />
        <Row>
          <Col>
            {" "}
            <div className="text-center nav-footer">
              <a href="http://www.facebook.com/wagertownusa">
                <FontAwesomeIcon
                  icon={faFacebook}
                  size="lg"
                  className="even-duller"
                />
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <a href="http://twitter.com/wagertownusa/">
                <FontAwesomeIcon
                  icon={faTwitter}
                  size="lg"
                  className="even-duller"
                />
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <a href="https://discord.gg/VC7bzvdYGD">
                <FontAwesomeIcon
                  className="even-duller"
                  size="lg"
                  icon={faDiscord}
                />
              </a>
            </div>
          </Col>
        </Row>
        <hr />
        <br />
        <br />
        <br />
        <p className="nav-color-dull small text-center">
          Super Jumbo, Inc © 2020
        </p>
      </Container>
    </Container>
  );
};

export default AboutRoute;
