import * as React from "react";
import {
  FacebookIcon,
  FacebookShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

interface TProps {
  game: Game;
  gameUrl: string;
}

export const TwitterShare = ({ game, gameUrl }: TProps) => {
  const twitterProps = {
    style: {
      padding: "2px",
    },
    title: `I just entered my picks for ${game.name} on Wager Town, a free fantasy sports competition. Join me!`,
    url: gameUrl,
  } as any;
  return (
    <div>
      <TwitterShareButton {...twitterProps}>
        <TwitterIcon size={40} round={true} crossOrigin="true" />
      </TwitterShareButton>
    </div>
  );
};

export const FacebookShare = ({ game, gameUrl }: TProps) => {
  const fbProps = {
    quote: `I just entered my picks for ${game.name} on Wager Town, a free fantasy sports competition. Join me!`,
    style: {
      padding: "2px",
    },
    url: gameUrl,
  } as any;
  return (
    <FacebookShareButton {...fbProps}>
      <FacebookIcon size={40} round={true} crossOrigin="true" />
    </FacebookShareButton>
  );
};

export const WhatsappShare = ({ game, gameUrl }: TProps) => {
  const WhatsappProps = {
    style: {
      padding: "2px",
    },
    title: `I just entered my picks for ${game.name} on Wager Town, a free fantasy sports competition. Join me!`,

    url: gameUrl,
  } as any;
  return (
    <WhatsappShareButton {...WhatsappProps}>
      <WhatsappIcon size={40} round={true} crossOrigin="true" />
    </WhatsappShareButton>
  );
};

export const RedditShare = ({ gameUrl }: TProps) => {
  const RedditProps = {
    style: {
      padding: "2px",
    },
    title: "Wager Town - Place Risk-Free Bets on Your Favorite Teams",
    url: gameUrl,
  } as any;
  return (
    <RedditShareButton {...RedditProps}>
      <RedditIcon size={40} round={true} crossOrigin="true" />
    </RedditShareButton>
  );
};
