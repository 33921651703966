import { faCloudDownloadAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";

import {
  canInstall,
  hasShownPrompt,
  setHasShownPrompt,
  showNativeInstallPrompt,
} from "jumbo/lib/installation";

export const InstallButton: React.FC = () => {
  return (
    <Button
      className="bet-button"
      style={{ height: "2.25rem" }}
      onClick={showNativeInstallPrompt}
    >
      Install
    </Button>
  );
};

const InstallPrompt: React.FC = () => {
  return (
    <div
      className="jumbo-install-prompt"
      style={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <span className="binary">
        <FontAwesomeIcon className="binary" icon={faCloudDownloadAlt} />
        &nbsp;&nbsp;Install to track your balance.
      </span>
      <InstallButton />
    </div>
  );
};

/**
 * Renders the install prompt as a toast with gating.
 * The toast will not be shown if we can't install or if the prompt has been shown in
 * the past.
 */
export const showInstallPromptAsToastIfNecessary = () => {
  if (canInstall() && !hasShownPrompt()) {
    toast(<InstallPrompt />, { autoClose: false });
    setHasShownPrompt(true);
  }
};

export default InstallPrompt;
