import { sendEvent } from "./analytics";

let LAST_GEO_POSITION: Position | undefined;

const recordPositionDenied = async (err: PositionError) => {
  if (err.code === 1) {
    // We want to record a ping the first time a user denies the location
    // (subsequent ones don't carry any additional information)
    if (window.localStorage["has-sent-position-denied-ping"] !== "yes") {
      sendEvent("position_denied", {});
      window.localStorage["has-sent-position-denied-ping"] = "yes";
    }
  }
  return err;
};

const getGeoPosition = async (): Promise<Position> => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        LAST_GEO_POSITION = position;
        resolve(position);
      },
      (err) => recordPositionDenied(err).then(reject)
    );
  });
};

const getGeoPositionSync = (): Position | undefined => {
  // Kick off another call to get position, so future calls will be up to date.
  getGeoPosition().catch(() => undefined);
  return LAST_GEO_POSITION;
};

const getDistance = (
  lat1: number | undefined,
  lon1: number | undefined,
  lat2: number | undefined,
  lon2: number | undefined
): number => {
  if (
    lat1 === undefined ||
    lon1 === undefined ||
    lat2 === undefined ||
    lon2 === undefined
  ) {
    return Infinity;
  }

  const R = 6371; // Radius of earth in km.
  const degreeLat = deg2rad(lat2 - lat1);
  const degreeLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(degreeLat / 2) * Math.sin(degreeLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(degreeLon / 2) *
      Math.sin(degreeLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c * 0.62;
};

const deg2rad = (deg: number) => {
  return deg * (Math.PI / 180);
};

export { getGeoPosition, getGeoPositionSync, getDistance };
