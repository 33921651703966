import { faUserSecret } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";

import GenericForm from "./forms/generic-form";

export interface Props {
  game: Game;
  submitGameCode: (gameId: number, code: string) => Promise<any> | undefined;
}
const GameCode: React.FC<Props> = ({ game, submitGameCode }) => {
  return (
    <Container>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="text-center">
        <FontAwesomeIcon
          size="3x"
          className="share-button"
          icon={faUserSecret}
        />
      </div>
      <div className="text-left">
        <br />
        <br />
        <GenericForm
          onSubmit={(data) => submitGameCode(game.id, data.code)}
          fields={[
            {
              label: "This game is password-protected",
              name: "code",
              placeholder: "Ask the Host to provide your code",
            },
          ]}
        />
        <br />
        <Link to={`/`}>
          <p className="text-muted text-center">
            Never mind, explore other games
          </p>
        </Link>
      </div>
    </Container>
  );
};

export default GameCode;
