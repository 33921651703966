import React from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { LinkContainer } from "react-router-bootstrap";

import { getDistance } from "jumbo/lib/location";
import { GroupList } from "../components/group-list";

import connectRoute, { ConnectedRouteProps } from "./connect-route";

export interface Props {
  actions: any;
  groups: JumboGroup[];
  user: JumboUser;
  history: any;
  location?: Position;
}

export const GroupsRoute: React.FC<ConnectedRouteProps> = ({ store }) => {
  const user: JumboUser | undefined = store.users.currentUser;
  const groups: JumboGroup[] = store.groups.items;
  const location: Position = store.location.location;

  const currentGroup = user
    ? groups.find(
        (group) => group.users.filter((x) => x.id === user.id).length > 0
      )
    : undefined;

  const nearByGroups = location
    ? groups.filter((group) => {
        if (!location) {
          return false;
        }
        const distance = getDistance(
          group.latitude,
          group.longitude,
          location.coords.latitude,
          location.coords.longitude
        );
        return distance < 25;
      })
    : [];
  return (
    <Container>
      <br />
      {user ? (
        <Container className="play-header">
          <LinkContainer to="/create-team">
            <Button className="w-100 bet-button">Create Your Team</Button>
          </LinkContainer>
        </Container>
      ) : null}
      {nearByGroups.length ? (
        <>
          <br />
          <h4>👥 Nearby Teams</h4>
          <GroupList groups={nearByGroups} currentGroup={currentGroup} />
        </>
      ) : null}
      <br />
      <h4>👥 All Teams</h4>
      <GroupList groups={groups} currentGroup={currentGroup} />
    </Container>
  );
};

export default connectRoute(GroupsRoute, "Teams", [
  (actions) => actions.fetchGroups(),
  (actions) => actions.getLocation(),
]);
