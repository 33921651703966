import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";

import ScrollToTop from "jumbo/components/scroll-to-top";

import connectRoute, { ConnectedRouteProps } from "./connect-route";

const WriterTermsRoute: React.FC = () => {
  return (
    <Container>
      <ScrollToTop />

      <div className="text-right">
        <Link to={`/?`} className="quiet-link">
          <FontAwesomeIcon icon={faTimes} size="2x" className="special-link" />
        </Link>
      </div>
      <Container>
        <br />
        <br />
        <h2 className="text-left">Writer Terms</h2>
        <br />
        Thank you for expressing an interest in writing questions for Wager
        Town™. Before we can approve you to begin writing questions for our
        site, we ask you to please read the below, as your adding questions
        signifies that you agree to the following:
        <br />
        <br />
        <ul>
          <li>
            I have read the Wager Town™ Terms of Service and agree to comply
            with all of the terms stated therein, including Article 7 (“User
            Conduct”) and Article 8 (“User Content”) of the Terms of Service,
            which explains the types of content I may or may not post or upload
            onto the site including when writing questions, as well as my grant
            to Wager Town™ of an “irrevocable, fully sub-licensable, perpetual,
            world-wide, royalty-free, non-exclusive license to use, distribute,
            reproduce, modify, adapt, publish, translate, publicly perform and
            publicly display” any such content I create for the site, including
            the questions I create for games involving Wager Town™.{" "}
          </li>
          <li>
            I understand that I am writing questions for Wager Town™ entirely
            out of my own volition and for my personal enjoyment, and that my
            writing of these questions does not make me an employee, agent or
            independent contractor of Wager Town™, nor does it establish any
            other form of special relationship between me and Wager Town™.{" "}
          </li>
          <li>
            I understand that I am not entitled to any compensation in any form
            in exchange for writing questions for Wager Town™.
          </li>
          <li>
            I agree not to profit either directly or indirectly from writing
            questions for Wager Town™. Among other things, this means I agree
            not to write questions that are intended to serve to promote myself,
            my family, my friends, or my businesses, or the business of any
            third party, nor will I accept money or gifts of any kind from third
            parties to write questions that would support them, their family,
            their friends or their business interests.
          </li>
        </ul>
      </Container>
      <br />

      <br />
    </Container>
  );
};

export default WriterTermsRoute;
