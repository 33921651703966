import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faSms } from "@fortawesome/free-solid-svg-icons";
import { faShare } from "@fortawesome/pro-light-svg-icons";
import { faUserPlus } from "@fortawesome/pro-light-svg-icons";
import { faHandHoldingUsd } from "@fortawesome/pro-light-svg-icons";
import { faPaperPlane } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import {
  FacebookShare,
  TwitterShare,
  WhatsappShare,
} from "./social-share-buttons5";

export interface Props {
  game: Game;
  link: string;
  prop: string;
  renderButton?: (onClick: () => void) => JSX.Element;
}

export interface State {
  showModal: boolean;
  linkCopied: boolean;
}

/** Supports native webshare or modal fallback. */
class ShareButtonShort3 extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      linkCopied: false,
      showModal: false,
    };
  }
  public render() {
    const title = `${this.props.prop}\n\nSee what the pro's think ${this.props.link}`;
    const body = `${this.props.prop}\n\nPSee what the pro's think ${this.props.link}`;
    const ShareButtonShort3 = this.props.renderButton ? (
      this.props.renderButton(this.handleClick)
    ) : (
      <div onClick={this.handleClick}>
        <div className="user-header">
          <Button className="logout-button-s">
            <small>
              <FontAwesomeIcon className="nav-color" icon={faPaperPlane} />
            </small>
            <span className="jeopardy small-host-text nav-color">
              &nbsp;&nbsp;&nbsp;send
            </span>
          </Button>
        </div>
      </div>
    );
    return (
      <>
        {ShareButtonShort3}
        <Modal
          className="modal-dialog-custom"
          show={this.state.showModal}
          onHide={this.handleModalClose}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body
            style={{
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div
              style={{ marginBottom: 10 }}
              className="text-center binary small"
            >
              <b>Share with a friend</b>
            </div>
            <br />
            <span
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginBottom: "20px",
                marginLeft: "10px",
              }}
            >
              <TwitterShare
                prop={this.props.prop}
                game={this.props.game}
                gameUrl={this.props.link}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <FacebookShare
                prop={this.props.prop}
                game={this.props.game}
                gameUrl={this.props.link}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <WhatsappShare
                prop={this.props.prop}
                game={this.props.game}
                gameUrl={this.props.link}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span className="text-center">
                <a href={`sms:?body=${encodeURI(body)}`}>
                  <Button className="bet-button">
                    <span className="binary">
                      <FontAwesomeIcon
                        size="2x"
                        className="td-promo"
                        icon={faSms}
                      />
                    </span>
                  </Button>
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a
                  href={`mailto:?subject=${encodeURI(title)}&body=${encodeURI(
                    body
                  )}`}
                >
                  <Button className="bet-button">
                    <span className="binary">
                      <FontAwesomeIcon
                        size="2x"
                        className="spinner-pink"
                        icon={faEnvelope}
                      />
                    </span>
                  </Button>
                </a>
                <br />
              </span>
            </span>
          </Modal.Body>
        </Modal>
      </>
    );
  }

  private handleClick = () => {
    if ((navigator as any).share) {
      (navigator as any).share({
        text: `${this.props.prop}\n\nSee what the pro's think`,
        title: `${this.props.prop}\n\nSee what the pro's think`,
        url: this.props.link,
      });
    } else {
      this.setState({ showModal: true });
    }
  };

  private handleModalClose = () => {
    this.setState({ showModal: false });
  };

  private copyLink = async () => {
    await navigator.clipboard.writeText(this.props.link);
    this.setState({ linkCopied: true });
  };
}

export default ShareButtonShort3;
