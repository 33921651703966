import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import { faUserCrown } from "@fortawesome/pro-solid-svg-icons";
import { faLock } from "@fortawesome/pro-solid-svg-icons";
import { faBoxBallot } from "@fortawesome/pro-solid-svg-icons";
import { faSackDollar } from "@fortawesome/pro-solid-svg-icons";
import { faHandshakeAlt } from "@fortawesome/pro-solid-svg-icons";
import { faFlagCheckered } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import ProgressBar from "react-bootstrap/ProgressBar";
import Table from "react-bootstrap/Table";
import { LinkContainer } from "react-router-bootstrap";
import { Link, Redirect } from "react-router-dom";

import Alert from "jumbo/components/alert";
import { loadGameOrAlias } from "jumbo/lib/routing";
import { getSingleStat } from "jumbo/lib/stats";

import connectRoute, { ConnectedRouteProps } from "./connect-route";

export const GameDetailsRoute: React.FC<ConnectedRouteProps> = ({
  actions,
  store,
}) => {
  const game: Game = store.games.item;
  const isUserGameOwner =
    store.users.currentUser && store.users.currentUser.id === game.user;

  if (
    game.requires_code &&
    store.gameCodes.authorizedGames.indexOf(game.id) === -1
  ) {
    return <Redirect to={`/game/${game.id}`} />;
  }

  return (
    <>
      {game.game_scores.length > 0 ? (
        <Link to={`/inbox`} className="quiet-link">
          <h5 className="text-left spinner-pink">
            <div>
              <FontAwesomeIcon
                className="spinner-pink"
                size="lg"
                icon={faChevronLeft}
              />
            </div>
          </h5>
        </Link>
      ) : (
        <Link to={`/game/${game.id}/play`} className="quiet-link">
          <h5 className="text-left spinner-pink">
            <div>
              <FontAwesomeIcon
                className="spinner-pink"
                size="lg"
                icon={faChevronLeft}
              />
            </div>
          </h5>
        </Link>
      )}

      <Container>
        {game.game_scores.length > 0 ? (
          <div>
            <br />
            <h5 className="text-center nav-love" style={{ marginTop: 10 }}>
              {game.name} - Results
            </h5>
            <div
              className="text-center even-duller small-details-text"
              style={{ marginTop: 5 }}
            >
              All thanks to&nbsp;
              <b className="nav-love">{game.user.username}</b> for hosting!
            </div>
            <br />

            {game.game_scores.length > 0 ? (
              <div>
                <div>
                  <div className="even-duller text-left small-details-text">
                    <b>Question Summary</b>
                  </div>
                  <Table
                    className="table-sm leaderboard"
                    style={{ marginTop: 10 }}
                  >
                    <thead>
                      <tr className="small">
                        <th className="text-left">
                          <span className="even-duller small">Question</span>
                        </th>
                        {game.game_scores.length > 0 ? (
                          <th className="text-left small">
                            <span className="even-duller">Final Odds</span>
                          </th>
                        ) : null}

                        <th className="nav-color text-left small">
                          <span className="even-duller">Graded</span>
                        </th>
                        <th className="nav-color text-left small">
                          <span className="even-duller">% Picks on Yes</span>
                        </th>
                        <th className="nav-color text-left small">
                          <span className="even-duller">% Wagered on Yes</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {game.props.length > 0 ? (
                        game.props.map((prop, index) => (
                          <tr key={index}>
                            <td className="content-44 nav-color-dull align-middle">
                              <span className="align-middle small nav-love">
                                {prop.prompt}
                              </span>
                            </td>
                            <td className="small">
                              <div>
                                {(prop.multiplier || 0) >= 1 ? (
                                  <span className="small-details-text-5 user-header">
                                    +{(prop.multiplier * 100).toFixed(0)}
                                  </span>
                                ) : (
                                  <span className="small-details-text-5 user-header">
                                    -
                                    {(
                                      ((prop.multiplier + 1) / prop.multiplier -
                                        1) *
                                      100
                                    ).toFixed()}
                                  </span>
                                )}
                              </div>
                            </td>
                            <td className="small">
                              {prop.result ? (
                                <FontAwesomeIcon
                                  className="td-positive"
                                  icon={faThumbsUp}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="td-no"
                                  icon={faThumbsDown}
                                />
                              )}
                            </td>
                            <td
                              className="td-positive"
                              style={{ width: "18%" }}
                            >
                              <ProgressBar
                                variant={
                                  (getSingleStat(store.stats.stats, {
                                    game: game.id,
                                    name: "pct_positive_wagers",
                                    prop: prop.id,
                                  }) || 0) >= 0
                                    ? "success"
                                    : "danger"
                                }
                                className="game-insights-bars"
                                now={getSingleStat(store.stats.stats, {
                                  game: game.id,
                                  name: "pct_positive_wagers",
                                  prop: prop.id,
                                })}
                                max={100}
                              />
                              <small className="text-left binary">
                                {getSingleStat(store.stats.stats, {
                                  game: game.id,
                                  name: "pct_positive_wagers",
                                  prop: prop.id,
                                })}
                                %
                              </small>
                            </td>
                            <td
                              className="td-positive"
                              style={{ width: "18%" }}
                            >
                              <ProgressBar
                                variant={
                                  (getSingleStat(store.stats.stats, {
                                    game: game.id,
                                    name: "pct_positive_amount_wagered",
                                    prop: prop.id,
                                  }) || 0) >= 0
                                    ? "success"
                                    : "danger"
                                }
                                className="game-insights-bars"
                                now={getSingleStat(store.stats.stats, {
                                  game: game.id,
                                  name: "pct_positive_amount_wagered",
                                  prop: prop.id,
                                })}
                                max={100}
                              />
                              <small className="text-left binary">
                                {getSingleStat(store.stats.stats, {
                                  game: game.id,
                                  name: "pct_positive_amount_wagered",
                                  prop: prop.id,
                                })}
                                %
                              </small>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td>No Props added yet</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <Alert
                    alertKey="discord-contest1"
                    className="alert-custom-blue"
                  >
                    <span className="small-details-text buy-icon">
                      See an incorrectly graded question?{" "}
                      <a href="https://discord.gg/VC7bzvdYGD">
                        <u className="buy-icon">Join our Discord</u>
                      </a>{" "}
                      and let one of the mods know.
                    </span>
                  </Alert>
                  <br />
                  <div className="even-duller text-left small-details-text">
                    <b>Player Stats</b>
                  </div>{" "}
                </div>
                <Table
                  className="table-sm leaderboard"
                  style={{ marginTop: 10 }}
                >
                  <thead>
                    <tr className="small even-duller">
                      <th className="even-duller">
                        <FontAwesomeIcon
                          className="even-duller"
                          icon={faUser}
                        />
                      </th>
                      <th className="text-center">
                        {" "}
                        <FontAwesomeIcon
                          className="even-duller"
                          icon={faFlagCheckered}
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {game.game_scores
                      .sort((a, b) => b.score - a.score)
                      .map((score, index) => {
                        if (score.user) {
                          return (
                            <tr
                              className={
                                store.users.currentUser.username ===
                                score.user.username
                                  ? "leaderboard-highlight-user"
                                  : ""
                              }
                              key={index}
                            >
                              <td className="content-5  small-details-text-2">
                                <div className="user-header small-details-text-2">
                                  <b className="binary small-details-text-2">
                                    {score.user.username}
                                  </b>{" "}
                                  {(score.score || 0) >= 0 ? (
                                    <span>
                                      <span
                                        className="nav-color"
                                        style={{ marginTop: 10 }}
                                      >
                                        &nbsp;gained&nbsp;
                                        <span
                                          className={
                                            (score.score || 0) >= 0
                                              ? "td-positive small-details-text-2"
                                              : "td-negative small-details-text=2"
                                          }
                                        >
                                          +$
                                          {((score.score || 0) / 100)
                                            .toFixed(2)
                                            .toLocaleString()}
                                        </span>
                                      </span>
                                    </span>
                                  ) : (
                                    <span className="nav-color">
                                      &nbsp;lost&nbsp;
                                      <span
                                        className={
                                          (score.score || 0) >= 0
                                            ? "td-positive small-details-text-2"
                                            : "td-negative small-details-text-2"
                                        }
                                      >
                                        $
                                        {(((score.score || 0) * -1) / 100)
                                          .toFixed(2)
                                          .toLocaleString()}
                                      </span>
                                    </span>
                                  )}
                                </div>
                              </td>

                              <td>
                                <Button className="details-button">
                                  <LinkContainer
                                    to={`/game/${game.id}/details/${score.id}`}
                                    key={index}
                                  >
                                    <span className="spinner-pink small">
                                      &nbsp;&nbsp;<b>See bets</b>
                                      &nbsp;&nbsp;
                                    </span>
                                  </LinkContainer>
                                </Button>
                              </td>
                            </tr>
                          );
                        } else if (score.group) {
                          return (
                            <LinkContainer
                              to={`/game/${game.id}/details/${score.id}`}
                              key={index}
                            >
                              <tr>
                                <td>{score.group.name}</td>
                                <td>{score.score}</td>
                              </tr>
                            </LinkContainer>
                          );
                        } else {
                          return null;
                        }
                      })}
                  </tbody>
                </Table>
                <div className="text-center small"></div>
              </div>
            ) : null}
          </div>
        ) : (
          <div>
            <h4 className="text-left nav-color" style={{ marginTop: 10 }}>
              <span className="binary">Audience Insights</span>
            </h4>
            <span className="even-duller">For {game.name}</span>
            <Alert
              alertKey="propt2"
              className="alert-custom"
              style={{ marginTop: 20 }}
            >
              <span className="small-details-text nav-love">
                <b className="nav-love">&nbsp;&nbsp;% Wagers on Yes</b>{" "}
                indicates how many players think this will be graded as a{" "}
                <b>Yes</b>
                <br />
                <div style={{ marginTop: 10 }}>
                  <b className="nav-love">欄&nbsp;&nbsp;% Wagered on Yes</b>{" "}
                  shows you how confident players think this will graded as a{" "}
                  <b>Yes</b>
                </div>
                <div style={{ marginTop: 10 }}>
                  <b className="nav-love">&nbsp;&nbsp;A high % Diff</b> may
                  indicate a smart betting opportunity on <b>Yes</b>
                </div>
              </span>
            </Alert>

            <br />
            <Table className="table-sm leaderboard">
              <thead>
                <tr className="small">
                  <th className="text-left">
                    <span className="even-duller small">Question</span>
                  </th>

                  <th className="nav-color text-left small">
                    <span className="even-duller">% Wagers on Yes</span>
                  </th>
                  <th className="nav-color text-left small">
                    <span className="even-duller">% Wagered on Yes</span>
                  </th>

                  <th className="text-left small">
                    <span className="even-duller">Bonus</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {game.props.length > 0 ? (
                  game.props.map((prop, index) => (
                    <tr key={index}>
                      <td className="content-44 nav-color-dull align-middle">
                        <span className="align-middle small nav-love">
                          {prop.prompt}
                        </span>
                      </td>

                      <td className="td-positive" style={{ width: "18%" }}>
                        <ProgressBar
                          variant={
                            (getSingleStat(store.stats.stats, {
                              game: game.id,
                              name: "pct_positive_wagers",
                              prop: prop.id,
                            }) || 0) >= 0
                              ? "success"
                              : "danger"
                          }
                          className="game-insights-bars"
                          now={getSingleStat(store.stats.stats, {
                            game: game.id,
                            name: "pct_positive_wagers",
                            prop: prop.id,
                          })}
                          max={100}
                        />
                        <small className="text-left binary">
                          {getSingleStat(store.stats.stats, {
                            game: game.id,
                            name: "pct_positive_wagers",
                            prop: prop.id,
                          })}
                          %
                        </small>
                      </td>
                      <td className="td-positive" style={{ width: "18%" }}>
                        <ProgressBar
                          variant={
                            (getSingleStat(store.stats.stats, {
                              game: game.id,
                              name: "pct_positive_amount_wagered",
                              prop: prop.id,
                            }) || 0) >= 0
                              ? "success"
                              : "danger"
                          }
                          className="game-insights-bars"
                          now={getSingleStat(store.stats.stats, {
                            game: game.id,
                            name: "pct_positive_amount_wagered",
                            prop: prop.id,
                          })}
                          max={100}
                        />
                        <small className="text-left binary">
                          {getSingleStat(store.stats.stats, {
                            game: game.id,
                            name: "pct_positive_amount_wagered",
                            prop: prop.id,
                          })}
                          %
                        </small>
                      </td>

                      <td className="small">
                        <b>{prop.multiplier || 0}</b>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>No Props added yet</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        )}
      </Container>
    </>
  );
};

const getStatsForProp = (game: number, prop: number) => {
  return [
    { name: "num_wagers", game, prop },
    { name: "num_positive_wagers", game, prop },
    { name: "pct_positive_wagers", game, prop },
    { name: "pct_positive_amount_wagered", game, prop },
  ];
};

const getAllPropStats = (game: Game) => {
  let stats: any = [];
  for (const prop of game.props) {
    stats = stats.concat(getStatsForProp(game.id, prop.id));
  }
  return stats;
};

export default connectRoute(
  GameDetailsRoute,
  (props) => `${props.store.games.item.name} Details`,
  [
    loadGameOrAlias,
    (actions, match, store) =>
      actions.getStats(getAllPropStats(store.games.item)),
  ]
);
