import React from "react";
import BootstrapForm from "react-bootstrap/Form";
import { Form } from "react-final-form";

import userService from "jumbo/lib/user-service";

import InputFieldWithFeedback from "./fields/input-field-with-feedback";
import { convertDjangoErrors } from "./utils";

export interface Props {
  onLoginComplete: () => void;
}

const LoginForm: React.FC<Props> = ({ onLoginComplete }) => {
  return (
    <Form
      onSubmit={(data: { userIdentifier: string; password: string }) => {
        return userService
          .login(data.userIdentifier, data.password)
          .then(onLoginComplete, convertDjangoErrors);
      }}
      render={({ handleSubmit, submitError }) => {
        return (
          <div>
            <form
              onSubmit={handleSubmit}
              id="login-form"
              className="binary small-details-text"
            >
              <InputFieldWithFeedback
                name="userIdentifier"
                label="Username or Email"
              />
              <InputFieldWithFeedback name="password" type="password" />
              <BootstrapForm.Text className="text-danger">
                {submitError}
              </BootstrapForm.Text>
              <button
                type="submit"
                className="btn logout-button2 w-100 rounded"
                style={{ marginTop: 10 }}
              >
                <span className="binary jeopardy">log in</span>
              </button>
            </form>
          </div>
        );
      }}
    />
  );
};

export default LoginForm;
