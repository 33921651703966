export const getDisplayString = (value: string): string => {
  switch (value) {
    case "avg":
      return "Collaborative";
    case "med":
      return "Representative";
    case "rnd":
      return "Anarchy";
    case "pun":
      return "Punish";
    case "rew":
      return "Reward";
    case "eq":
      return "Equal";
    case "prp":
      return "Proportional";
    default:
      return "";
  }
};
