import { defaultHeaders } from "redux-rest-resource";

import { API_ROOT } from "jumbo/lib/constants";
import { getAuthHeaders, postAsPromise } from "jumbo/lib/network";

const login = async (userIdentifier: string, password: string) => {
  const isEmailAddress = userIdentifier.match(/^.*\@.*\.\w{2,3}/);
  const userIdType = isEmailAddress ? "email" : "username";

  return postAsPromise(`${API_ROOT}/auth/login/`, {
    password,
    [userIdType]: userIdentifier,
  }).then(storeApiToken);
};

const logout = async () => {
  clearApiToken();
  return postAsPromise(`${API_ROOT}/auth/logout/`, "");
};

const register = async (
  email: string,
  username: string,
  password1: string,
  password2: string
) => {
  return postAsPromise(`${API_ROOT}/auth/registration/`, {
    email,
    password1,
    password2,
    username,
  }).then(storeApiToken);
};

const storeApiToken = (data: { key: string }) => {
  const token = data.key;
  if (token) {
    window.localStorage["api-token"] = token;
  }
  updateReduxRestResourceTokenHeader();
};

const clearApiToken = () => {
  window.localStorage.removeItem("api-token");
  updateReduxRestResourceTokenHeader();
};

const updateReduxRestResourceTokenHeader = () => {
  Object.assign(defaultHeaders, { ...defaultHeaders, ...getAuthHeaders() });
};

export default {
  login,
  logout,
  postAsPromise,
  register,
  updateReduxRestResourceTokenHeader,
};
