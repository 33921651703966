/**
 * Takes in a camelCase, dashed-case, or snake_case and returns
 * a displayable version of the string.
 * @param name  Non display name (camelCase)
 * @returns Display version of the input string.
 */
export const getDisplayName = (name: string): string => {
  // Try to split based on camel case
  name = name.replace(/([a-z](?=[A-Z]))/g, "$1 ");
  // Try to split based on snake/dash case
  name = name.replace(/[_-]/, " ");
  // Lower case everything and capitalize the first word.
  name = name.toLowerCase();
  return name === "" ? name : name.charAt(0).toUpperCase() + name.slice(1);
};
