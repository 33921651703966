import React from "react";

interface WagerMap {
  [key: number]: Wager;
}

export interface WagerRowProps {
  prop: any;
  wagers: WagerMap;
  userIds: number[];
}

export function WagersByPropRow(props: WagerRowProps) {
  const { prop, wagers, userIds } = props;

  return (
    <tr>
      <td className="content-44 nav-color-dull align-middle">
        <span className="align-middle small binary">
          <b>{prop.prompt}</b>
        </span>
      </td>
      <td>
        <span className="nav-love small">
          {(prop.multiplier || 0) >= 1 ? (
            <span className="user-header">
              +{(prop.multiplier * 100).toFixed(0)}
            </span>
          ) : (
            <span className="user-header">
              -{(((prop.multiplier + 1) / prop.multiplier - 1) * 100).toFixed()}
            </span>
          )}
        </span>
      </td>
      {userIds &&
        userIds.map((userId, j) => {
          const wager = wagers[userId];
          return wager === undefined ? (
            <React.Fragment key={j}>
              <td />
              <td />
              <td />
            </React.Fragment>
          ) : (
            <React.Fragment key={j}>
              <td className="nav-color-dull">
                {wager.prediction === true ? (
                  <b className="wager small">Yes&nbsp;</b>
                ) : (
                  <b className="wager small">No&nbsp;</b>
                )}
              </td>
              <td className="small wager">
                <b className="nav-color-dull">
                  ${(wager.amount / 100).toFixed(2).toLocaleString()}
                </b>
              </td>

              <td className="small nav-love">
                <span>
                  $
                  {((wager.amount / 100) * prop.multiplier)
                    .toFixed(2)
                    .toLocaleString()}{" "}
                </span>
              </td>
            </React.Fragment>
          );
        })}
    </tr>
  );
}
