import React from "react";

import GroupForm from "jumbo/components/forms/group-form";

import connectRoute, { ConnectedRouteProps } from "./connect-route";

export const EditGroupRoute: React.FC<ConnectedRouteProps> = ({
  actions,
  history,
  store,
}) => {
  const group = store.groups.item;
  return (
    <GroupForm
      group={group}
      onSubmit={async (data) => {
        await actions.updateGroup({ id: group.id, ...data });
        history.push(`"/team/${group.id}`);
      }}
    />
  );
};

export default connectRoute(EditGroupRoute, "Edit Team", [
  (actions, match) => actions.getGroup(match.params.id),
]);
