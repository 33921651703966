import moment, { Moment } from "moment";
import React, { MutableRefObject } from "react";
import CardDeck from "react-bootstrap/CardDeck";
import Col from "react-bootstrap/Col";
import { getSingleStat } from "jumbo/lib/stats";

import GameListItem from "jumbo/components/game-list-item";

export const sortGames = (currentTime: Moment, games: Game[]): Game[] => {
  return games.sort((a, b) => {
    if (a.end_time === b.end_time) {
      return 0;
    }

    const aDiff = moment(a.end_time).diff(currentTime);
    const bDiff = moment(b.end_time).diff(currentTime);
    if (aDiff > 0 && bDiff <= 0) {
      return -1;
    }
    if (bDiff > 0 && aDiff <= 0) {
      return 1;
    }
    if (a.is_scored && !b.is_scored) {
      return -1;
    }
    if (b.is_scored && !a.is_scored) {
      return 1;
    }
    return Math.abs(aDiff) - Math.abs(bDiff);
  });
};

export interface Props {
  games: Game[];
  user?: JumboUser;
  location?: {
    lat: number;
    lon: number;
  };
  scrollable?: boolean;
  stats?: Map<string, number>;
}

const GameList: React.FC<Props> = ({
  games,
  user,
  location,
  scrollable,
  stats,
}) => {
  const currentTime = moment();
  const deckRef = React.useRef<HTMLDivElement>(null);
  const [showScrollControls, setShowScrollControls] = React.useState(false);

  const scroll = (direction: string) => (event: any) => {
    const container = event.nativeEvent.srcElement.closest(".container");
    const deck = container.querySelector(".jumbo-card-deck");
    const cards = deck.querySelectorAll(".card");
    const SCROLL_INCREMENT = container.clientWidth / (cards.length - 1);

    deck.scroll({
      behavior: "smooth",
      left:
        direction === "left"
          ? deck.scrollLeft - (SCROLL_INCREMENT + 20)
          : deck.scrollLeft + SCROLL_INCREMENT + 20,
      top: 0,
    });
  };

  const determineControlVisibility = () => {
    const deckWrapper = deckRef.current;
    if (!deckWrapper) {
      return;
    }
    const deck = Array.from(deckWrapper.children).filter(
      (c) => !!c.className.includes("jumbo-card-deck")
    )[0];

    if (scrollable) {
      const deckWidth = deck.clientWidth;

      if (deck.children.length < 2) {
        return;
      }

      setShowScrollControls(
        deckWidth < deck.children[0].clientWidth * deck.children.length
      );
    } else {
      setShowScrollControls(false);
    }
  };

  const onResizeWindow = () => {
    determineControlVisibility();
  };

  React.useEffect(() => {
    determineControlVisibility();
  }, [games, user, location, scrollable, deckRef]);

  React.useEffect(() => {
    window.addEventListener("resize", onResizeWindow);

    return () => {
      window.removeEventListener("resize", onResizeWindow);
    };
  });

  return (
    <div ref={deckRef} style={{ position: "relative" }}>
      {showScrollControls && (
        <>
          <div
            className="scroll-control scroll-left"
            onClick={(e) => scroll("left")(e)}
          >
            <p>&lt;</p>
          </div>
        </>
      )}
      <CardDeck className={`jumbo-card-deck ${scrollable ? "scrollable" : ""}`}>
        {sortGames(currentTime, games).map((game, i) => (
          <GameListItem user={user} game={game} key={i} location={location} />
        ))}
      </CardDeck>

      {showScrollControls && (
        <div
          className="scroll-control scroll-right"
          onClick={(e) => scroll("right")(e)}
        >
          <p>&gt;</p>
        </div>
      )}
    </div>
  );
};

export default GameList;
