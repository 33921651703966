import { faFacebook, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faCalendarPlus } from "@fortawesome/pro-light-svg-icons";
import { faCalendarWeek } from "@fortawesome/pro-solid-svg-icons";
import { faScroll } from "@fortawesome/pro-light-svg-icons";
import { faGavel } from "@fortawesome/pro-solid-svg-icons";
import { faMobileAlt } from "@fortawesome/pro-solid-svg-icons";
import { faStopwatch } from "@fortawesome/pro-light-svg-icons";
import { faHourglassHalf } from "@fortawesome/pro-light-svg-icons";
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons";
import { faPiggyBank } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import moment, { Moment } from "moment-timezone";
import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import CardDeck from "react-bootstrap/CardDeck";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import BootstrapNavbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";

import Alert from "jumbo/components/alert";
import GameList from "jumbo/components/game-list";
import InstallPrompt from "jumbo/components/install-prompt";
import { GameTimeDisplayAlt } from "jumbo/components/shared/game-time-display-alt";

import connectRoute, { ConnectedRouteProps } from "./connect-route";
import { OperatingSystems } from "jumbo/store/device";
import { onInstalled } from "jumbo/lib/installation";

type ThemeConfig = {
  id: string;
  sortOrder: number;
  title: string;
};

const LOGO = "/static/images/wager-town-banner.png";
const themes: ThemeConfig[] = [
  {
    id: "FootballNfl",
    sortOrder: 1,
    title: "🏈 NFL",
  },
  {
    id: "FootballNcaa",
    sortOrder: 2,
    title: "🏈 NCAA",
  },
  {
    id: "BasketballNba",
    sortOrder: 3,
    title: "🏀 NBA",
  },
  {
    id: "BasketballNcaa",
    sortOrder: 4,
    title: "🏀 NCAA",
  },
  {
    id: "BaseballMlb",
    sortOrder: 5,
    title: "⚾ MLB",
  },
  {
    id: "BaseballNcaa",
    sortOrder: 6,
    title: "⚾ NCAA",
  },
  {
    id: "HockeyNhl",
    sortOrder: 7,
    title: "🏒 NHL",
  },
  {
    id: "HockeyNcaa",
    sortOrder: 8,
    title: "🏒 NCAA",
  },
  {
    id: "HorseRacing",
    sortOrder: 9,
    title: "🐎 Horse Racing",
  },
  {
    id: "Tennis",
    sortOrder: 10,
    title: "🎾 Tennis",
  },
  {
    id: "Boxing",
    sortOrder: 11,
    title: "🥊 Boxing",
  },
  {
    id: "Golf",
    sortOrder: 12,
    title: "⛳ Golf",
  },
  {
    id: "Soccer",
    sortOrder: 13,
    title: "⚽ Soccer",
  },
  {
    id: "SportsSims",
    sortOrder: 14,
    title: "🎮 Sports Sims",
  },
  {
    id: "AutoRacing",
    sortOrder: 15,
    title: "🏎️ Auto Racing",
  },
  {
    id: "Darts",
    sortOrder: 16,
    title: "🎯 Darts",
  },
  {
    id: "Cycling",
    sortOrder: 17,
    title: "🚴 Cycling",
  },
  {
    id: "WrestlingMma",
    sortOrder: 18,
    title: "🤼 MMA",
  },
  {
    id: "WrestlingWwe",
    sortOrder: 19,
    title: "🤼 WWE",
  },
  {
    id: "Lottery",
    sortOrder: 20,
    title: "🎰 Lottery",
  },
  {
    id: "Tv",
    sortOrder: 21,
    title: "📺 TV Shows",
  },
  {
    id: "Markets",
    sortOrder: 22,
    title: "📈 Markets",
  },
  {
    id: "Justforfun",
    sortOrder: 23,
    title: "🤣 Just for Fun",
  },
].sort((a, b) => a.sortOrder - b.sortOrder);

export const groupGames = (currentTime: Moment, games: Game[]) =>
  games.reduce(
    (acc: { activeGames: Game[]; completedGames: Game[] }, game) => {
      moment(game.end_time).diff(currentTime) > 0
        ? acc.activeGames.push(game)
        : acc.completedGames.push(game);
      return acc;
    },
    { activeGames: [], completedGames: [] }
  );

const groupByTheme = (mixedCollection: Game[]) =>
  mixedCollection.reduce(
    (accumulator: any, currentValue: any) => ({
      ...accumulator,
      [currentValue.theme]: [
        ...(accumulator[currentValue.theme]
          ? accumulator[currentValue.theme]
          : []),
        currentValue,
      ],
    }),
    {} as ThemedGameList
  );

interface ThemedGameList {
  FootballNfl: Game[];
  FootballNcaa: Game[];
  BasketballNba: Game[];
  BasketballNcaa: Game[];
  BaseballMlb: Game[];
  BaseballNcaa: Game[];
  HockeyNhl: Game[];
  HockeyNcaa: Game[];
  HorseRacing: Game[];
  Tennis: Game[];
  Boxing: Game[];
  Golf: Game[];
  Soccer: Game[];
  SportsSims: Game[];
  AutoRacing: Game[];
  Darts: Game[];
  Cycling: Game[];
  WrestlingMma: Game[];
  WrestlingWwe: Game[];
  Lottery: Game[];
  Tv: Game[];
  Markets: Game[];
  Justforfun: Game[];
}

export const HomeRoute: React.FC<ConnectedRouteProps> = ({ store }) => {
  const games: Game[] = store.games.items;
  const location: Position | undefined = store.location.location;
  const { activeGames, completedGames } = groupGames(moment(), games);
  const user: JumboUser = store.users.currentUser;
  const [showScrollHint, setShowScrollHint] = useState(false);

  const activeGamesByTheme = groupByTheme(activeGames);
  const completedGamesByTheme = groupByTheme(completedGames);
  const isWeekAlmostOver =
    moment().tz("America/New_York").endOf("isoWeek").toISOString() &&
    moment().toISOString() >
      moment()
        .tz("America/New_York")
        .endOf("isoWeek")
        .subtract(24, "hours")
        .toISOString();

  return (
    <>
      <div>

        <Container>

          <div className="text-center" style={{ paddingTop: 15 }}>
            <LinkContainer to="/" className="header-logo">
              <img src={LOGO} />
            </LinkContainer>
          </div>
        </Container>
        <br/>
            <Alert
                      className="alert-success"
                      alertKey="weekly-timerssaasa"
                      style={{ paddingBottom: 10, paddingTop: 10 }}
                    >
                      <div className="text-left">
            
                        <br/>
                        <div
                          className="text-center lobbytext nav-love"
                          style={{ paddingTop: 25, paddingBottom: 10 }}
                        >
                         "These young guys are playing checkers. <br/>I'm out there playing chess." <br/><br/>-Kobe Bryant
                         <br/><br/>
                        </div>
                      </div>
                    </Alert>
        <section className="upcoming-games">
          {themes.map((theme) => {
            const games = activeGamesByTheme[theme.title];
            if (!games) {
              return null;
            }
            const className = `upcoming-games-${theme.id}`;

            return (
              <Row key={className} className={className}>
                <Col>
                 
                </Col>
              </Row>
            );
          })}
        </section>
        
      
        <br />
        <hr />

        
      </div>
      {window.localStorage.getItem("JUMBO_SHOW_PROMPT") === "true" && (
        <div className=".d-block .d-none-md">
          <InstallPrompt />
        </div>
      )}
    </>
  );
};

/* tslint:disable */
export default connectRoute(HomeRoute, "Today", [
  (actions) =>
    actions.fetchGames(
      {},
      {
        query: {
          nonscored_games: 1,
          end_time__gt: moment()
            .endOf("day")
            .subtract(1.5, "days")
            .toISOString(),
          end_time__lt: moment().endOf("day").add(0.5, "days").toISOString(),
        },
      }
    ),
]);
