import React from "react";
import Container from "react-bootstrap/Container";
import Helmet from "react-helmet";

export const PageNotFoundRoute: React.FC = () => {
  return (
    <Container>
      <Helmet>
        <title>Jumbo - Not Found</title>
      </Helmet>
      <p>Sorry we couldn't find the page you're looking for.</p>
    </Container>
  );
};

export default PageNotFoundRoute;
