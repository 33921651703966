import moment from "moment";
import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import CardDeck from "react-bootstrap/CardDeck";
import Container from "react-bootstrap/Container";
import ProgressBar from "react-bootstrap/ProgressBar";
import Table from "react-bootstrap/Table";
import { LinkContainer } from "react-router-bootstrap";
import { Link, Redirect } from "react-router-dom";

import { GameTimeDisplayAlt } from "jumbo/components/shared/game-time-display-alt";

export interface Props {
  stats: Map<string, number>;
  username?: string;
  paypal_username?: string;
}

const UserStats: React.FC<Props> = ({ stats, username, paypal_username }) => {
  const isWeekAlmostOver =
    moment().tz("America/New_York").endOf("isoWeek").toISOString() &&
    moment().toISOString() >
      moment()
        .tz("America/New_York")
        .endOf("isoWeek")
        .subtract(24, "hours")
        .toISOString();

  return (
    <div>
      <CardDeck className="jumbo-card-deck">
        <Card className="jumbo-card-shop3">
          <br />
          <br />
          <Table
            className="table-sm balance text-left align-vertical bottom"
            style={{ paddingBottom: 0, paddingTop: 0 }}
          >
            <tbody>
              <tr>
                <td className="text-right">
                  <span
                    className="even-duller small-host-text"
                    style={{ marginBottom: 0 }}
                  >
                    Earnings this week
                  </span>
                  {(stats.get("weekly_score_total") || 0) >= 2500 ? (
                    <div className="td-promo header-font-dob">
                      $
                      {((stats.get("weekly_score_total") || 0) / 100)
                        .toFixed(2)
                        .toLocaleString()}
                    </div>
                  ) : (
                    <>
                      {(stats.get("weekly_score_total") || 0) >= 0 ? (
                        <div className="nav-love header-font-dob">
                          $
                          {((stats.get("weekly_score_total") || 0) / 100)
                            .toFixed(2)
                            .toLocaleString()}
                        </div>
                      ) : (
                        <div className="nav-love header-font-dob">
                          -$
                          {(((stats.get("weekly_score_total") || 0) * -1) / 100)
                            .toFixed(2)
                            .toLocaleString()}
                        </div>
                      )}
                    </>
                  )}
                  <div style={{ paddingTop: 10 }}>
                    <div
                      className="text-right even-duller"
                      style={{ paddingBottom: 10 }}
                    >
                      <span className="text-right small-host-text">
                        Your progress to <b>$25</b>
                      </span>
                    </div>
                    <ProgressBar
                      variant={
                        (stats.get("weekly_score_total") || 0) >= 2500
                          ? "success"
                          : "warning"
                      }
                      className="progress-bar-lobby"
                      label={`$${(stats.get("weekly_score_total") || 0) / 100}`}
                      now={stats.get("weekly_score_total")}
                      max={2500}
                      style={{ marginLeft: 0 }}
                    />
                  </div>

                  <div
                    className="nav-love big-wager-text"
                    style={{ paddingTop: 25 }}
                  >
                    <span
                      className="even-duller small-host-text"
                      style={{ marginBottom: 0 }}
                    >
                      Next cashout in
                    </span>
                    {isWeekAlmostOver ? (
                      <div
                        className="text-right prop-money-text"
                        style={{ paddingTop: 0 }}
                      >
                        <div className="even-duller">
                          <b className="text-warning">
                            <GameTimeDisplayAlt
                              endTime={moment()
                                .tz("America/New_York")
                                .endOf("isoWeek")
                                .toISOString()}
                            />{" "}
                          </b>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="text-right prop-money-text"
                        style={{ paddingTop: 0 }}
                      >
                        <div className="even-duller">
                          <b className="td-positive">
                            <GameTimeDisplayAlt
                              endTime={moment()
                                .tz("America/New_York")
                                .endOf("isoWeek")
                                .toISOString()}
                            />{" "}
                          </b>
                        </div>
                      </div>
                    )}
                  </div>

                  <div style={{ marginTop: 20 }}>
                    <div
                      className="nav-color-dull text-center big-wager-text"
                      style={{ marginTop: 0 }}
                    >
                      <div className="big-wager-text">
                        <div style={{ marginTop: 10 }}></div>

                        <br />
                        <div className="text-left small">
                          <h6 className="even-duller" style={{ marginTop: 25 }}>
                            Weekly Cashout Minimum - Rules
                          </h6>
                          <p
                            className="small even-duller text-left"
                            style={{ marginTop: 7 }}
                          >
                            All or nothing. You will receive your balance on{" "}
                            {moment()
                              .tz("America/New_York")
                              .endOf("isoWeek")
                              .add(2, "days")
                              .format("dddd, MMM Do")}
                            &nbsp; if you are at or over the{" "}
                            <b>Cashout Minimum</b> ($25) when all games ending
                            prior to{" "}
                            {moment()
                              .endOf("isoWeek")
                              .format("dddd, MMM Do hh:mm A")}{" "}
                            are graded.&nbsp;
                            <a href="/rules" className="even-duller">
                              Read <u>The Rules</u>
                            </a>
                            &nbsp;for more info. &nbsp;
                            <br />
                            <br />
                            You can add or change your preferred cashout method
                            in&nbsp;
                            <a href="/settings" className="even-duller">
                              <u>Your Settings</u>,
                            </a>
                            &nbsp;otherwise it will be sent as a PayPal to the
                            email you registered with.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </Card>
      </CardDeck>
    </div>
  );
};

export default UserStats;
