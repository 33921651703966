import {
  faDiscord,
  faFacebook,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faShare } from "@fortawesome/pro-solid-svg-icons";
import { faMobileAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import CardDeck from "react-bootstrap/CardDeck";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";

import Alert from "jumbo/components/alert";
import GenericFormSettings from "jumbo/components/forms/generic-form-settings";
import { showInstallPromptAsToastIfNecessary } from "jumbo/components/install-prompt";
import {
  canInstall,
  hasShownPrompt,
  setHasShownPrompt,
  showNativeInstallPrompt,
} from "jumbo/lib/installation";
import {
  hasSubscription,
  sendPushTest,
  subscribe,
  unsubscribe,
} from "jumbo/lib/push";

import connectRoute, { ConnectedRouteProps } from "./connect-route";

export interface State {
  hasPushSubscription: boolean;
  showInformationSaved: boolean;
  showReferralSaved: boolean;
}

export class UserSettingsRoute extends React.Component<
  ConnectedRouteProps,
  State
> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasPushSubscription: false,
      showInformationSaved: false,
      showReferralSaved: false,
    };
  }

  public async componentDidMount() {
    const hasPushSubscription = await hasSubscription();
    showInstallPromptAsToastIfNecessary();
    this.setState({
      hasPushSubscription,
    });
  }

  public render() {
    const userProfile = this.props.store.userProfiles.item;

    return (
      <>
        <div>
          <Alert
            alertKey="install-pwa-app"
            className="alert-custom4c"
            dismissible
          >
            <div style={{ paddingTop: 10 }} className="play-header">
              <h4 className="nav-love">
                Get the app&nbsp;&nbsp;&nbsp;
                <FontAwesomeIcon className="nav-love" icon={faMobileAlt} />
              </h4>
            </div>
            <div
              className="small-host-text even-duller"
              style={{ paddingBottom: 10, paddingTop: 10 }}
            >
              Wager Town is available as a free app on mobile and desktop.&nbsp;
              <Link className="wager" to={`/how-to-install`}>
                <u>Learn more.</u>
              </Link>
            </div>
          </Alert>

          <Link className="quiet-link" to="/throne-room">
            <h4 className="spinner-pink">
              <FontAwesomeIcon className="spinner-pink" icon={faChevronLeft} />
            </h4>
          </Link>
          <Container>
            <div>
              <h2 className="text-left" style={{ paddingTop: 10 }}>
                <b>Your Settings</b>
              </h2>
            </div>
            <br />
            <CardDeck className="jumbo-card-deck">
              <Card className="jumbo-card-shop">
                <Card.Header className="jumbo-card-shop-header">
                  <Card.Title>
                    <h3 className="text-left" style={{ paddingTop: 15 }}>
                      Cashout Email
                    </h3>
                  </Card.Title>
                </Card.Header>
                <div
                  className="even-duller text-left small-host-text"
                  style={{
                    paddingBottom: 10,
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}
                >
                  Save the PayPal email you'd like us to send any cash won.
                  Cashouts are processed and sent out every Wednesday night.{" "}
                  <a href="/rules" className="even-duller small-host-text">
                    Learn more.
                  </a>
                </div>

                <Card.Body style={{ paddingTop: 40 }}>
                  <GenericFormSettings
                    submitLabel="save to my account"
                    onSubmit={async (data) => {
                      await this.props.actions.updateUserProfile({
                        id: userProfile.id,
                        ...data,
                      });
                      this.setState({ showInformationSaved: true });
                    }}
                    fields={[
                      {
                        label: "Enter a PayPal email",
                        name: "paypal_username",
                        placeholder: "Enter your PayPal email",
                        // validate: username =>
                        //   !username.match(/^[0-9a-z]+$/)
                        //     ? "Username must contain only letters and numbers"
                        //     : undefined
                      },
                    ]}
                    initialValues={userProfile}
                  />

                  {this.state.showInformationSaved ? (
                    <span className="titlecenter text-center">
                      <br />
                      <div className="text-center small td-yass">
                        <FontAwesomeIcon icon={faCheck} />
                        &nbsp;&nbsp;Your info has been saved. <br />
                        <br />
                        <Link to={"/?"} className="nav-love">
                          <Button
                            className="logout-button0 w-100 jeopardy"
                            style={{ padding: 10 }}
                          >
                            <span className="nav-love">
                              {" "}
                              <b>see all games ending soon</b>
                            </span>
                          </Button>
                        </Link>
                      </div>
                    </span>
                  ) : null}
                  <br />
                </Card.Body>
              </Card>
            </CardDeck>

            <br />
            <hr />
            <section className="text-center home-footer">
              <Row>
                <Col>
                  <nav className="nav-footer small star-opacity2">
                    <Link to="/rules">
                      <span className="star-opacity2 small">
                        <b>Rules</b>
                      </span>
                    </Link>
                    <Link to="/tos">
                      {" "}
                      <span className="star-opacity2  small">
                        <b>Terms</b>
                      </span>
                    </Link>
                    <Link to="/privacy">
                      {" "}
                      <span className="star-opacity2 small">
                        <b>Privacy</b>
                      </span>
                    </Link>
                    <a href="/about">
                      {" "}
                      <span className="star-opacity2 small">
                        <b>About</b>
                      </span>
                    </a>
                  </nav>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  {" "}
                  <div className="text-center nav-footer">
                    <a href="http://www.facebook.com/wagertownusa">
                      <FontAwesomeIcon
                        icon={faFacebook}
                        size="lg"
                        className="star-opacity2"
                      />
                    </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a href="http://twitter.com/wagertownusa/">
                      <FontAwesomeIcon
                        icon={faTwitter}
                        size="lg"
                        className="star-opacity2"
                      />
                    </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a href="https://discord.gg/VC7bzvdYGD">
                      <FontAwesomeIcon
                        className="star-opacity2"
                        size="lg"
                        icon={faDiscord}
                      />
                    </a>
                  </div>
                </Col>
              </Row>
              <hr />
            </section>
            <br />

            <p className="nav-color-dull small text-center">
              Super Jumbo, Inc © 2020
            </p>
            <br />
            <div className="text-center titlecenter">
              <LinkContainer to={`/logout`}>
                <span className="nav-color-dull">
                  <b>Log out</b>
                </span>
              </LinkContainer>
            </div>

            <br />
          </Container>
        </div>
      </>
    );
  }

  private updateHasSubscription = async () => {
    const hasPushSubscription = await hasSubscription();
    this.setState({
      hasPushSubscription,
    });
  };
}

export default connectRoute(UserSettingsRoute, "Settings", [
  (actions) => actions.getCurrentUser(),
  (actions, match, store) =>
    store.users.currentUser
      ? actions.getUserProfile(store.users.currentUser.profile.id)
      : Promise.reject("No current user"),
]);
