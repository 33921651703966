import { API_ROOT } from "jumbo/lib/constants";
import { postAsPromise } from "jumbo/lib/network";

export interface GameCodesState {
  authorizedGames: number[];
}

const SET_GAME_AUTHORIZED = "@@jumbo/game-codes/SET_GAME_AUTHORIZED";

const submitGameCode = (gameId: number, code: string) => {
  return async (dispatch: any) => {
    return postAsPromise(`${API_ROOT}/games/${gameId}/check_code`, {
      code,
    }).then(() => dispatch(setGameAuthorized(gameId)));
  };
};

const setGameAuthorized = (gameId: number) => {
  return {
    gameId,
    type: SET_GAME_AUTHORIZED,
  };
};

const rootReducer = (
  state: GameCodesState = { authorizedGames: [] },
  action: any
): GameCodesState => {
  switch (action.type) {
    case SET_GAME_AUTHORIZED:
      return {
        ...state,
        authorizedGames: [...state.authorizedGames, action.gameId],
      };
    default:
      return state;
  }
};

const actions = { setGameAuthorized, submitGameCode };
export { actions, rootReducer };
