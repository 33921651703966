import React from "react";
import Table from "react-bootstrap/Table";

import { getDisplayString } from "jumbo/lib/group-utils";

interface Props {
  group: JumboGroup;
}

const GroupDetails: React.FC<Props> = ({ group }) => {
  return (
    <Table>
      <thead></thead>
      <tbody>
        <tr>
          <td>Scoring</td>
          <td>{getDisplayString(group.scoring)}</td>
        </tr>
      </tbody>
    </Table>
  );
};

export default GroupDetails;
