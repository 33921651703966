import React from "react";
import Container from "react-bootstrap/Container";

export interface State {
  show: boolean;
  tooLong: boolean;
}
class LoadingIndicator extends React.Component<{}, State> {
  private timerId: NodeJS.Timeout | undefined = undefined;
  private tooLongTimerId: NodeJS.Timeout | undefined = undefined;

  constructor(props: {}) {
    super(props);
    this.state = { show: false, tooLong: false };
  }

  public componentDidMount() {
    // Only show after 500ms.
    this.timerId = setTimeout(() => this.setState({ show: true }), 500);
    // If it's been 5 seconds, tell the user something is up...
    this.tooLongTimerId = setTimeout(
      () => this.setState({ tooLong: true }),
      5000
    );
  }

  public componentWillUnmount() {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
    if (this.tooLongTimerId) {
      clearTimeout(this.tooLongTimerId);
    }
  }

  public render() {
    if (!this.state.show) {
      return null;
    }
    return (
      <div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <div className="spinner-border spinner-pink" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
        {this.state.tooLong ? (
          <Container>
            <br />
            <br />
            <div className="even-duller small text-center">
              Champions keep playing until they get it right.
              <br /> – Billie Jean King
            </div>
          </Container>
        ) : null}
      </div>
    );
  }
}

export default LoadingIndicator;
