import React from "react";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";

import FinalFormWrapper, { FinalFormProps } from "./final-form-wrapper";
import GenericFieldWithFeedback, {
  GenericProps,
} from "./generic-field-with-feedback";
import { SelectOption } from "./select-field-with-feedback";

interface Props extends GenericProps {
  options: SelectOption[];
  search?: (query: string) => Promise<SelectOption[]>;
  multiple?: boolean;
}

interface State {
  isLoading: boolean;
  options: SelectOption[];
}

export class TypeaheadFieldWithFeedback extends React.Component<
  Props & FinalFormProps,
  State
> {
  constructor(props: Props & FinalFormProps) {
    super(props);

    this.state = {
      isLoading: false,
      options: props.options,
    };
  }

  public render() {
    const props = this.props;
    return (
      <GenericFieldWithFeedback
        {...props}
        renderInput={({ input }) => {
          let selected: SelectOption[] | undefined;
          if (input.value) {
            if (props.multiple) {
              const stringValues = input.value.map((x: any) => x.toString());
              selected = this.state.options.filter(
                (x) => stringValues.indexOf(x.value) !== -1
              );
            } else {
              const option = this.state.options.find(
                (x) => x.value === input.value
              );
              if (option !== undefined) {
                selected = [option];
              }
            }
          }

          const typeaheadProps = {
            id: `typeahead-field-${props.name}`,
            isLoading: this.state.isLoading,
            labelKey: (option: SelectOption) => option.display,
            maxResults: 10,
            multiple: props.multiple,
            onChange: (selections: SelectOption[]) => {
              selections.length
                ? input.onChange(
                    props.multiple
                      ? selections.map((x) => x.value)
                      : selections[0].value
                  )
                : props.multiple
                ? input.onChange([])
                : input.onChange(undefined);
            },
            onSearch:
              props.search !== undefined
                ? async (query: string) => {
                    if (!props.search) {
                      return;
                    }
                    this.setState({ isLoading: true });
                    const options = await props.search(query);
                    // Since we use the options for display name look ups above,
                    // we need to make sure that we merge the options instead
                    // of a straight up override.
                    for (const option of this.state.options) {
                      if (
                        options.filter((x) => x.value === option.value)
                          .length === 0
                      ) {
                        options.push(option);
                      }
                    }
                    this.setState({ isLoading: false, options });
                  }
                : undefined,
            options: this.state.options,
            selected,
          };
          const TypeaheadComponent = props.search ? AsyncTypeahead : Typeahead;
          return <TypeaheadComponent {...typeaheadProps} />;
        }}
      />
    );
  }
}

export default FinalFormWrapper(
  TypeaheadFieldWithFeedback,
  "select"
) as React.FC<Props>;
