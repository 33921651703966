import React from "react";
import { connect, Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import LoadingIndicator from "jumbo/components/loading-indicator";
import Navbar from "jumbo/components/navbar";
import { API_ROOT } from "jumbo/lib/constants";
import userService from "jumbo/lib/user-service";
import AboutRoute from "jumbo/routes/about";
import CreateGameRoute from "jumbo/routes/create-game";
import CreateGroupRoute from "jumbo/routes/create-group";
import CreatePropRoute from "jumbo/routes/create-prop";
import EditGameRoute from "jumbo/routes/edit-game";
import EditGroupRoute from "jumbo/routes/edit-group";
import GameRoute from "jumbo/routes/game";
import GameDetailsRoute from "jumbo/routes/game-details";
import GradeGameRoute from "jumbo/routes/grade-game";
import GroupRoute from "jumbo/routes/group";
import GroupGameDetailsRoute from "jumbo/routes/group-game-details";
import GroupsRoute from "jumbo/routes/groups";
import HomeRoute from "jumbo/routes/home";
import InstallRoute from "jumbo/routes/how-to-install";
import InboxRoute from "jumbo/routes/inbox";
import JumboUnavailableRoute from "jumbo/routes/jumbo-unavailable";
import LeaderboardRoute from "jumbo/routes/leaderboard";
import LeaderboardAllTimeRoute from "jumbo/routes/leaderboard-all-time";
import LeaderboardLastWeekRoute from "jumbo/routes/leaderboard-last-week";
import LoginRegisterRoute from "jumbo/routes/login-register";
import LogoutRoute from "jumbo/routes/logout";
import PageNotFoundRoute from "jumbo/routes/page-not-found";
import PlayGameRoute from "jumbo/routes/play-game";
import PrivacyRoute from "jumbo/routes/privacy";
import RulesRoute from "jumbo/routes/rules";
import ThankYouRoute from "jumbo/routes/thankyou";
import ThroneRoomRoute from "jumbo/routes/throne-room";
import TosRoute from "jumbo/routes/tos";
import UserSettingsRoute from "jumbo/routes/user-settings";
import WagersRoute from "jumbo/routes/wagers";
import WriterTermsRoute from "jumbo/routes/writer-terms";
import store from "jumbo/store";
import { actions as userActions } from "jumbo/store/user-resource";
import { actions as deviceActions } from "jumbo/store/device";

import "./styles/jumbo.css";

// Make sure bootstrap and associated styles are included.
// import "bootstrap/dist/css/bootstrap.min.css";

interface ConnectedAppProps {
  user?: JumboUser;
}

export const AppComponent: React.FC<ConnectedAppProps> = ({ user }) => {
  return (
    <div className="jumbo-container">
      <BrowserRouter>
        <div className="pb-5">
          <Route render={(props) => <Navbar {...props} user={user} />} />
          <Switch>
            <Route path="/throne-room" component={ThroneRoomRoute}></Route>
            <Route path="/create-team" component={CreateGroupRoute}></Route>
            <Route path="/team/:id/edit" component={EditGroupRoute}></Route>
            <Route path="/team/:id" component={GroupRoute}></Route>
            <Route path="/teams" component={GroupsRoute}></Route>
            <Route
              path="/game/:id/details/:scoreId"
              component={GroupGameDetailsRoute}
            ></Route>
            <Route path="/game/:id/edit" component={EditGameRoute}></Route>
            <Route path="/game/:id/grade" component={GradeGameRoute}></Route>
            <Route
              path="/game/:id/details"
              component={GameDetailsRoute}
            ></Route>
            <Route path="/game/:id/play" component={PlayGameRoute}></Route>
            <Route
              path="/game/:id/create-prop"
              component={CreatePropRoute}
            ></Route>
            <Route path="/game/:id" component={GameRoute}></Route>
            <Route path="/create-game" component={CreateGameRoute}></Route>
            <Route path="/settings" component={UserSettingsRoute}></Route>
            <Route path="/logout" component={LogoutRoute}></Route>
            <Route path="/login" component={LoginRegisterRoute}></Route>
            <Route path="/privacy" component={PrivacyRoute}></Route>
            <Route path="/about" component={AboutRoute}></Route>
            <Route path="/rules" component={RulesRoute}></Route>
            <Route path="/leaderboard" component={LeaderboardRoute} />
            <Route
              path="/leaderboard-all-time"
              component={LeaderboardAllTimeRoute}
            />
            <Route
              path="/leaderboard-last-week"
              component={LeaderboardLastWeekRoute}
            />

            <Route path="/thankyou" component={ThankYouRoute} />
            <Route path="/tos" component={TosRoute}></Route>
            <Route path="/" exact={true} component={HomeRoute}></Route>
            <Route path="/inbox" exact={true} component={InboxRoute}></Route>
            <Route path="/how-to-install" component={InstallRoute}></Route>
            <Route path="/wagers" exact={true} component={WagersRoute}></Route>
            <Route
              path="/writer-terms"
              exact={true}
              component={WriterTermsRoute}
            ></Route>
            <Route component={PageNotFoundRoute} />
          </Switch>
        </div>
      </BrowserRouter>
      <ToastContainer
        className="jumbo-toast-container"
        position={toast.POSITION.BOTTOM_RIGHT}
        toastClassName="jumbo-toast"
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.users.currentUser,
  };
};

export const ConnectedAppComponent = connect(mapStateToProps)(AppComponent);

enum ApiStatus {
  WAITING,
  AVAILABLE,
  UNAVAILABLE,
}

interface State {
  apiStatus: ApiStatus;
}

const EXCULDED_REGIONS = ["Arizona"];

class App extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      apiStatus: ApiStatus.WAITING,
    };
  }

  public componentDidMount() {
    // Make sure the API is accessiable.
    fetch(`${API_ROOT}`).then(
      (response) => {
        response.status === 200 && this.state.apiStatus === ApiStatus.WAITING
          ? this.setState({ apiStatus: ApiStatus.AVAILABLE })
          : this.setState({ apiStatus: ApiStatus.UNAVAILABLE });
      },
      () => this.setState({ apiStatus: ApiStatus.UNAVAILABLE })
    );

    // As soon as the app mounts we need to update our token resources.
    store.dispatch(userActions.getCurrentUser() as any);

    const userAgent = window.navigator.userAgent || navigator.vendor;
    store.dispatch(deviceActions.setUserAgent(userAgent));
    store.dispatch(deviceActions.setOperatingSystem(userAgent));
  }

  public render() {
    if (this.state.apiStatus === ApiStatus.WAITING) {
      return <LoadingIndicator />;
    } else if (this.state.apiStatus === ApiStatus.UNAVAILABLE) {
      return <JumboUnavailableRoute />;
    } else {
      return (
        <Provider store={store}>
          <ConnectedAppComponent />
        </Provider>
      );
    }
  }
}

// This should be called at least once at start up time.
userService.updateReduxRestResourceTokenHeader();

export default App;
