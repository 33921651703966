import React from "react";

import { getStat } from "jumbo/lib/stats";

interface Props {
  statDefinition: StatDefinition;
  render: (value: number) => React.ReactNode;
}

interface State {
  value: number | null;
}

class StatsFragment extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      value: null,
    };
  }

  public async componentDidMount() {
    const stat = await getStat(this.props.statDefinition);
    this.setState({ value: stat.value });
  }

  public render() {
    if (this.state.value === null) {
      return null;
    }
    return this.props.render(this.state.value);
  }
}

export default StatsFragment;
