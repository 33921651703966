import {
  faDiscord,
  faFacebook,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";

import ScrollToTop from "jumbo/components/scroll-to-top";
const LOGO = "/static/images/wager-town-banner.png";

const TosRoute: React.FC = () => {
  return (
    <Container className="jumbo-info-pages">
      <ScrollToTop />

      <Container>
        <div className="play-header">
          <div className="text-left">
            <img src={LOGO} className="left header-logo" />
          </div>
          <Link to={`/`} className="quiet-link text-right">
            <FontAwesomeIcon
              icon={faTimes}
              className="special-link spinner-pink"
              size="lg"
            />
          </Link>
        </div>
        <br />
        <br />
        <h2 className="text-left">Terms of Service</h2>
        <h5 className="text-left nav-color-dull">Wager Town</h5>
        <br />
        <br />
        <h3>1. PREAMBLE</h3>
        <p>
          The following Terms of Service (“Terms”) set forth the terms and
          conditions of the agreement between you, an individual user (“You”),
          and Super Jumbo, Inc. (Wager Town™”) in conjunction with Wager Town™’s
          offering of fantasy sports contests (“the Service”) at the domain
          http://wager.town (“the Site”) and through our iPhone and Android
          apps. These Terms are to be read in conjunction with the{" "}
          <a href="/privacy">Wager Town™ Privacy Policy</a> and the
          <a href="/rules"> Wager Town™ Game Rules.</a>
        </p>
        <h3>2. THE SERVICE</h3>
        <p>
          Wager Town™ will provide a wide range of free-to-enter, prediction
          contests. For all contests, winners are determined by Wager Town™.
          Wager Town™ shall have the sole discretion of determining its game
          rules and selecting winners of these contests. Further, Wager Town™
          shall be the sole arbiter of disputes over who wins each contest. The
          Site and Service is not to be used in conjunction with any form of
          illegal gambling.
        </p>
        <h3>3. ACCEPTANCE OF TERMS</h3>
        <p>
          By accessing the Site and using any part of the Site or Service, you
          agree to be bound by these Terms. It is your responsibility to review
          these Terms regularly to determine whether they have changed. If you
          do not agree to all of the Terms, then you may not access the Site or
          attempt to use our Services.
        </p>
        <h3>4. TERMS REPRESENT ENTIRE AGREEMENT</h3>
        <p>
          These Terms constitute the entire agreement between you and Wager
          Town™ with respect to the Site, and these Terms supersede any prior
          agreements, oral or written, between you and Wager Town™. A provision
          of these Terms may be waived only by written instrument executed by
          the party entitled to the benefit of such provisions. The failure of
          Wager Town™ to exercise or enforce any right or provision of these
          Terms will not constitute a waiver of such right or provision.
        </p>
        <h3>5. MODIFICATION OF TERMS</h3>
        <p>
          Wager Town™ reserves the right, at its sole discretion, to modify or
          replace the existing Terms of Service at any time. Should any change
          in Terms occur, you shall be responsible for reviewing and becoming
          familiar with any such modifications. Your continued use of the Wager
          Town™ Site and Service upon any change or modification of the Terms
          constitutes your constructive acknowledgement and acceptance of these
          modifications.
        </p>
        <h3>6. USER ELIGIBILTY</h3>
        <p>
          By using our Site or Service, you hereby represent and warrant that
          you are a natural person (corporate entities and similar organizations
          are not eligible to use the Wager Town™ Service) and you are fully
          able and competent to enter into the terms, conditions, obligations,
          affirmations, representations and warranties set forth in the Terms of
          Service and to abide by and comply with these terms. Further, you
          represent and warrant that: i) You are the age of majority in your
          jurisdiction of residence (this is at least 18 in most states, 19
          years of age in Nebraska and Alabama) ii) You are physically located
          in the United States of America and reside in a jurisdiction in which
          participation in any contest you choose to participate in is allowed
          by applicable law. iii) You will establish only one account to
          participate in the Services offered on the Wager Town™ Site, and only
          you will operate the account. iv) You will abide at all times by these
          Terms and any other agreements between you and Wager Town™. v) You are
          not subject to backup withholding tax because: (a) you are exempt from
          backup withholding, or (b) you have not been notified by the Internal
          Revenue Service (IRS) that you are subject to backup withholding as a
          result of a failure to report all interest or dividends, or (c) the
          IRS has notified you that you are no longer subject to backup
          withholding. vi) If you are playing our contest in conjunction with
          one of our approved restaurant or bar host sites, you meet the minimum
          requirements for eligibility stipulated by that entity, which is some
          contexts may be even stricter. If any of the above representations and
          warranties are not met, in addition to any other rights and remedies
          available under law or equity, Wager Town™ reserves the right to
          suspend or terminate your account and to revoke or withhold the
          awarding of any prizes associated with your account. Further, in the
          event that Wager Town™ discovers that you have opened more than one
          account, in addition to any other rights that Wager Town™ may have,
          Wager Town™ reserves the right to suspend or terminate any or all of
          your accounts and terminate, withhold or revoke the award of any
          prizes.
        </p>
        <h3>7. USER CONDUCT</h3>
        <p>
          As a condition of use, you agree not to use the Site or Service for
          any purpose that is unlawful or prohibited by these Terms of Service,
          or any other purpose not reasonably intended by Wager Town™. By way of
          example, and not as a limitation, you agree to conduct yourself with
          good sportsmanship at all times, not to purchase multiple teams within
          a given contest unless the contest affirmatively allows for multiple
          entries by the same user. In addition, you agree not to abuse, harass,
          impersonate, intimidate or threaten other Wager Town™ users; not to
          post or transmit, or cause to be posted or transmitted, any materials
          (“User Content”) that are infringing, libelous, defamatory, abusive,
          offensive, obscene, pornographic, vulgar, or otherwise in violation of
          any law or right of any third party; not to use the Site for any
          unauthorized purpose, or in violation of any applicable law, including
          intellectual property laws; not to post or transmit, or cause to be
          posted or transmitted, any communication or solicitation designed or
          intended to obtain password, account, or private information from any
          Wager Town™ user; not to create or submit unwanted email
          (&quot;Spam&quot;) to any other Wager Town™ users; and not to infringe
          upon the intellectual property rights of Wager Town™, its users, or
          any third party. Further, you agree not to submit comments discussing
          or linking to affiliate programs, multi- level marketing schemes,
          personal business ventures, or off-topic content; not to post, email,
          transmit, upload, or otherwise make available any material that
          contains software viruses or any other computer code, files or
          programs designed or functioning to interrupt, destroy, or limit the
          functionality of any computer software or hardware or
          telecommunications equipment; and not to use any robot, spider,
          scraper or other automated means to access the site for any purpose
          (except for RSS feed access) without our express written permission.
          Finally, you agree that you will not: (1) take any action that
          imposes, or may impose, in our sole discretion, an unreasonable or
          disproportionately large load on our infrastructure; (2) interfere or
          attempt to interfere with the proper working of the Site or any
          activities conducted on the Site; or (3) bypass any measures Wager
          Town™ may use to prevent or restrict access to the Site. Violation of
          our rules may result in the removal of your content from the Site
          and/or the cancellation of your account. You acknowledge and agree
          that Wager Town™ may remove any User Content and terminate any Wager
          Town™ account at any time for any reason (including, but not limited
          to, upon receipt of claims or allegations from third parties or
          authorities relating to such User Content). IN ADDITION, ANY ATTEMPT
          BY A USER TO DELIBERATELY DAMAGE ANY WEBSITE OR UNDERMINE THE
          LEGITIMATE OPERATION OF OUR CONTESTS IS A VIOLATION OF CRIMINAL AND
          CIVIL LAWS, AND SHOULD SUCH AN ATTEMPT OCCUR, Wager Town™ RESERVES THE
          RIGHT TO SEEK DAMAGES FROM ANY SUCH USER TO THE FULLEST EXTENT
          AVAILABLE BY LAW. To report Terms of Service abuse, please contact us
          at tos@wager.town.
        </p>
        <h3>8. USER CONTENT</h3>
        <p>
          You understand that all content created by users of either the Site or
          Service (“User Content”), including but not limited to profile
          information and communications with other users, whether privately
          transmitted or made publicly available, is the sole responsibility of
          the person from which such User Content originated. This means that
          you are entirely responsible for all User Content that you upload,
          post, share, email, transmit, or otherwise make available via the
          Site. Under no circumstances will Wager Town™ be liable in any way for
          any User Content uploaded, posted, shared, emailed, transmitted or
          otherwise made available via the Site. You acknowledge that Wager
          Town™ may or may not pre-screen User Content, but that Wager Town™ and
          its designees have the right (but not the obligation) in their sole
          discretion to pre-screen, refuse, permanently delete, and/or move any
          User Content available via the Site. Without limiting the foregoing,
          Wager Town™ and its designees shall have the right to remove any User
          Content that violates these Terms or is otherwise objectionable in
          Wager Town™’s sole discretion. You understand that by using the Site,
          you may be exposed to User Content that you may consider offensive or
          objectionable. You agree that you must evaluate, and bear all risks
          associated with, the use or disclosure of any User Content. You
          further acknowledge and agree that you bear the sole risk of reliance
          on any content available on or through the Site. With respect to User
          Content you submit or make available on the Site, you grant Wager
          Town™ an irrevocable, fully sub-licensable, perpetual, world-wide,
          royalty-free, non- exclusive license to use, distribute, reproduce,
          modify, adapt, publish, translate, publicly perform and publicly
          display such User Content (in whole or in part) and to incorporate
          such User Content into other works in any format or medium now known
          or later developed. You are solely responsible for your interactions
          with other users of the Site. Wager Town™ reserves the right, but has
          no obligation, to monitor disputes between you and other users.
        </p>
        <h3>9. RIGHTS TO USE OF YOUR PERSONAL INFORMATION</h3>
        <p>
          By using this Site or Service, you agree to provide Wager Town™ with a
          worldwide, non- exclusive, royalty-free, perpetual, transferable and
          non-revocable license to use, for any purpose, any information
          provided by you on our Site. Specifically, you consent to the use of
          your name, voice, likeness, and photograph in connection with the
          development, production, distribution and/or exploitation (including
          marketing and promotion) of the selected contest and/or other Wager
          Town™ contests, unless otherwise prohibited by law. Contest winners
          agree that, from the date of notification of their status as a
          potential winner, and continuing until such time when notified that
          they no longer need to do so, they will make themselves available to
          Wager Town™ for publicity, advertising and promotional activities
          relating to any contest in which they participate.
        </p>
        <h3>10. REGISTRATION FOR ACCOUNT</h3>
        <p>
          In order to use our Site or Service, you must register for an account.
          By registering as a user, you agree to provide accurate, current and
          complete information about yourself as prompted (such information
          being the “Registration Data”) and to maintain and promptly update the
          Registration Data to keep it accurate, current and complete.
          Furthermore, you will not take any steps to mask your identity,
          location or demographic information. If you provide any information
          that is inaccurate, not current or incomplete, or Wager Town™ has
          reasonable grounds to suspect that such information is inaccurate, not
          current or incomplete, Wager Town™ may deny you access to areas
          requiring registration, at its sole discretion.
        </p>
        <h3>11. ACCOUNT PASSWORD AND SECURITY</h3>
        <p>
          At the time of registering for an online account, you must provide a
          valid email address or phone number and supply a username and password
          to be used in conjunction with your account. Many portions of the Site
          require registration for access. You are responsible for maintaining
          the confidentiality of your username and password, and are fully
          responsible for all uses of your username and password, whether by you
          or others. You agree to (a) keep your username and password
          confidential and not share them with anyone else; (b) immediately
          notify Wager Town™ of any unauthorized use of your username and
          password or account or any other breach of security; and (c) use only
          your username and password to access the Site’s Restricted Areas. IF
          THERE IS UNAUTHORIZED USE OF YOUR ACCOUNT, YOU WILL BE LIABLE FOR THE
          LOSSES INCURRED BY YOURSELF, Wager Town™ AND OTHERS DUE TO ANY
          UNAUTHORIZED USE.
        </p>
        <h3>12. COMMUNICATIONS AND DISCLOSURES</h3>
        <p>
          As a result of your registration for the Service, you may receive
          certain commercial communications from Wager Town™. You understand and
          agree that these communications are part of your registration, and
          that you may opt out of receiving these communications at any time by
          either using the unsubscribe functionality or sending an email to
          tos@wager.town. You acknowledge, consent and agree that Wager Town™
          may access, preserve and disclose your account information and content
          if required to do so by law or in a good faith belief that such
          access, preservation, or disclosure is reasonably necessary: (i) to
          comply with legal process; (ii) to enforce these Terms of Service;
          (iii) to respond to claims that any content violates the rights of
          third parties; (iv) to respond to your requests for customer service;
          or (v) to protect the rights, property, or personal safety of Wager
          Town™, its Users and the public.
        </p>
        <h3>13. DISQUALIFICATION OF USERS</h3>
        <p>
          Wager Town™, in its sole discretion, may disqualify any entrant from
          the Site or Service, refuse to award fantasy points or prizes and
          require the return of any prizes, if an entrant engages in conduct
          Wager Town™ deems to be improper, unfair or otherwise adverse to the
          operation of the Site or Service or in any way detrimental to other
          entrants. Improper conduct includes, but is not limited to: falsifying
          personal information required to use the Service or to claim a prize;
          accumulating, or attempting to accumulate, points or prizes through
          unauthorized methods such as automated scripts or other automated
          means; tampering with the administration of the Service or trying to
          in any way tamper with the computer programs associated with this
          Service; obtaining other entrants’ information and spamming other
          entrants; engaging in Collusion with other contest participants;
          abusing the Wager Town™ Site in any way; or using language that in the
          sole discretion of Wager Town™ is deemed offensive, intimidating, or
          in violation of the spirit of building a healthy and friendly fantasy
          sports environment. You further acknowledge that the forfeiture and/or
          return of any prize shall in no way prevent Wager Town™ from pursuing
          criminal or civil proceedings in connection with such conduct.
        </p>
        <h3>14. TECHNICAL MALFUNCTIONS</h3>
        <p>
          You will not hold Wager Town™ or its partners responsible for: any
          incorrect, invalid or inaccurate entry information; human errors;
          technical malfunctions; failures, including public utility or
          telephone outages; omissions, interruptions, latency, deletions or
          defects of any telephone system or network, computer online systems,
          data, computer equipment, servers, providers, or software (including,
          but not limited to, software and operating systems that do not permit
          an entrant to participate in the Service), including without
          limitation any injury or damage to any entrant’s or any other person’s
          computer equipment relating to or resulting from participation in the
          Service; inability to access the Site, or any web pages of Wager
          Town™; theft, tampering, destruction, or unauthorized access to, or
          alteration of, entries and/or images of any kind; data that is
          processed late or incorrectly or is incomplete or lost due to
          telephone, computer or electronic malfunction or traffic congestion on
          telephone lines or transmission systems, or the Internet, or any
          Service provider’s facilities, or any Site for any other reason
          whatsoever; typographical, printing or other errors; or any
          combination thereof. Wager Town™ and any partners are also not
          responsible for incomplete, illegible, misdirected or stolen entries.
          If for any reason the Service is not capable of running as originally
          planned, as well as if the Site or Service becomes corrupted or does
          not allow for the proper usage and processing of entries in accordance
          with the rules, or if infection by a computer (or similar) virus,
          bugs, tampering, unauthorized intervention, actions by entrants,
          fraud, technical failures, or any other causes of any kind, in the
          sole opinion of Wager Town™ corrupts or affects the administration,
          security, fairness, integrity or proper conduct of the Service, Wager
          Town™ reserves the right, in its sole discretion, to disqualify any
          individual implicated in such action and/or to cancel, terminate,
          extend, modify or suspend the Service, and select the winner(s) from
          all eligible entries received. If such cancellation, termination,
          modification or suspension occurs, notification will be posted on the
          Wager Town™ Site. From time to time servers may overload and data may
          be lost. Wager Town™ assumes no responsibility for the occasional loss
          of data, irrespective of whether this loss is temporary or permanent
          in nature. The failure of Wager Town™ to comply with any provision of
          these rules due to an act of god, hurricane, war, fire, riot,
          earthquake, terrorism, act of public enemies, actions of governmental
          authorities outside of the control of Wager Town™ (excepting
          compliance with applicable codes and regulations) or other force
          majeure event will not be considered a breach of these terms.
        </p>
        <h3>15. TAXATION</h3>
        <p>
          In early January of each year all winners who have won $600 or more
          (net of all entry fees) over the previous year will be contacted to
          provide address and social security details. These details will be
          used to file a 1099-MISC with the Internal Revenue Service. Paying
          taxes shall be the sole obligation of each user.
        </p>
        <h3>
          16. NON-AFFILIATION WITH ANY SPORTS LEAGUE OR PLAYERS ASSOCATION
        </h3>
        <p>
          In early January of each year all winners who have won $600 or more
          (net of all entry fees) over the previous year will be contacted to
          provide address and social security details. These details will be
          used to file a 1099-MISC with the Internal Revenue Service. Paying
          taxes shall be the sole obligation of each user.
        </p>
        <h3>17. INDEMNITY</h3>
        <p>
          BY USING THE Wager Town™ SERVICE OR ACCEPTING ANY PRIZE, YOU AGREE TO
          RELEASE AND TO HOLD HARMLESS Wager Town™ AND EACH OF ITS SUBSIDIARIES,
          AFFILIATES AND AGENCIES, AS WELL AS THE OFFICERS, DIRECTORS,
          EMPLOYEES, SHAREHOLDERS AND REPRESENTATIVES OF ANY OF THE FOREGOING
          ENTITIES FROM ANY AND ALL LIABILITY, CLAIMS OR ACTIONS OF ANY KIND
          WHATSOEVER, INCLUDING, BUT NOT LIMITED TO, FOR INJURIES, DAMAGES OR
          LOSSES TO PERSONS AND PROPERTY WHICH MAY BE SUSTAINED IN CONNECTION
          WITH PARTICIPATION IN THE SERVICE, THE RECEIPT, OWNERSHIP, USE OR
          MISUSE OF ANY PRIZE AND CLAIMS BASED ON PUBLICITY RIGHTS, DEFAMATION
          OR INVASION OF PRIVACY.
        </p>
        <h3>18. NO WARRANTIES</h3>
        <p>
          TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, Wager
          Town™ DISCLAIMS ALL WARRANTIES--STATUTORY, EXPRESS OR
          IMPLIED--INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-
          INFRINGEMENT OF PRORPIETARY RIGHTS. FURTHER, YOU EXPRESSLY UNDERSTAND
          AND AGREE THAT YOUR USE OF THE SITE IS AT YOUR SOLE RISK. THE SITE IS
          PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. THE SERVICE, CONTENT,
          AND SITE ARE PROVIDED ON AN &quot;AS IS&quot; BASIS, WITHOUT
          WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT
          LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE OR NON- INFRINGEMENT. YOU ACKNOWLEDGE THAT Wager
          Town™ HAS NO CONTROL OVER, AND NO DUTY TO TAKE ANY ACTION REGARDING:
          WHICH USERS GAIN ACCESS TO THE SITE OR USE THE SITE; WHAT EFFECTS THE
          CONTENT MAY HAVE ON YOU; HOW YOU MAY INTERPRET OR USE THE CONTENT; OR
          WHAT ACTIONS YOU MAY TAKE AS A RESULT OF HAVING BEEN EXPOSED TO THE
          CONTENT. YOU RELEASE Wager Town™ FROM ALL LIABILITY FOR YOUR HAVING
          ACQUIRED OR NOT ACQUIRED CONTENT THROUGH THE SERVICE OR THE SITE. THE
          SITE MAY CONTAIN OR DIRECT YOU TO SITES CONTAINING INFORMATION THAT
          SOME PEOPLE MAY FIND OFFENSIVE OR INAPPROPRIATE. Wager Town™ MAKES NO
          REPRESENTATIONS CONCERNING ANY CONTENT CONTAINED IN OR ACCESSED
          THROUGH THE SITE AND Wager Town™ WILL NOT BE RESPONSIBLE OR LIABLE FOR
          THE ACCURACY, COPYRIGHT COMPLIANCE, LEGALITY OR DECENCY OF MATERIAL
          CONTAINED IN OR ACCESSED THROUGH THE SITE. ANY MATERIAL DOWNLOADED OR
          OTHERWISE OBTAINED THROUGH THE USE OF THE SITE OR SERVICE IS DONE AT
          YOUR OWN RISK. YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
          COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OR USE
          OF DOWNLOADED MATERIAL.
        </p>

        <h3>19. LIMITATION ON LIABILITY</h3>
        <p>
          YOU UNDERSTAND AND AGREE THAT, UNDER NO CIRCUMSTANCES, AND UNDER NO
          LEGAL THEORY, SHALL Wager Town™ BE LIABLE TO YOU FOR ANY INDIRECT,
          INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING
          WITHOUT LIMITATION DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR
          OTHER INTANGIBLE LOSSES (EVEN IF Wager Town™ HAD BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (i) THE USE OR THE
          INABILITY TO USE THE SITE; (ii) UNAUTHORIZED ACCESS TO OR ALTERATION
          OF YOUR TRANSMISSIONS OR DATA; (iii) STATEMENTS OR CONDUCT OF ANY
          THIRD PARTY ON THE SERVICE; OR (iv) ANY OTHER MATTER RELATING TO THE
          SITE. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, Wager
          Town™’S LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF
          THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT
          PAID, IF ANY, BY YOU TO Wager Town™ FOR GENERAL USE OF THE SITE DURING
          THE TERM OF YOUR REGISTRATION FOR THE SITE, NOT INCLUDING ANY ENTRY
          FEES FOR CONTESTS COMPLETED PRIOR TO THE CIRCUMSTANCES GIVING RISE TO
          THE CLAIM. THIS LIMITATION ON LIABILITY SHALL EXTEND TO THE FULLEST
          EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAWS.
        </p>
        <h3>20. OUR PROPRIETARY RIGHTS</h3>
        <p>
          All title, ownership and intellectual property rights in and to the
          Site are owned by Wager Town™ or its licensors. You acknowledge and
          agree that the Site contains proprietary and confidential information
          that is protected by applicable intellectual property and other laws.
          Except as expressly authorized by Wager Town™, you agree not to
          modify, rent, lease, loan, sell, publicly display, distribute or
          create derivative works based on the Site, in whole or in part.
        </p>
        <h3>21. LINKS TO THIRD PARTY SITES</h3>
        <p>
          The Site provides, or third parties may provide, links to other World
          Wide Web sites or resources, including, but not limited to, third
          party advertisers. Recognizing that Wager Town™ has no control over
          such sites and resources, you acknowledge and agree that Wager Town™
          is not responsible for the availability of such external sites or
          resources, and does not endorse and is not responsible or liable for
          any content, advertising, products or other materials on or available
          from such sites or resources. You further acknowledge and agree that
          Wager Town™ shall not be responsible or liable, directly or
          indirectly, for any damage or loss caused or alleged to be caused by
          or in connection with use of or reliance on any such content, goods or
          services available on or through any such Site or resource. ACCESS AND
          USE OF THIRD PARTY SITES, INCLUDING THE INFORMATION, MATERIALS,
          PRODUCTS AND SERVICES OF THIRD PARTY SITES OR AVAILABLE THROUGH THIRD
          PARTY SITES IS SOLELY AT YOUR OWN RISK.
        </p>
        <h3>22. TERMINATION</h3>
        <p>
          Wager Town™ may terminate or suspend the Site and any and all Services
          and your Wager Town™ account immediately, without prior notice or
          liability, if you breach any of the terms or conditions of the Terms
          of Service. Upon termination of your account, your right to use the
          Site will immediately cease, and all rights under this contract shall
          cease other than those deemed to survive termination under Section 33
          of the Terms. If you wish to terminate your Wager Town™ account, you
          may simply discontinue using the Site or contact us with a note to say
          you wish to terminate your account. If you contact us expressing the
          desire to permanently close your account, we will do so at any time,
          for any reason. The best way to contact us about terminating your
          account is by emailing us at tos@wager.town.
        </p>

        <h3>23. NO THIRD PARTY BENEFICIARIES</h3>
        <p>
          You agree that, except as otherwise expressly provided in these Terms,
          there shall be no third party beneficiaries to these Terms of Service.
          This means, among other things, that you agree, to the full extent
          permissible by law, no third party shall have any rights to bring
          claims arising out of these Terms of Service on your behalf or to
          claim for themselves rights that are afforded to you under this
          agreement.
        </p>
        <h3>
          24. NOTICE AND PROCEDURE FOR MAKING CLAIMS OF COPYRIGHT INFRINGEMENT
        </h3>
        <p>
          Wager Town™ may, in appropriate circumstances and at its discretion,
          disable and/or terminate the accounts of users who infringe the
          intellectual property of others. If you are a copyright owner, or an
          agent of a copyright owner, and believe that any content infringes
          upon your copyrights, you may submit notification pursuant to the
          Digital Millennium Copyright Act (&quot;DMCA&quot;) by providing our
          designated Copyright Agent, with the following information in writing
          (see 17 USC 512(c)(3)): (i) a physical or electronic signature of a
          person authorized to act on behalf of the owner of an exclusive right
          that is allegedly infringed; (ii) identification of the copyrighted
          work claimed to have been infringed, or, if multiple copyrighted works
          at a single online site are covered by a single notification, a
          representative list of such works at that site; (iii) identification
          of the material that is claimed to be infringing or to be the subject
          of infringing activity and that is to be removed or access to which is
          to be disabled, and information reasonably sufficient to permit the
          service provider to locate the material; (iv) information reasonably
          sufficient to permit the service provider to contact the complaining
          party, such as an address, telephone number, and, if available, an
          electronic mail address at which the complaining party may be
          contacted; (v) a statement that the complaining party has a good faith
          belief that use of the material in the manner complained of is not
          authorized by the copyright owner, its agent, or the law; and (vi) a
          statement that the information in the notification is accurate, and
          under penalty of perjury, that the complaining party is authorized to
          act on behalf of the owner of an exclusive right that is allegedly
          infringed. Wager Town™’s designated Copyright Agent to receive
          notifications of claimed infringement can be reached at
          tos@wager.town. Only DMCA notices should be sent to the Copyright
          Agent. Any other communications should be directed to the appropriate
          channels as indicated by clicking on the &quot;Contact Us&quot; link.
        </p>
        <h3>25. CHOICE OF LAW</h3>
        <p>
          You agree that any claim you may have against Wager Town™,
          irrespective of whether the claim that is brought arises under
          contract, tort or other legal theory, shall be interpreted exclusively
          under the law of the State of New York. No user of this site,
          successor in interest, or any other party seeking to bring suit on
          behalf of the interests of a user may attempt to argue any state law
          claims under any theory other than the State of New York. THIS CHOICE
          OF LAW CLAUSE SHALL APPLY WITHOUT REGARD TO CONFLICT OF LAW PRINCIPLES
          AND IRRESPECTIVE OF THE UNDERLYING CAUSE OF ACTION.
        </p>
        <h3>26. ARBITRATION AND CLASS ACTION WAIVER</h3>
        <p>
          PLEASE READ THIS SECTION CAREFULLY – IT MAY SIGNIFICANTLY AFFECT YOUR
          LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT: Any
          controversy or claim arising out of, in connection with, or in any way
          relating to the Terms of Service, with the exception of those
          controversies or claims specifically excluded in the following
          paragraph, shall be settled by the American Arbitration Association
          (“AAA”) in connection with its rules. The arbitrator, and not any
          federal, state or local court or agency, shall have exclusive
          authority to resolve all disputes arising out of or relating to the
          interpretation, applicability, enforceability or formation of these
          Terms, including, but not limited to any claim that all or any part of
          these Terms are void or voidable, or whether a claim is subject to
          arbitration. The arbitrator shall be empowered to grant whatever
          relief would be available in a court under law or in equity. The
          arbitrator’s award shall be written, and binding on the parties and
          may be entered as a judgment in any court of competent jurisdiction.
          Any such arbitration shall be held within a fifty mile radius of
          Brooklyn, NY. You and Wager Town™ agree that the following types of
          disputes are not subject to this binding arbitration provision: (1)
          any disputes seeking to enforce or protect, or concerning the validity
          of either any of your intellectual property rights or Wager Town™’s
          intellectual property rights; and (2) any claim for injunctive relief.
          The Commercial Arbitration Rules governing the arbitration may be
          accessed at www.adr.org or by calling the AAA at +1.800.778.7879. In
          addition, you and Wager Town™ agree that any arbitration shall be
          conducted in their individual capacities only and not as a class
          action or other representative action, and the parties expressly waive
          their right to file a class action or seek relief on a class basis.
          YOU AND Wager Town™ AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER
          ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR
          CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. If
          any court or arbitrator determines that the class action waiver set
          forth in this paragraph is void or unenforceable for any reason or
          that an arbitration can proceed on a class basis, then the arbitration
          provision set forth above shall be deemed null and void in its
          entirety and the parties shall be deemed to have not agreed to
          arbitrate disputes. You have the right to opt-out and not be bound by
          the arbitration and class action waiver provisions set forth above by
          sending written notice of your decision to opt-out to the following
          address: tos@wager.town. The notice must be sent within 30 days of
          your first use of the Service. Otherwise you shall be bound to
          arbitrate disputes. If you opt-out of these arbitration provisions,
          Wager Town™ also will not be bound by them. Wager Town™ will provide
          60-days’ notice of any changes to this section. Changes will become
          effective on the 60th day, and will apply prospectively only to any
          claims arising after the 60th day.
        </p>
        <h3>27. FORUM SELECTION</h3>
        <p>
          For any dispute not subject to arbitration, or where no election to
          arbitrate has been made, you and Wager Town™ agree to submit to the
          personal and exclusive jurisdiction of and venue in the federal and
          state courts located within a fifty mile radius of Brooklyn, NY. You
          further agree to accept service of process by mail, and hereby waive
          any and all jurisdictional and venue defenses otherwise available.
        </p>
        <h3>28. WAIVER AND SEVERABILITY AND TERMS</h3>
        <p>
          The failure of Wager Town™ to exercise or enforce any right or
          provision of the Terms shall not constitute a waiver of such right or
          provision. If any provision of the Terms is found by a court of
          competent jurisdiction to be invalid, the parties nevertheless agree
          that the court should endeavor to give effect to the parties’
          intentions as reflected in the provision, and the other provisions of
          the Terms remain in full force and effect.
        </p>
        <h3>29. ASSIGNMENT</h3>
        <p>
          The Terms, and any rights and licenses granted hereunder, may not be
          transferred or assigned by you but may be assigned by Wager Town™
          without restriction. Any assignment attempted to be made in violation
          of these Terms of Service shall be deemed void. In addition, you agree
          that to the fullest extent permissible by law, no third party shall
          have any rights to bring claims arising out of this Terms of Service
          on your behalf or to claim for themselves rights that are afforded to
          you under this agreement.
        </p>
        <h3>30. NO AGENCY</h3>
        <p>
          You agree that no joint venture, partnership, employment, or agency
          relationship of any kind exists between you and Wager Town™. YOU
          FURTHER ACKNOWLEDGE THAT BY SUBMITTING USER CONTENT TO Wager Town™, NO
          CONFIDENTIAL, FIDUCIARY, CONTRACTUALLY IMPLIED OR OTHER RELATIONSHIP
          IS CREATED BETWEEN YOU AND Wager Town™.
        </p>
        <h3>31. STATUTE OF LIMITATIONS</h3>
        <p>
          You agree that regardless of any statute or law to the contrary, any
          claim or cause of action arising out of or related to the use of the
          Site or the Terms must be filed within one (1) year after such claim
          or cause of action arose or be forever barred.
        </p>
        <h3>32. HEADINGS</h3>
        <p>
          The headings and section titles in the Terms are for convenience only
          and have no legal or contractual effect.
        </p>
        <h3>33. SURVIVAL</h3>
        <p>
          The following sections of these Terms shall survive the termination of
          the Terms and/or your account, irrespective of whether terminated by
          you or Wager Town™: Sections 6, 8, 9, 12, 14, 17, 18, 19, 20, 21, 22,
          23, 25, 26, 27, 29, 30, 31 and 33.
        </p>
      </Container>
      <br />
      <div className="text-center">
        <LinkContainer to={`/`}>
          <Button className="w-75 logout-button">Go to Home</Button>
        </LinkContainer>
      </div>

      <br />
      <hr className="cr" />
      <div className="text-center social-links">
        <span className="special-link small">Join our community!</span>
        <br />
        <br />
        <a href="https://www.facebook.com/wagertownusa">
          <FontAwesomeIcon icon={faFacebook} size="2x" />
        </a>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <a href="http://twitter.com/wagertownusa/">
          <FontAwesomeIcon icon={faTwitter} size="2x" />
        </a>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <a href="https://discord.com/channels/723239008142098575/723239008943210519">
          <FontAwesomeIcon icon={faDiscord} size="2x" />
        </a>
        <p className="special-link small text-center">
          Super Jumbo, Inc © 2020
        </p>
      </div>
    </Container>
  );
};

export default TosRoute;
