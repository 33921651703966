import { API_ROOT } from "./constants";
import { fetchWithHeaders } from "./network";

/**
 * Uploads files if necessary.
 *
 * Accepts either a file object or a string (the output of
 * an ImageFieldWithFeedback). If passed a file it will upload
 * that file to the backend and return the URL. Otherwise it just
 * returns the string (in case the user manually specifies a URL).
 */
export const uploadIfNecessary = async (
  value: File | string
): Promise<string> => {
  if (typeof value === "string") {
    return value;
  }

  const formData = new FormData();
  formData.append("data", value);
  const response = await fetchWithHeaders(`${API_ROOT}/upload`, {
    body: formData,
    method: "POST",
  });
  const url = (await response.json()).data;
  return url;
};
