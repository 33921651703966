import React from "react";
import Container from "react-bootstrap/Container";

import GroupForm from "jumbo/components/forms/group-form";

import connectRoute, { ConnectedRouteProps } from "./connect-route";

export const CreateGroupRoute: React.FC<ConnectedRouteProps> = ({
  actions,
  history,
}) => {
  return (
    <Container>
      <h4>👥 Create your team</h4>
      <GroupForm
        onSubmit={async (data: any) => {
          const response = await actions.createGroup(data);
          const group = response.body;
          await actions.joinGroup(group.id);
          await actions.fetchGroups();
          await actions.getCurrentUser();
          history.push(`/team/${group.id}`);
        }}
      />
      <br />
    </Container>
  );
};
export default connectRoute(CreateGroupRoute, "Create Team");
