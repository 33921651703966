import React from "react";
import Container from "react-bootstrap/Container";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { bindActionCreators } from "redux";

import LoadingIndicator from "jumbo/components/loading-indicator";
import { actions as formOptionsActions } from "jumbo/store/form-options";
import { actions as gameCodeActions } from "jumbo/store/game-codes";
import { actions as gameActions } from "jumbo/store/game-resource";
import { actions as gameScoreActions } from "jumbo/store/game-score-resource";
import { actions as groupActions } from "jumbo/store/group-resource";
import { actions as locationActions } from "jumbo/store/location";
import { actions as propActions } from "jumbo/store/prop-resource";
import { actions as statsActions } from "jumbo/store/stats";
import { actions as userProfileActions } from "jumbo/store/user-profile-resource";
import { actions as userActions } from "jumbo/store/user-resource";
import { actions as wagerActions } from "jumbo/store/wager-resource";
import { actions as deviceActions } from "jumbo/store/device";

/** These props will be passed to all connected routes. */
export interface ConnectedRouteProps extends RouteComponentProps<any> {
  actions: any;
  store: any;
}

/**
 * This HOC handles some of the boilerplate around routes. This includes
 * connection to the redux store, setting up the title, wrapping in a
 * container, and loading resources.
 * @param component
 * @param title
 */
const connectRoute = (
  Component: React.ComponentType<ConnectedRouteProps>,
  title?: string | ((props: ConnectedRouteProps) => string),
  load?: Array<(actions?: any, match?: any, store?: any) => Promise<any>>
): React.ComponentType<any> => {
  const mapStateToProps = (state: any) => {
    return {
      store: state,
    };
  };

  const mapDispatchToProps = (dispatch: any) => {
    return {
      actions: bindActionCreators(
        {
          ...gameActions,
          ...groupActions,
          ...formOptionsActions,
          ...propActions,
          ...wagerActions,
          ...userActions,
          ...statsActions,
          ...locationActions,
          ...gameCodeActions,
          ...gameScoreActions,
          ...userProfileActions,
          ...deviceActions,
        },
        dispatch
      ),
    };
  };

  class WrapperComponent extends React.Component<
    ConnectedRouteProps,
    { loading: boolean; loadingError: boolean }
  > {
    constructor(props: ConnectedRouteProps) {
      super(props);
      this.state = {
        loading: load !== undefined && load.length > 0,
        loadingError: false,
      };
    }

    public async componentDidMount() {
      await this.loadData();
    }

    public async componentDidUpdate(prevProps: ConnectedRouteProps) {
      // If we change the params we may need to load new data, so we'll
      // reissue the loads. Note that the JSON comparision here is order
      // sensitive, so isn't super reliable.
      if (
        JSON.stringify(this.props.match.params) !==
        JSON.stringify(prevProps.match.params)
      ) {
        this.setState({ loading: true });
        this.loadData();
      }
    }

    public render() {
      return (
        <Container>
          {this.state.loadingError ? (
            <>
              <Helmet>
                <title>Wager Town | Error</title>
              </Helmet>
              <div className="text-danger">
                There was an error while loading.
              </div>
            </>
          ) : this.state.loading ? (
            <>
              <Helmet>
                <title>Wager Town | Loading</title>
              </Helmet>
              <LoadingIndicator />
            </>
          ) : (
            <>
              <Helmet>
                <title>
                  {title
                    ? `Wager Town | ${
                        typeof title === "string" ? title : title(this.props)
                      }`
                    : "Wager Town | Bet on your way."}
                </title>
              </Helmet>
              <Component {...this.props} />
            </>
          )}
        </Container>
      );
    }

    private loadData = async () => {
      if (load !== undefined) {
        // Loads are executed in order instead of parallel.
        for (const toLoad of load) {
          try {
            await toLoad(
              this.props.actions,
              this.props.match,
              this.props.store
            );
          } catch (error) {
            // tslint:disable-next-line: no-console
            console.log("Failed to load route", error);
            this.setState({ loadingError: true });
            return;
          }
        }
        this.setState({ loading: false });
      }
    };
  }

  return connect(mapStateToProps, mapDispatchToProps)(WrapperComponent);
};

export default connectRoute;
