import moment from "moment";
import React from "react";
import Button from "react-bootstrap/Button";
import BootstrapForm from "react-bootstrap/Form";
import { Form } from "react-final-form";

import { uploadIfNecessary } from "jumbo/lib/upload";

import CheckFieldWithFeedback from "./fields/check-field-with-feedback";
import DatetimeFieldWithFeedback from "./fields/datetime-field-with-feedback";
import ImageFieldWithFeedback from "./fields/image-field-with-feedback";
import InputFieldWithFeedback from "./fields/input-field-with-feedback";
import LocationFieldWithFeedback from "./fields/location-field-with-feedback";
import SelectFieldWithFeedback, {
  SelectOption,
} from "./fields/select-field-with-feedback";
import TypeaheadFieldWithFeedback from "./fields/typeahead-field-with-feedback";

export interface FieldProps {
  name: string;
  skip?: boolean;
  label?: string;
  type?: string;
  placeholder?: string;
  options?: SelectOption[];
  typeahead?: boolean;
  validate?: (value: any) => string | undefined;
  search?: (query: string) => Promise<SelectOption[]>;
}

export interface Props {
  onSubmit: (data: any) => void | Promise<void>;
  fields: FieldProps[];
  placeholder?: string;
  submitLabel?: string;
  initialValues?: any;
}

export const preprocessData = async (data: any, fields: FieldProps[]) => {
  // We may need to do some processing here. Specifically in case we have
  // either an image field or a location field, we need to extract the location
  // into lat/lng or upload the image.

  const processedData = { ...data };
  for (const field of fields) {
    if (field.type === "image" && processedData[field.name]) {
      processedData[field.name] = await uploadIfNecessary(
        processedData[field.name]
      );
    }
    if (field.type === "location" && processedData.location) {
      processedData.latitude = processedData.location.lat;
      processedData.longitude = processedData.location.lng;
      delete processedData.location;
    }

    if (field.type === "datetime") {
      processedData[field.name] = moment(processedData[field.name])
        .toDate()
        .toISOString();
    }
  }
  return processedData;
};

/**
 * This supports generic input field forms. You can specify field names, and
 * a custom submit label, but not much else.
 */
const GenericForm: React.FC<Props> = ({
  fields,
  onSubmit,
  submitLabel,
  initialValues,
}) => {
  const modifiedInitialValues =
    initialValues !== undefined ? { ...initialValues } : undefined;
  if (modifiedInitialValues && modifiedInitialValues.latitude !== undefined) {
    modifiedInitialValues.location = {
      lat: +initialValues.latitude,
      lng: +initialValues.longitude,
    };
  }
  return (
    <Form
      initialValues={modifiedInitialValues}
      onSubmit={async (data) => {
        const processedData = await preprocessData(data, fields);
        try {
          await onSubmit(processedData);
        } catch (e) {
          return e;
        }
      }}
      render={({ handleSubmit, submitError }) => (
        <BootstrapForm onSubmit={handleSubmit} noValidate={true}>
          {fields.map((field, i) => {
            if (field.skip) {
              return null;
            }
            switch (field.type) {
              case "select":
              case "multiselect":
                // Selects use a simple select for < 40 options and a typeahead for more.
                // But not for multi select right now.
                return field.options &&
                  (field.options.length > 40 || field.typeahead === true) ? (
                  <TypeaheadFieldWithFeedback
                    key={i}
                    name={field.name}
                    label={field.label}
                    options={field.options}
                    multiple={field.type === "multiselect"}
                    search={field.search}
                  />
                ) : (
                  <SelectFieldWithFeedback
                    key={i}
                    name={field.name}
                    label={field.label}
                    options={field.options || []}
                    multiple={field.type === "multiselect"}
                  />
                );
              case "location":
                return (
                  <LocationFieldWithFeedback
                    key={i}
                    name={field.name}
                    label={field.label}
                  />
                );
              case "check":
                return (
                  <CheckFieldWithFeedback
                    key={i}
                    name={field.name}
                    label={field.label}
                  />
                );
              case "image":
                return (
                  <ImageFieldWithFeedback
                    key={i}
                    name={field.name}
                    label={field.label}
                  />
                );
              case "datetime":
                return (
                  <DatetimeFieldWithFeedback
                    key={i}
                    name={field.name}
                    label={field.label}
                  />
                );
              case "input":
              case "password":
              default:
                return (
                  <InputFieldWithFeedback
                    key={i}
                    name={field.name}
                    type={field.type}
                    label={field.label}
                    placeholder={field.placeholder}
                    validate={field.validate}
                  />
                );
            }
          })}

          <BootstrapForm.Text className="text-danger">
            {submitError}
          </BootstrapForm.Text>
          <div className="text-center">
            <Button
              className="w-100 pink-bet-button fixed-bottom jeopardy"
              type="submit"
              style={{ marginTop: 20 }}
            >
              {submitLabel ? submitLabel : "Submit"}
            </Button>
          </div>
        </BootstrapForm>
      )}
    />
  );
};

export default GenericForm;
