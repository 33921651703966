import { createResource } from "redux-rest-resource";

import { API_ROOT } from "jumbo/lib/constants";

export const { types, actions, rootReducer } = createResource({
  actions: {
    join: { method: "POST", url: "./join" },
    leave: { method: "POST", url: "./leave" },
  },
  name: "group",
  url: `${API_ROOT}/groups/:id`,
});
