import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faSms } from "@fortawesome/free-solid-svg-icons";
import { faGift } from "@fortawesome/pro-light-svg-icons";
import { faHandshakeAlt } from "@fortawesome/pro-light-svg-icons";
import { faShare } from "@fortawesome/pro-light-svg-icons";
import { faUserPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import {
  FacebookShare,
  RedditShare,
  TwitterShare,
  WhatsappShare,
} from "./social-share-buttons";

export interface Props {
  game: Game;
  link: string;
  renderButton?: (onClick: () => void) => JSX.Element;
}

export interface State {
  showModal: boolean;
  linkCopied: boolean;
}

/** Supports native webshare or modal fallback. */
class ShareButtonLong extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      linkCopied: false,
      showModal: false,
    };
  }
  public render() {
    const title = `Here's $1 free to bet on ${this.props.game.name}`;
    const body = `Here's $1 free to bet on ${this.props.game.name} on Wager Town, a new fantasy sports game. Join me! ${this.props.link}`;
    const shareButtonLong = this.props.renderButton ? (
      this.props.renderButton(this.handleClick)
    ) : (
      <div onClick={this.handleClick} style={{ marginTop: 12 }}>
        <span>
          <Button className="logout-button-z">
            <div
              className="jeopardy small nav-love"
              style={{ padding: 4, paddingTop: 8 }}
            >
              {" "}
              <FontAwesomeIcon className="nav-love" icon={faUserPlus} />{" "}
              &nbsp;&nbsp;
              <b>add</b>
            </div>
          </Button>
        </span>
      </div>
    );
    return (
      <>
        {shareButtonLong}
        <Modal
          className="modal-dialog-custom"
          show={this.state.showModal}
          onHide={this.handleModalClose}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body
            style={{
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div style={{ marginBottom: 10 }} className="text-center binary">
              <b>Invite a friend to bet free</b>
            </div>
            <br />
            <span
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginBottom: "20px",
                marginLeft: "10px",
              }}
            >
              <TwitterShare game={this.props.game} gameUrl={this.props.link} />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <FacebookShare game={this.props.game} gameUrl={this.props.link} />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <WhatsappShare game={this.props.game} gameUrl={this.props.link} />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span className="text-center">
                <a href={`sms:?body=${encodeURI(body)}`}>
                  <Button className="bet-button">
                    <span className="binary">
                      <FontAwesomeIcon
                        size="2x"
                        className="td-promo"
                        icon={faSms}
                      />
                    </span>
                  </Button>
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a
                  href={`mailto:?subject=${encodeURI(title)}&body=${encodeURI(
                    body
                  )}`}
                >
                  <Button className="bet-button">
                    <span className="binary">
                      <FontAwesomeIcon
                        size="2x"
                        className="spinner-pink"
                        icon={faEnvelope}
                      />
                    </span>
                  </Button>
                </a>
                <br />
              </span>
            </span>
          </Modal.Body>
        </Modal>
      </>
    );
  }

  private handleClick = () => {
    if ((navigator as any).share) {
      (navigator as any).share({
        text: `Here's $1 free to bet on ${this.props.game.name} on Wager Town, a new fantasy sports game. Join me! ${this.props.link}`,
        title: `Here's $1 free to bet on ${this.props.game.name} on Wager Town, a new fantasy sports game. Join me! ${this.props.link}`,
        url: this.props.link,
      });
    } else {
      this.setState({ showModal: true });
    }
  };

  private handleModalClose = () => {
    this.setState({ showModal: false });
  };

  private copyLink = async () => {
    await navigator.clipboard.writeText(this.props.link);
    this.setState({ linkCopied: true });
  };
}

export default ShareButtonLong;
