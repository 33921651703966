import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import "moment-timezone";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import BootstrapNavbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import { LinkContainer } from "react-router-bootstrap";
import { Link, Redirect } from "react-router-dom";

import LoginForm from "jumbo/components/forms/login-form";
import RegisterForm from "jumbo/components/forms/register-form";
import { GameTimeDisplay } from "jumbo/components/shared/game-time-display";
import StatsFragment from "jumbo/components/stats-fragment";
import { sendEventWithTimeout } from "jumbo/lib/analytics";

import connectRoute, { ConnectedRouteProps } from "./connect-route";

const LOGO = "/static/images/wager-town-banner.png";

export const LoginRegisterRoute: React.FC<ConnectedRouteProps> = ({
  store,
  actions,
}) => {
  const user = store.users.currentUser;
  const referrer = localStorage.getItem("referredBy")
    ? localStorage.getItem("referredBy")
    : null;

  if (user) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const userProfile = store.userProfiles.item;
    return (
      <Redirect
        to={
          urlSearchParams.has("redirect")
            ? (urlSearchParams.get("redirect") as string)
            : "/"
        }
      ></Redirect>
    );
  }
  const isWeekAlmostOver =
    moment().tz("America/New_York").endOf("isoWeek").toISOString() &&
    moment().toISOString() >
      moment()
        .tz("America/New_York")
        .endOf("isoWeek")
        .subtract(72, "hours")
        .toISOString();
  const modTime = moment()
    .tz("America/New_York")
    .startOf("isoWeek")
    .add(7, "days")
    .toISOString();
  return (
    <Row>
      <Container>
        <Container>
          <Link to={`/`} className="quiet-link">
            <h3 className="text-right special-link">
              <FontAwesomeIcon
                className="even-duller"
                size="lg"
                icon={faTimes}
              />
            </h3>
          </Link>
          <BootstrapNavbar expand="lg" className="reg-logo">
            <LinkContainer to="/" className="reg-logo text-center">
              <img src={LOGO} className="reg-logo text-center" />
            </LinkContainer>
          </BootstrapNavbar>

          <Col>
            <h5
              className="text-center binary"
              style={{ paddingBottom: 20, paddingTop: 20 }}
            >
              Create Your Free Account
            </h5>

            <div>
              <RegisterForm
                onRegistrationComplete={async () => {
                  await sendEventWithTimeout(
                    "sign_up",
                    { method: "username" },
                    2000
                  );
                  const urlSearchParams = new URLSearchParams(
                    window.location.search
                  );
                  const newUser = await actions.getCurrentUser();
                  referrer
                    ? actions.updateUserProfile({
                        id: newUser.body.profile.id,
                        referred_by: referrer,
                      })
                    : actions.updateUserProfile({
                        id: newUser.body.profile.id,
                        referred_by: urlSearchParams.get("referred_by"),
                      });
                }}
              />

              <div className="text-center">
                <span className="even-duller text-center small-details-text">
                  <small>
                    By clicking the "Register" button, you agree to Wager
                    Town's&nbsp;
                    <Link className="even-duller" to="tos">
                      Terms
                    </Link>{" "}
                    and&nbsp;
                    <Link to="privacy" className="even-duller">
                      Privacy Policy.
                    </Link>
                    <br />
                    <br />
                  </small>
                </span>
              </div>
            </div>
          </Col>
          <Col>
            <hr />
            <br />
            <h5 className="text-left binary" style={{ paddingBottom: 20 }}>
              Log In
            </h5>

            <LoginForm onLoginComplete={actions.getCurrentUser} />
          </Col>
        </Container>
        <br />
        <br />
        <Container className="text-center nav-color">
          <br />
          <br />
          <span className="p-2 small special-link">
            <Link className="even-duller" to="/rules">
              Rules
            </Link>
          </span>
          <span className="p-2 small special-link">
            <Link className="even-duller" to="/tos">
              Terms
            </Link>
          </span>
          <span className="p-2 small special-link">
            <Link className="even-duller" to="/privacy">
              Privacy
            </Link>
          </span>
          <span className="p-2 small special-link">
            <Link className="even-duller" to="/about">
              About
            </Link>
          </span>
        </Container>
      </Container>
    </Row>
  );
};

export default connectRoute(LoginRegisterRoute, "Login", [
  (actions) => actions.getCurrentUser(),
]);
