import GoogleMapReact from "google-map-react";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import { getGeoPosition } from "jumbo/lib/location";

import FinalFormWrapper, { FinalFormProps } from "./final-form-wrapper";
import GenericFieldWithFeedback, {
  GenericProps,
} from "./generic-field-with-feedback";
import PlacesWrapper from "./places-wrapper";

type Props = GenericProps;

export class LocationFieldWithFeedback extends React.Component<
  Props & FinalFormProps
> {
  private marker: any;
  private maps: any;

  public componentDidUpdate() {
    if (this.props.input.value && this.marker) {
      this.marker.setPosition(
        new this.maps.LatLng(
          this.props.input.value.lat,
          this.props.input.value.lng
        )
      );
    }
  }

  public render() {
    return (
      <GenericFieldWithFeedback
        {...this.props}
        renderInput={({ input }) => (
          <>
            <Form.Row>
              <Col>
                <PlacesWrapper
                  onPlaceChange={(latLng) => input.onChange(latLng)}
                />
              </Col>
              <Col className="col-auto">
                <Button
                  className="bet-button"
                  id="current-location-button"
                  onClick={async () => {
                    const position = await getGeoPosition();
                    input.onChange({
                      lat: position.coords.latitude,
                      lng: position.coords.longitude,
                    });
                  }}
                >
                  📍Set to My Location
                </Button>
              </Col>
            </Form.Row>
            <div className="maps-container">
              <GoogleMapReact
                center={input.value ? input.value : undefined}
                defaultCenter={{ lat: 29.74, lng: -95.35 }}
                defaultZoom={5}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={this.handleApiLoaded}
              ></GoogleMapReact>
            </div>
          </>
        )}
      />
    );
  }

  private handleApiLoaded = ({ map, maps }: { map: any; maps: any }) => {
    this.maps = maps;
    this.marker = new maps.Marker({
      draggable: true,
      map,
      position: this.props.input.value
        ? this.props.input.value
        : { lat: 29.74, lng: -95.35 },
    });
    this.marker.addListener("dragend", () => {
      this.props.input.onChange(this.marker.getPosition().toJSON());
    });
  };
}

export default FinalFormWrapper(LocationFieldWithFeedback);
