import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { faWrench } from "@fortawesome/pro-light-svg-icons";
import { faClipboardCheck } from "@fortawesome/pro-light-svg-icons";
import { faGavel } from "@fortawesome/pro-light-svg-icons";
import { faUserEdit } from "@fortawesome/pro-light-svg-icons";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { faThumbsUp } from "@fortawesome/pro-solid-svg-icons";
import { faThumbsDown } from "@fortawesome/pro-solid-svg-icons";
import { faUserFriends } from "@fortawesome/pro-solid-svg-icons";
import { faHandshake } from "@fortawesome/pro-solid-svg-icons";
import { faCommentPlus } from "@fortawesome/pro-light-svg-icons";
import { faHandPointRight } from "@fortawesome/pro-solid-svg-icons";
import { faHandshakeAlt } from "@fortawesome/pro-solid-svg-icons";
import { faHourglassHalf } from "@fortawesome/pro-solid-svg-icons";
import { faEdit } from "@fortawesome/pro-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import { LinkContainer } from "react-router-bootstrap";
import { Link, Redirect } from "react-router-dom";

import GameCode from "jumbo/components/game-code";
import GameList from "jumbo/components/game-list";
import GameLobby from "jumbo/components/game-lobby";
import ShareButtonShort2 from "jumbo/components/share-button-short2";
import { GameTimeDisplay } from "jumbo/components/shared/game-time-display";
import { WagersByPropRow } from "jumbo/components/WagersByPropRow";
import { loadGameOrAlias } from "jumbo/lib/routing";
import { extractStats } from "jumbo/lib/stats";
import { getSingleStat } from "jumbo/lib/stats";

import connectRoute, { ConnectedRouteProps } from "./connect-route";

export const GameRoute: React.FC<ConnectedRouteProps> = ({
  store,
  actions,
}) => {
  if (!store.users.currentUser) {
    return <Redirect to={`/`} />;
  }
  const game: Game = store.games.item;
  const user: JumboUser = store.users.currentUser;
  const users: JumboUser[] = !!store.users.currentUser
    ? [store.users.currentUser]
    : store.users.currentUser;
  const username = store.users.currentUser.username;
  const userIds = users.map((x) => x.id);
  const isGameRunning = game.end_time
    ? new Date(game.end_time) > new Date()
    : true;
  const isOwner = user && game.user.id === user.id;
  const isGameAlmostOver =
    game.end_time &&
    moment().toISOString() >
      moment(game.end_time).subtract(3, "hours").toISOString();
  const isAdmin = user.profile.is_admin;
  const allWagers: Wager[] = store.wagers.items;
  const wagersByProp = game.props.map((prop) => {
    // Wagers indexed by userId
    const wagers: any = {};
    for (const wager of allWagers) {
      if (wager.prop === prop.id) {
        wagers[wager.user] = wager;
      }
    }
    return {
      prop,
      wagers,
    };
  });

  const relatedGames = store.games.items.filter(
    (x: Game) => x.id !== game.id && x.theme === game.theme
  );
  const gameStats = extractStats(store.stats.stats, getGameStats(game.id));
  const userStats = user
    ? extractStats(store.stats.stats, getUserStats(game.id, user.id))
    : undefined;
  if (
    game.requires_code &&
    store.gameCodes.authorizedGames.indexOf(game.id) === -1
  ) {
    return <GameCode game={game} submitGameCode={actions.submitGameCode} />;
  }
  return (
    <>
      <GameLobby
        game={game}
        user={user}
        stats={gameStats}
        userStats={userStats}
        username={username}
      />
      {isOwner ? (
        <div>
          <Container style={{ marginTop: 10 }}>
            <div className="nav-love">{game.name}</div>
            <div
              className="nav-color-dull small-details-text text-left"
              style={{ marginTop: 2 }}
            >
              You are the Host of this game
            </div>
            <br />
            <hr />

            <div className="text-center" style={{ marginTop: 10 }}>
              {user ? (
                <>
                  {isOwner || isAdmin ? (
                    <>
                      {isGameRunning ? (
                        <h5 className="play-header">
                          <div className="play-header">
                            <Link
                              to={`/game/${game.id}/edit`}
                              style={{ marginTop: 7, marginBottom: 7 }}
                            >
                              <div className="nav-color details-text">
                                <FontAwesomeIcon icon={faWrench} />
                              </div>
                            </Link>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Link
                              to={`/game/${game.id}/grade`}
                              style={{ marginTop: 7, marginBottom: 7 }}
                            >
                              <div className="nav-color details-text">
                                <FontAwesomeIcon icon={faGavel} />
                              </div>
                            </Link>
                          </div>

                          <div className="play-header">
                            <Link
                              to={`/game/${game.id}/create-prop`}
                              style={{ marginTop: 7, marginBottom: 7 }}
                            >
                              {game.props.length < 2 ? (
                                <div className="spinner-pink details-text">
                                  <FontAwesomeIcon icon={faCommentPlus} />
                                  &nbsp;&nbsp;Add
                                </div>
                              ) : (
                                <span className="nav-color details-text">
                                  <FontAwesomeIcon icon={faCommentPlus} />
                                  &nbsp;&nbsp;Add
                                </span>
                              )}
                            </Link>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {game.props.length >= 2 ? (
                              <div
                                className="spinner-pink details-text"
                                style={{ marginTop: 7, marginBottom: 7 }}
                              >
                                <ShareButtonShort2
                                  game={game}
                                  link={`${window.location.protocol}//${window.location.hostname}/game/${game.id}/play?referred_by=${user.username}`}
                                ></ShareButtonShort2>
                              </div>
                            ) : (
                              <div
                                className="nav-color details-text"
                                style={{ marginTop: 7, marginBottom: 7 }}
                              >
                                <ShareButtonShort2
                                  game={game}
                                  link={`${window.location.protocol}//${window.location.hostname}/game/${game.id}/play?referred_by=${user.username}`}
                                ></ShareButtonShort2>
                              </div>
                            )}
                          </div>
                        </h5>
                      ) : (
                        <h5 className="play-header">
                          <Link to={`/game/${game.id}/edit`}>
                            <span className="spinner-pink">
                              <FontAwesomeIcon icon={faWrench} />
                              &nbsp;&nbsp;Modify
                            </span>
                          </Link>

                          <Link to={`/game/${game.id}/grade`}>
                            <span className="spinner-pink">
                              <FontAwesomeIcon icon={faGavel} />
                              &nbsp;&nbsp;Grade
                            </span>
                          </Link>
                        </h5>
                      )}
                    </>
                  ) : null}
                </>
              ) : null}
            </div>

            <hr />
            <br />
            <b className="nav-color-dull small-details-text">Audience Stats</b>
            <hr />

            {user ? (
              <>
                {gameStats ? (
                  <>
                    <div
                      className="leaderboard-header small-details-text"
                      style={{ marginTop: 10 }}
                    >
                      <div className="even-duller small-details-text">
                        <b className="nav-color">
                          {gameStats.get("num_props") || 0}
                        </b>
                        &nbsp;&nbsp;Question(s)
                      </div>
                      <div className="even-duller small-details-text">
                        <b className="nav-color">
                          {(
                            gameStats.get("total_amount_wagered") || 0
                          ).toLocaleString()}
                          ¢
                        </b>
                        &nbsp;&nbsp;Wagered
                      </div>
                      <div className="even-duller small-details-text">
                        <b className="nav-color">
                          {(gameStats.get("num_wagers") || 0).toLocaleString()}
                        </b>
                        &nbsp;&nbsp; Wagers
                      </div>
                    </div>
                  </>
                ) : null}
              </>
            ) : null}
            <hr />
            <br />
          </Container>
        </div>
      ) : (
        <Container style={{ marginTop: 10, marginBottom: 10 }}>
          <div className="nav-color-dull" style={{ marginTop: 2 }}>
            {isAdmin ? (
              <div className="text-right">
                <Link
                  to={`/game/${game.id}/edit`}
                  style={{ marginTop: 7, marginBottom: 7 }}
                >
                  <span className="spinner-pink">
                    <FontAwesomeIcon icon={faWrench} />
                  </span>
                </Link>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Link
                  to={`/game/${game.id}/grade`}
                  style={{ marginTop: 7, marginBottom: 7 }}
                >
                  <span className="spinner-pink">
                    <FontAwesomeIcon icon={faGavel} />
                  </span>
                </Link>
              </div>
            ) : null}
          </div>
        </Container>
      )}

      {!isOwner ? (
        <Container style={{ marginTop: 20 }}>
          <div className="nav-love">
            <div>
              <div className="text-left">
                <h4>Your Picks</h4>
              </div>
            </div>
          </div>

          <div className="nav-color-dull" style={{ marginTop: 5 }}>
            <Table
              className="table-sm leaderboard"
              style={{ marginBottom: 0, marginTop: 10 }}
            >
              <tbody>
                {wagersByProp.map(({ prop, wagers }, i) => {
                  return (
                    <tr key={i}>
                      {userIds.map((userId, j) => {
                        const wager = wagers[userId];
                        return wager === undefined ? (
                          <React.Fragment key={j}></React.Fragment>
                        ) : (
                          <React.Fragment key={j}>
                            <td className="even-duller">
                              <div
                                className="nav-love"
                                style={{ marginRight: 5 }}
                              >
                                <div style={{ marginBottom: 0, marginTop: 0 }}>
                                  <div className="even-duller text-left small">
                                    <br />
                                    <span className="even-duller ">
                                      {game.user.username}
                                    </span>{" "}
                                    is asking
                                  </div>
                                  <div
                                    className="text-left"
                                    style={{ marginTop: 5 }}
                                  >
                                    {prop.prompt}
                                    &nbsp;&nbsp;&nbsp;
                                    <b>
                                      {(prop.multiplier || 0) >= 1 ? (
                                        <span>
                                          +{(prop.multiplier * 100).toFixed(0)}
                                        </span>
                                      ) : (
                                        <span>
                                          -
                                          {(
                                            ((prop.multiplier + 1) /
                                              prop.multiplier -
                                              1) *
                                            100
                                          ).toFixed()}
                                        </span>
                                      )}
                                    </b>
                                  </div>
                                  <br />
                                </div>

                                {wager.prediction === true ? (
                                  <div
                                    className="even-duller  text-left"
                                    style={{ marginTop: 0 }}
                                  >
                                    <div
                                      className="even-duller play-header"
                                      style={{ marginBottom: 5, marginTop: 0 }}
                                    >
                                      <small>You said</small>
                                      <small>Your wager</small>
                                      <small>Proj win</small>
                                    </div>
                                    <div>
                                      <div className="play-header">
                                        <div className="td-positive jeopardy">
                                          <FontAwesomeIcon
                                            className="td-positive"
                                            icon={faThumbsUp}
                                          />
                                          <b>&nbsp;&nbsp;yes</b>
                                          <small></small>
                                        </div>

                                        <div>
                                          <span className="binary">
                                            $
                                            {(wager.amount / 100)
                                              .toFixed(2)
                                              .toLocaleString()}
                                          </span>{" "}
                                        </div>

                                        <div>
                                          <span className="td-positive">
                                            <i>
                                              $
                                              {(
                                                (wager.amount / 100) *
                                                prop.multiplier
                                              )
                                                .toFixed(2)
                                                .toLocaleString()}
                                            </i>
                                          </span>{" "}
                                        </div>
                                      </div>
                                      <br />
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className="even-duller  text-left"
                                    style={{ marginTop: 0 }}
                                  >
                                    <div
                                      className="even-duller play-header"
                                      style={{ marginBottom: 5, marginTop: 5 }}
                                    >
                                      <small>You said</small>
                                      <small>Your wager</small>
                                      <small>Proj win</small>
                                    </div>
                                    <div>
                                      <div className="play-header">
                                        <div className="td-no jeopardy">
                                          <FontAwesomeIcon
                                            className="td-no"
                                            icon={faThumbsDown}
                                          />
                                          <b>&nbsp;&nbsp;no</b>
                                          <small></small>
                                        </div>

                                        <div>
                                          <span className="binary">
                                            $
                                            {(wager.amount / 100)
                                              .toFixed(2)
                                              .toLocaleString()}
                                          </span>{" "}
                                        </div>

                                        <div>
                                          <span className="td-positive">
                                            <i>
                                              $
                                              {(
                                                (wager.amount / 100) *
                                                prop.multiplier
                                              )
                                                .toFixed(2)
                                                .toLocaleString()}
                                            </i>
                                          </span>{" "}
                                        </div>
                                      </div>
                                      <br />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </td>
                          </React.Fragment>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>{" "}
          </div>

          <div className="text-left even-duller" style={{ marginTop: 0 }}>
            {user ? (
              <>
                {gameStats ? (
                  <>
                    <div className="text-left">
                      <div></div>
                      {userStats ? (
                        <div>
                          <Link to={`/game/${game.id}/play`}>
                            <div
                              className="text-left"
                              style={{
                                paddingBottom: 3,
                                paddingTop: 10,
                              }}
                            >
                              <span className="even-duller jeopardy ">
                                <FontAwesomeIcon size="sm" icon={faUserEdit} />
                                &nbsp;&nbsp;change
                              </span>
                            </div>
                          </Link>
                        </div>
                      ) : null}
                    </div>
                  </>
                ) : null}
              </>
            ) : null}
          </div>

          <br />
        </Container>
      ) : (
        <Container>
          <div>
            <b className="nav-color-dull small-details-text">Questions added</b>
            <Table className="table-sm leaderboard" style={{ marginTop: 10 }}>
              <thead>
                <tr>
                  <td className="even-duller small">
                    <span className="even-duller">Question</span>
                  </td>
                  <td className="even-duller small">
                    <span className="even-duller">Odds</span>
                  </td>
                  {users.map((userz, i) => (
                    <React.Fragment key={i}>
                      <td className="text-left small even-duller">
                        <span className="even-duller">Your Pick</span>
                      </td>
                      <td className="text-left small even-duller">
                        <span className="even-duller">Wager</span>
                      </td>
                      <td className="text-left small even-duller">
                        <span className="even-duller">Proj Win</span>
                      </td>
                    </React.Fragment>
                  ))}
                </tr>
              </thead>
              <tbody>
                {wagersByProp.map(({ prop, wagers }, i) => {
                  return (
                    <WagersByPropRow
                      prop={prop}
                      wagers={wagers}
                      userIds={userIds}
                      key={i}
                    />
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Container>
      )}

      {!isOwner ? (
        <Container>
          <div className="text-left" style={{ paddingTop: 15 }}>
            <h4 className="binary text-left" style={{ paddingTop: 0 }}>
              <b>Other games you might like</b>
            </h4>
          </div>
          <br />
          <GameList
            games={relatedGames.slice(0, 5)}
            location={undefined}
            user={user}
            scrollable
          />
        </Container>
      ) : null}
      <br />

      {!isOwner ? (
        <span
          className="play-header"
          style={{ marginTop: 20, marginBottom: 10 }}
        >
          <div>
            <Link to={`/`}>
              <div
                className="td-promo jeopardy"
                style={{ padding: 6, paddingTop: 8 }}
              >
                <FontAwesomeIcon size="sm" icon={faChevronLeft} />
                &nbsp;&nbsp;all games
              </div>
            </Link>
          </div>
          <div>&nbsp;</div>
        </span>
      ) : (
        <span
          className="play-header"
          style={{ marginTop: 20, marginBottom: 10 }}
        >
          <div>
            <Link to={`/`}>
              <div
                className="td-promo jeopardy"
                style={{ padding: 6, paddingTop: 8 }}
              >
                <FontAwesomeIcon size="sm" icon={faChevronLeft} />
                &nbsp;&nbsp;all games
              </div>
            </Link>
          </div>
          <div>&nbsp;</div>
        </span>
      )}
      <br />
    </>
  );
};

export const getGameStats = (game: number) => {
  return [
    { name: "num_props", game },
    { name: "num_wagers", game },
    { name: "num_players", game },
    { name: "total_amount_wagered", game },
    { name: "game_winner", game },
  ];
};

export const getUserStats = (game: number, user: number) => {
  return [
    { name: "num_wagers", game, user },
    { name: "total_amount_wagered", game, user },
  ];
};

export const getAllStats = (
  game: number,
  user: number | undefined = undefined
) => {
  if (user !== undefined) {
    return getGameStats(game).concat(getUserStats(game, user));
  } else {
    return getGameStats(game);
  }
};

export const toWagersByProps = (allWagers: Wager[]) => (prop: any) => ({
  prop,
  wagers: allWagers.reduce(
    (accumulator, currentValue) => ({
      ...accumulator,
      [currentValue.id]: currentValue,
    }),
    {}
  ) as Wager[],
});

export default connectRoute(GameRoute, (props) => props.store.games.item.name, [
  loadGameOrAlias,
  (actions, match, store) =>
    store.users.currentUser
      ? actions.fetchWagers(
          {},
          {
            query: {
              prop__game: store.games.item.id,
              user: store.users.currentUser.id,
            },
          }
        )
      : Promise.resolve(),
  (actions, match, store) =>
    actions.getStats(
      getAllStats(
        store.games.item.id,
        store.users.currentUser ? store.users.currentUser.id : undefined
      )
    ),
  (actions) =>
    actions.fetchGames(
      {},
      {
        query: {
          end_time__gt: moment().toISOString(),
          end_time__lt: moment().endOf("day").add(1, "days").toISOString(),
        },
      }
    ),
]);
