import { faThumbsUp as falThumbsUp } from "@fortawesome/pro-light-svg-icons";
import { faThumbsDown as falThumbsDown } from "@fortawesome/pro-light-svg-icons";
import { faHandshakeAlt as fasHandshakeAlt } from "@fortawesome/pro-light-svg-icons";
import { faThumbsUp as fasThumbsUp } from "@fortawesome/pro-solid-svg-icons";
import { faThumbsDown as fasThumbsDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import BootstrapForm from "react-bootstrap/Form";
import { Field, Form } from "react-final-form";

export interface Props {
  wager?: Wager;
  amountRemaining: number;
  submitLabel?: string;
  multiplier: number;
  user?: JumboUser;
  onSubmit: (data: { amount: number; prediction: boolean }) => void;
}
const WagerForm: React.FC<Props> = ({
  wager,
  user,
  onSubmit,
  amountRemaining,
  submitLabel,
  multiplier,
}) => {
  const initialValues = wager
    ? { amount: wager.amount, prediction: wager.prediction }
    : { amount: Math.floor((amountRemaining || 0) / 2) };
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues as any}
      render={({ handleSubmit, values }) => {
        return (
          <BootstrapForm onSubmit={handleSubmit}>
            <Field name="prediction">
              {(props: any) => {
                const isYesActive = props.input.value === true;
                const isNoActive = props.input.value === false;
                return (
                  <ButtonGroup
                    className="w-100 "
                    style={{ paddingRight: 0, paddingTop: 0 }}
                  >
                    <Button
                      variant="outline-warning"
                      active={isYesActive}
                      style={{ padding: 0 }}
                      onClick={() => {
                        props.input.onChange(true);
                      }}
                    >
                      {isYesActive ? (
                        <div className="nav-love jeopardy pulse">
                          <FontAwesomeIcon
                            className="td-promo"
                            icon={fasThumbsUp}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;<b>yes</b>
                        </div>
                      ) : (
                        <div className="even-duller jeopardy">
                          <FontAwesomeIcon
                            className="even-duller"
                            icon={falThumbsUp}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;yes
                        </div>
                      )}
                    </Button>

                    <Button
                      variant="outline-info"
                      active={isNoActive}
                      style={{ padding: 0 }}
                      onClick={() => {
                        props.input.onChange(false);
                      }}
                    >
                      {isNoActive ? (
                        <div className="nav-love jeopardy pulse">
                          <FontAwesomeIcon
                            className="td-no"
                            icon={fasThumbsDown}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;<b>no</b>
                        </div>
                      ) : (
                        <div className="even-duller jeopardy">
                          <FontAwesomeIcon
                            className="even-duller"
                            icon={falThumbsDown}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;no
                        </div>
                      )}
                    </Button>
                  </ButtonGroup>
                );
              }}
            </Field>

            <div className="text-left" style={{ paddingTop: 8 }}>
              <div className="nav-color lobbytext">
                {" "}
                <div className="nav-color text-center"></div>
                <br />
                <div className="even-duller play-header small-details-text-5">
                  <div className="user-header">What's your wager?</div>
                  <div className="user-header">Proj win</div>
                </div>
                <div className="star-opacity3 play-header">
                  <div className="binary" style={{ paddingTop: 5 }}>
                    <div className="play-header">
                      <span className="nav-color">
                        <div className="user-header">
                          <div className="nav-love big-wager-text">
                            <b>
                              $
                              {(values.amount / 100)
                                .toFixed(2)
                                .toLocaleString()}
                            </b>
                          </div>

                          <div className="even-duller">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <small>
                              <small>x</small>
                            </small>
                          </div>
                          {(multiplier || 0) >= 1 ? (
                            <div className="nav-love">
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; +
                              {(multiplier * 100).toFixed(0)}
                            </div>
                          ) : (
                            <div className="nav-love">
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -
                              {(
                                ((multiplier + 1) / multiplier - 1) *
                                100
                              ).toFixed(0)}
                            </div>
                          )}

                          <div className="even-duller small">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <small>=</small>
                          </div>
                        </div>
                      </span>
                    </div>
                    {user ? (
                      <div
                        className="small even-duller user-header"
                        style={{ paddingTop: 5 }}
                      >
                        $
                        {amountRemaining.toLocaleString() !== undefined
                          ? (amountRemaining / 100).toFixed(2).toLocaleString()
                          : 100}
                        &nbsp;
                        <small className="even-duller">left</small>
                      </div>
                    ) : (
                      <div
                        className="small gold-color user-header"
                        style={{ paddingTop: 5 }}
                      >
                        <b className="td-promo">$1.00</b>
                        &nbsp;&nbsp;
                        <small className="even-duller">left</small>
                      </div>
                    )}
                  </div>
                  <div style={{ paddingTop: 5 }}>
                    <span>
                      <span className="td-promo big-wager-text">
                        <b>
                          <i>
                            $
                            {((values.amount / 100) * multiplier)
                              .toFixed(2)
                              .toLocaleString()}{" "}
                          </i>
                        </b>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ marginTop: 35 }}>
              <Field name="amount" component="input" type="range">
                {({ input }) => (
                  <BootstrapForm.Control
                    {...input}
                    className="custom-range w-100 mt-3"
                    max={
                      amountRemaining.toLocaleString() !== undefined
                        ? amountRemaining
                        : 100
                    }
                  />
                )}
              </Field>
            </div>

            <br />
            {user ? (
              <div className="text-center" style={{ paddingTop: 10 }}>
                <Button
                  className="w-100 pink-bet-button text-center fixed-bottom"
                  type="submit"
                >
                  {submitLabel ? (
                    submitLabel
                  ) : (
                    <h3>
                      <b>Save your pick</b>
                    </h3>
                  )}
                </Button>
              </div>
            ) : (
              <div className="text-center" style={{ paddingTop: 10 }}>
                <Button
                  className="w-100 pink-bet-button text-center fixed-bottom"
                  type="submit"
                >
                  {submitLabel ? (
                    submitLabel
                  ) : (
                    <h3 className="binary ">Save your pick</h3>
                  )}
                </Button>
              </div>
            )}
          </BootstrapForm>
        );
      }}
    />
  );
};

export default WagerForm;
