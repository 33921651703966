import { faFacebook, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faCog } from "@fortawesome/pro-light-svg-icons";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { faCross } from "@fortawesome/pro-light-svg-icons";
import { faGavel } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Link, Redirect } from "react-router-dom";

import StatsFragment from "jumbo/components/stats-fragment";
import UserStatsPremium from "jumbo/components/user-stats-premium";
import { extractStats } from "jumbo/lib/stats";
import connectRoute, { ConnectedRouteProps } from "jumbo/routes/connect-route";

const relevantStats = (user: number) => {
  return [
    { name: "weekly_score_total", user },
    { name: "yesterday_change", user },
  ];
};

export const ThroneRoomRoute: React.FC<ConnectedRouteProps> = ({ store }) => {
  if (!store.users.currentUser) {
    return <Redirect to={`/`} />;
  }

  const hasPaypal =
    store.userProfiles.item && store.userProfiles.item.paypal_username !== null;

  return (
    <div>
      <Container>
        <div>
          <div className="even-duller">
            <div>
              <div className="text-right prop-content-hack-3">
                <Link to={`/inbox`}>
                  <Button className="logout-button-z">
                    <div
                      className="jeopardy small nav-love"
                      style={{ padding: 4, paddingTop: 8 }}
                    >
                      <b>
                        {" "}
                        <StatsFragment
                          statDefinition={{
                            name: "weekly_games_total",
                            user: store.users.currentUser.id,
                          }}
                          render={(value) => {
                            return (
                              <div>
                                <FontAwesomeIcon
                                  className="td-promo"
                                  icon={faGavel}
                                />{" "}
                                &nbsp;&nbsp;
                                <span className="td-promo">
                                  <b>{value}</b>
                                </span>
                              </div>
                            );
                          }}
                        />
                      </b>
                    </div>
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="binary text-left">
            <h2>{store.users.currentUser.username}</h2>
          </div>
        </div>
      </Container>

      <div>
        <UserStatsPremium
          stats={extractStats(
            store.stats.stats,
            relevantStats(store.users.currentUser.id)
          )}
          username={store.users.currentUser.username}
          paypal_username={store.userProfiles.item.paypal_username}
        />
      </div>
      <Row style={{ paddingTop: 0 }}>
        <Col>
          <hr />
          <div className="text-center nav-footer">
            <a href="http://www.facebook.com/wagertownusa">
              <FontAwesomeIcon
                icon={faFacebook}
                size="lg"
                className="even-duller"
              />
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a href="http://twitter.com/wagertownusa/">
              <FontAwesomeIcon
                icon={faTwitter}
                size="lg"
                className="even-duller"
              />
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a href="mailto:support@wager.town">
              <FontAwesomeIcon
                className="even-duller"
                size="lg"
                icon={faEnvelope}
              />
            </a>
          </div>
          <hr />
        </Col>
      </Row>
      <br />

      <p className="even-duller small text-center">
        "Tennis is just a game, family is forever."
        <br />– Serena Williams
      </p>

      <div className="text-center">
        <FontAwesomeIcon className="even-duller text-center" icon={faCross} />
      </div>
      <br />
    </div>
  );
};

export default connectRoute(ThroneRoomRoute, "Your Balance", [
  (actions) => actions.getCurrentUser(),
  (actions, match, store) =>
    store.users.currentUser
      ? actions.getStats(relevantStats(store.users.currentUser.id))
      : Promise.resolve(),
  (actions, match, store) =>
    store.users.currentUser
      ? actions.getUserProfile(store.users.currentUser.profile.id)
      : Promise.resolve(),
]);
