import { faThumbsUp } from "@fortawesome/pro-solid-svg-icons";
import { faThumbsDown } from "@fortawesome/pro-solid-svg-icons";
import { faEdit } from "@fortawesome/pro-solid-svg-icons";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";

import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import { LinkContainer } from "react-router-bootstrap";

import { getAllStats } from "jumbo/routes/game";
import Button from "react-bootstrap/Button";
import { WagersByPropRow } from "jumbo/components/WagersByPropRow";
import { Link, Redirect } from "react-router-dom";
import { GameTimeDisplay } from "jumbo/components/shared/game-time-display";
import connectRoute, { ConnectedRouteProps } from "./connect-route";

export const WagersRoute: React.FC<ConnectedRouteProps> = ({
  store,
  history,
  actions,
}) => {
  const user: JumboUser = store.users.currentUser;
  const wagersWithProps = store.wagers.items.map((wager: Wager) => {
    const prop = store.props.items.filter((prop: Prop) => {
      return prop.id === wager.prop;
    })[0];
    const game = store.games.items.filter(
      (game: Game) => game.id === prop?.game || false
    )[0];

    return {
      ...wager,
      prop,
      game,
    };
  });
  const tableConfig = wagersWithProps.reduce(
    (accumulator: any, currentValue: any) => {
      if (!currentValue.prop || !currentValue.game) {
        return { ...accumulator };
      }
      const gameId: string = currentValue.game?.id;
      const game = accumulator[gameId] || {
        ...currentValue.game,
        props: [],
        wagers: [],
      };

      return {
        ...accumulator,
        [gameId]: {
          ...game,
          props: [...game.props, currentValue.prop],
          wagers: [...game.wagers, currentValue],
        },
      };
    },
    {}
  );

  return (
    <Container>
      <div style={{ paddingBottom: 10, paddingTop: 10 }}>
        <div
          className="binary text-center h1-companion"
          style={{ paddingTop: 10, paddingBottom: 0 }}
        >
          <b>Pending Bets</b>
        </div>
      </div>

      {Object.values(tableConfig).map((section: any, index: number) => (
        <section key={index} className="mt-3 mb-1 pt-1">
          <div className="play-header">
            <h4 className="h6 mb-1">{section.name}</h4>
            <small className="small mb-1 even-duller">
              <small>
                <GameTimeDisplay endTime={section.end_time} />
              </small>
            </small>
          </div>
          <Table className="table-sm leaderboard" style={{ marginTop: 5 }}>
            <thead>
              <tr>
                <td className="even-duller small">
                  <span className="even-duller">Question</span>
                </td>
                <td className="even-duller small">
                  <span className="even-duller">Odds</span>
                </td>
                <td className="text-left small even-duller">
                  <span className="even-duller">Your Pick</span>
                </td>

                <td className="text-left small even-duller">
                  <span className="even-duller">Proj Win</span>
                </td>
              </tr>
            </thead>
            <tbody>
              {section.props.map((prop: Prop, index: number) => {
                const wagers = section.wagers.filter(
                  (wager: any) => wager.prop.id === prop.id
                )[0];

                return (
                  <tr key={index} className="wager-table__row">
                    <td className="content-44 nav-color-dull align-middle">
                      <div className="align-middle small binary user-header">
                        {prop.prompt}
                      </div>
                    </td>
                    <td className="text-center">
                      <span className="wager small">
                        {(prop.multiplier || 0) >= 1 ? (
                          <span className="user-header">
                            +{(prop.multiplier * 100).toFixed(0)}
                          </span>
                        ) : (
                          <span className="user-header">
                            -
                            {(
                              ((prop.multiplier + 1) / prop.multiplier - 1) *
                              100
                            ).toFixed()}
                          </span>
                        )}
                      </span>
                    </td>
                    <td className="even-duller text-center">
                      {wagers.prediction === true ? (
                        <div className="td-promo jeopardy">
                          <FontAwesomeIcon
                            className="td-positive"
                            size="sm"
                            icon={faThumbsUp}
                          />
                          <b>&nbsp;&nbsp;yes</b>
                        </div>
                      ) : (
                        <b className="td-no jeopardy">
                          <FontAwesomeIcon
                            className="td-negative"
                            size="sm"
                            icon={faThumbsDown}
                          />
                          <b>&nbsp;&nbsp;no</b>
                        </b>
                      )}
                    </td>

                    <td className="small td-promo text-center">
                      <i>
                        $
                        {((wagers.amount / 100) * (prop.multiplier || 1))
                          .toFixed(2)
                          .toLocaleString()}{" "}
                      </i>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </section>
      ))}
    </Container>
  );
};

export default connectRoute(WagersRoute, "Wagers", [
  (actions) => actions.getCurrentUser(),
  (actions, match, store) => {
    return store.users.currentUser
      ? actions.fetchWagers(
          {},
          {
            query: {
              user: store.users.currentUser.id,
              end_time__gt: moment()
                .tz("America/New_York")
                .startOf("week")
                .toISOString(),
              end_time__lt: moment()
                .tz("America/New_York")
                .endOf("week")
                .toISOString(),
            },
          }
        )
      : Promise.resolve();
  },
  (actions, match) =>
    actions.fetchProps(
      {},
      {
        query: {
          game__end_time__gt: moment()
            .tz("America/New_York")
            .startOf("week")
            .toISOString(),
          game__end_time__lt: moment()
            .tz("America/New_York")
            .endOf("week")
            .toISOString(),
        },
      }
    ),
  (actions, match) =>
    actions.fetchGames(
      {},
      {
        query: {
          end_time__gt: moment()
            .tz("America/New_York")
            .startOf("week")
            .toISOString(),
          end_time__lt: moment()
            .tz("America/New_York")
            .endOf("week")
            .toISOString(),
        },
      }
    ),
]);
