import moment from "moment";
import React, { useEffect, useState } from "react";

export interface Props {
  endTime: string | undefined;
}

export const GameTimeDisplayAlt: React.FC<Props> = ({ endTime }) => {
  const getEndTimeString = (endTimeString: string | undefined) => {
    const [currentTime, setCurrentTime] = useState(moment());
    const expiration = moment(endTimeString);
    const diff = expiration.diff(currentTime);
    const diffDuration = moment.duration(diff);
    const monthsCount = diffDuration.months();
    const daysCount = diffDuration.days();
    const hoursCount = diffDuration.hours();
    const minutesCount = diffDuration.minutes();
    const secondsCount = diffDuration.seconds();
    const secondsFormatted = ("0" + secondsCount).slice(-2);
    const minutesFormatted = ("0" + minutesCount).slice(-2);
    const hoursFormatted = ("0" + hoursCount).slice(-2);

    useEffect(() => {
      const id = setInterval(() => {
        setCurrentTime(moment());
      }, 1000);
      return () => clearInterval(id);
    }, [setCurrentTime]);

    if (monthsCount > 0) {
      return `${monthsCount} ${monthsCount > 1 ? "mo" : "mo"}`;
    } else if (daysCount > 0) {
      return `${daysCount}${
        daysCount > 1 ? " days" : " day"
      }, ${hoursCount}h, ${minutesFormatted}m, ${secondsFormatted}s`;
    } else if (hoursCount > 0) {
      return `${hoursCount}h, ${minutesFormatted}m`;
    } else if (minutesCount > 0) {
      return `${minutesFormatted}:${secondsFormatted}`;
    } else if (secondsCount > 0) {
      return `00:${secondsFormatted}`;
    } else if (monthsCount < 0) {
      return `${Math.abs(monthsCount)} ${
        monthsCount < -1 ? "months" : "month"
      } ago`;
    } else if (daysCount < 0) {
      return `${Math.abs(daysCount)} ${daysCount < -1 ? "d" : "d"} ago`;
    } else if (hoursCount < 0) {
      return `${Math.abs(hoursCount)} ${hoursCount < -1 ? "h" : "h"} ago`;
    } else if (minutesCount < 0) {
      return `${Math.abs(minutesCount)} ${minutesCount < -1 ? "m" : "m"} ago`;
    } else if (secondsCount < 0) {
      return `${Math.abs(secondsCount)} ${secondsCount < -1 ? "s" : "s"} ago`;
    }
    return "Game Ended";
  };
  return <React.Fragment>{getEndTimeString(endTime)}</React.Fragment>;
};
