import React, { useState } from "react";
import BootstrapAlert, { AlertProps } from "react-bootstrap/Alert";

interface Props extends AlertProps {
  alertKey?: string;
}

const getLocalStorageKey = (alertKey: string) => `alert-${alertKey}`;

const Alert: React.FC<Props> = ({ alertKey, children, ...rest }) => {
  const [show, setShow] = useState(
    alertKey
      ? localStorage.getItem(getLocalStorageKey(alertKey)) === null
      : true
  );

  const close = () => {
    if (alertKey !== undefined) {
      localStorage.setItem(getLocalStorageKey(alertKey), "true");
    }
    setShow(false);
  };

  if (show) {
    return (
      <BootstrapAlert onClose={close} {...rest}>
        {children}
      </BootstrapAlert>
    );
  } else {
    return null;
  }
};

export default Alert;
