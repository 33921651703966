import React from "react";
import Form from "react-bootstrap/Form";

import FinalFormWrapper, { FinalFormProps } from "./final-form-wrapper";
import GenericFieldWithFeedback, {
  GenericProps,
} from "./generic-field-with-feedback";

interface Props extends GenericProps {
  type?: string;
}

export const InputFieldWithFeedback: React.FC<Props & FinalFormProps> = (
  props
) => {
  return (
    <GenericFieldWithFeedback
      {...props}
      renderInput={({ input, label, placeholder }) => (
        <Form.Control
          {...input}
          placeholder={placeholder !== undefined ? placeholder : label}
          type={props.type}
        ></Form.Control>
      )}
    />
  );
};

export default FinalFormWrapper(InputFieldWithFeedback) as React.FC<Props>;
