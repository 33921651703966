import { faUsersCrown as falUsersCrown } from "@fortawesome/pro-light-svg-icons";
import { faPiggyBank as falPiggyBank } from "@fortawesome/pro-light-svg-icons";
import { faSearchDollar as falSearchDollar } from "@fortawesome/pro-light-svg-icons";
import { faSearchDollar as fasSearchDollar } from "@fortawesome/pro-solid-svg-icons";
import { faBookmark as falBookmark } from "@fortawesome/pro-light-svg-icons";
import { faBookmark as fasBookmark } from "@fortawesome/pro-solid-svg-icons";
import { faUserCircle as falUserCircle } from "@fortawesome/pro-light-svg-icons";
import { faUsersCrown as fasUsersCrown } from "@fortawesome/pro-solid-svg-icons";
import { faUserCircle as fasUserCircle } from "@fortawesome/pro-solid-svg-icons";
import { faPiggyBank as fasPiggyBank } from "@fortawesome/pro-solid-svg-icons";
import { faDice as fasDice } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Nav from "react-bootstrap/Nav";
import BootstrapNavbar from "react-bootstrap/Navbar";
import { LinkContainer } from "react-router-bootstrap";

export interface Props {
  user?: JumboUser;
  location?: any;
}

/**
 * If the current path matches any of these paths we will not render
 * the navbar (this allows the user to focus on the current
 * page).
 */
const HIDE_LOGGED_OUT_NAVBAR_PATHS: RegExp[] = [
  /\/play/,
  /\/login/,
  /\/create-prop/,
  /\/thankyou/,
  /\/create-game/,
  /\/edit/,
  /\/grade/,
  /\//,
];

const Navbar: React.FC<Props> = ({ user, location }) => {
  for (const path of HIDE_LOGGED_OUT_NAVBAR_PATHS) {
    if (location && location.pathname && location.pathname.match(path)) {
      return null;
    }
  }
  return (
    <BootstrapNavbar className="fixed-bottom nav-jumbo border-light border-top">
      <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
      <BootstrapNavbar.Collapse id="basic-navbar-nav">
        <Nav className="mx-auto text-center">
          {user ? (
            <>
              <LinkContainer to="/" className="nav-jumbo-link">
                <Nav.Link>
                  {location?.pathname === "/" ? (
                    <div className="text-center">
                      <FontAwesomeIcon
                        className="h4 wager2"
                        icon={fasSearchDollar}
                      />
                      <br />
                      <div className="navtext wager2" style={{ paddingTop: 5 }}>
                        Explore
                      </div>
                    </div>
                  ) : (
                    <div className="text-center nav-color">
                      <FontAwesomeIcon
                        className="h4 nav-color"
                        icon={falSearchDollar}
                      />
                      <br />
                      <div className="navtext" style={{ paddingTop: 5 }}>
                        Explore
                      </div>
                    </div>
                  )}
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/leaderboard" className="nav-jumbo-link">
                <Nav.Link>
                  {location?.pathname === "/leaderboard" ? (
                    <div className="text-center">
                      <FontAwesomeIcon
                        className="h4 wager2"
                        icon={fasUsersCrown}
                      />
                      <br />
                      <div className="navtext wager2" style={{ paddingTop: 5 }}>
                        Standings
                      </div>
                    </div>
                  ) : (
                    <div className="text-center">
                      <div className="nav-color">
                        <FontAwesomeIcon
                          className="h4 nav-color"
                          icon={falUsersCrown}
                        />
                        <br />
                        <div
                          className="navtext nav-color"
                          style={{ paddingTop: 5 }}
                        >
                          Standings
                        </div>
                      </div>
                    </div>
                  )}
                </Nav.Link>
              </LinkContainer>

              <LinkContainer to="/throne-room" className="nav-jumbo-link">
                <Nav.Link>
                  {location?.pathname === "/throne-room" ? (
                    <div className="text-center">
                      <FontAwesomeIcon
                        className="h4 wager2"
                        icon={fasPiggyBank}
                      />
                      <br />
                      <div className="navtext wager2" style={{ paddingTop: 5 }}>
                        Earnings
                      </div>
                    </div>
                  ) : (
                    <div className="text-center">
                      <div className="nav-color">
                        <FontAwesomeIcon
                          className="h4 nav-color"
                          icon={falPiggyBank}
                        />
                        <br />
                        <div
                          className="navtext nav-color"
                          style={{ paddingTop: 5 }}
                        >
                          Earnings
                        </div>
                      </div>
                    </div>
                  )}
                </Nav.Link>
              </LinkContainer>
            </>
          ) : (
            <div className="text-left">
              {" "}
              <h3
                className="binary text-left"
                style={{ paddingBottom: 5, paddingTop: 5, paddingLeft: 10 }}
              >
                Make your picks, win cash. Always free.
              </h3>
              <div
                className="even-duller small-details-text text-left"
                style={{ paddingBottom: 20, paddingLeft: 10 }}
              >
                Wager Town is a free betting app for sports fans&nbsp;&nbsp;
              </div>
              <LinkContainer
                className="nav-jumbo-link33"
                style={{ paddingBottom: 20 }}
                to={`/login?redirect=${window.location.pathname}`}
              >
                <ButtonGroup
                  className="w-100 text-center"
                  style={{
                    paddingBottom: 25,
                    paddingTop: 25,
                  }}
                >
                  <Button className="logout-button text-center jeopardy">
                    <span className="even-duller">
                      <b>log in</b>
                    </span>
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button className="register-button2 text-center jeopardy">
                    &nbsp;&nbsp;&nbsp;sign up free&nbsp;&nbsp;&nbsp;
                  </Button>
                </ButtonGroup>
              </LinkContainer>
            </div>
          )}
        </Nav>
      </BootstrapNavbar.Collapse>
    </BootstrapNavbar>
  );
};

export default Navbar;
