import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";

import GameForm from "jumbo/components/forms/game-form";
import { loadGameOrAlias } from "jumbo/lib/routing";

import connectRoute, { ConnectedRouteProps } from "./connect-route";

export const EditGameRoute: React.FC<ConnectedRouteProps> = ({
  actions,
  history,
  store,
}) => {
  const game: Game = store.games.item;
  const user: JumboUser | null = store.users.currentUser;
  return (
    <Container>
      <Link to={`/game/${store.games.item.id}`}>
        <h4 className="text-right quiet-link special-link">
          <FontAwesomeIcon size="lg" className="special-link" icon={faTimes} />
        </h4>
      </Link>
      <br />

      <h2 className="binary text-left">Edit Game</h2>
      <span className="nav-color">{game.name}</span>
      <br />
      <br />
      <div className="text-left">
        <GameForm
          game={game}
          onSubmit={async (data) => {
            await actions.updateGame({ id: game.id, ...data });
            history.push(`/game/${game.id}`);
          }}
          allowCode={user ? user.profile.is_subscriber : false}
          allowPrize={user ? user.profile.is_subscriber : false}
        />
      </div>
      <br />
      <br />
    </Container>
  );
};

export default connectRoute(EditGameRoute, "Edit Game", [loadGameOrAlias]);
