import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FORM_ERROR } from "final-form";
import React from "react";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";

import Alert from "jumbo/components/alert";
import GradeGameForm from "jumbo/components/forms/grade-game-form";
import { loadGameOrAlias } from "jumbo/lib/routing";
import { GameTimeDisplay } from "../components/shared/game-time-display";

import connectRoute, { ConnectedRouteProps } from "./connect-route";

export const GradeGameRoute: React.FC<ConnectedRouteProps> = ({
  actions,
  history,
  store,
}) => {
  const game: Game = store.games.item;
  return (
    <>
      <Container>
        <Link to={`/game/${store.games.item.id}`} className="quiet-link">
          <h4 className="text-right spinner-pink">
            <FontAwesomeIcon size="lg" icon={faTimes} />
          </h4>
        </Link>

        <h2 className="binary text-left">Grade Game</h2>
        <span className="even-duller">{game.name}</span>
        <h6>
          <div className="even-duller text-left small">
            Ended <GameTimeDisplay endTime={game.end_time} />
          </div>
        </h6>

        <Alert
          alertKey="grading"
          className="alert-custom"
          style={{ marginTop: 10 }}
        >
          <span className="small-details-text nav-love">
            • Grade your game soon after the event is over.
            <br />
            • Be accurate! Your players trust you.
            <br />
            • Player balances will update shortly after.
            <br />
          </span>
        </Alert>
        <br />
        <div className="text-left">
          <GradeGameForm
            game={game}
            onSubmit={async (propData) => {
              for (const id of propData.keys()) {
                try {
                  await actions.updateProp({ id, result: propData.get(id) });
                } catch {
                  return Promise.reject({
                    [`prop-${id}`]: "Failed to update prop.",
                  });
                }
              }
              // Now try to make a score request.
              try {
                await actions.scoreGame(game);
              } catch {
                // TODO: Kind of annoying you have to format this for react-final form...
                return Promise.reject({
                  [FORM_ERROR]:
                    "Your grades are saved, but the queue is busy. Try again in a few seconds!",
                });
              }
              history.push(`/game/${game.id}`);
              return Promise.resolve();
            }}
          />
          <br />
        </div>
      </Container>
    </>
  );
};

export default connectRoute(GradeGameRoute, "Grade Game", [loadGameOrAlias]);
