import React from "react";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

interface PlacesWrapperProps {
  onPlaceChange: (data: { lat: number; lng: number }) => void;
}
export class PlacesWrapper extends React.Component<
  PlacesWrapperProps,
  {
    value: string;
  }
> {
  constructor(props: PlacesWrapperProps) {
    super(props);
    this.state = { value: "" };
  }
  public render() {
    return (
      <PlacesAutocomplete
        value={this.state.value}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <Form.Control
              name="places"
              {...(getInputProps({
                placeholder: "Search Places ...",
              }) as any)}
            />
            <ListGroup>
              {suggestions.map((suggestion, i) => (
                <ListGroupItem
                  {...getSuggestionItemProps(suggestion)}
                  active={suggestion.active}
                >
                  {suggestion.description}
                </ListGroupItem>
              ))}
            </ListGroup>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }

  private handleChange = async (value: string) => {
    this.setState({ value });
  };

  private handleSelect = async (value: string) => {
    this.handleChange(value);
    // Determine the lat/lng and then call our callbacks.
    const gecodeResult = await geocodeByAddress(value);
    const latLng = await getLatLng(gecodeResult[0]);
    this.props.onPlaceChange(latLng);
  };
}

export default PlacesWrapper;
