import {
  faDiscord,
  faFacebook,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faCloudDownloadAlt } from "@fortawesome/pro-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { faCalendarWeek } from "@fortawesome/pro-solid-svg-icons";
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment, { Moment } from "moment";
import React from "react";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import CardDeck from "react-bootstrap/CardDeck";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { LinkContainer } from "react-router-bootstrap";
import { Link, Redirect } from "react-router-dom";

import GameList from "jumbo/components/game-list";
import { GameTimeDisplay } from "jumbo/components/shared/game-time-display";

import connectRoute, { ConnectedRouteProps } from "./connect-route";

export const groupGames = (currentTime: Moment, games: Game[]) =>
  games.reduce(
    (acc: { activeGames: Game[]; completedGames: Game[] }, game) => {
      moment(game.end_time).diff(currentTime) > 0
        ? acc.activeGames.push(game)
        : acc.completedGames.push(game);
      return acc;
    },
    { activeGames: [], completedGames: [] }
  );

export const InboxRoute: React.FC<ConnectedRouteProps> = ({ store }) => {
  const games: Game[] = store.games.items;
  const user: JumboUser = store.users.currentUser;
  const { activeGames, completedGames } = groupGames(moment(), games);

  return (
    <>
      <div>
        <Container>
          <h4 className="text-left spinner-pink play-header">
            <Link to={`/throne-room`} className="quiet-link">
              <FontAwesomeIcon icon={faChevronLeft} className="spinner-pink" />
            </Link>
            <h4 className="spinner-pink">&nbsp;&nbsp;&nbsp;</h4>
          </h4>
          <div style={{ paddingBottom: 10 }}>
            <br />
            <h2 className="text-center" style={{ paddingTop: 0 }}>
              <b>Your Results</b>
            </h2>
            <div
              className="small-details-text even-duller text-center"
              style={{ paddingTop: 5 }}
            >
              {moment()
                .tz("America/New_York")
                .startOf("isoWeek")
                .format("ddd, MMM Do")}
              &nbsp;—&nbsp;
              {moment()
                .tz("America/New_York")
                .endOf("isoWeek")
                .format("ddd, MMM Do")}
            </div>
          </div>
          <br />
          <Row>
            <GameList user={user} games={completedGames} location={undefined} />
          </Row>
          <br />
          <div className="text-center">
            <Link to={"/?"} className="nav-color-dull ">
              <Button
                className="logout-button0 w-50 jeopardy"
                style={{ padding: 10 }}
              >
                &nbsp;&nbsp;&nbsp;explore today&nbsp;&nbsp;&nbsp;
              </Button>
            </Link>
          </div>
          <br />
          <br />

          <br />

          <hr />
          <Row>
            <Col>
              {" "}
              <div className="text-center nav-footer">
                <a href="http://www.facebook.com/wagertownusa">
                  <FontAwesomeIcon
                    icon={faFacebook}
                    size="lg"
                    className="nav-color"
                  />
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a href="http://twitter.com/wagertownusa/">
                  <FontAwesomeIcon
                    icon={faTwitter}
                    size="lg"
                    className="nav-color"
                  />
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a href="https://discord.gg/VC7bzvdYGD">
                  <FontAwesomeIcon
                    className="nav-color"
                    size="lg"
                    icon={faDiscord}
                  />
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a href="mailto:support@wager.town">
                  <FontAwesomeIcon
                    className="nav-color"
                    size="lg"
                    icon={faEnvelope}
                  />
                </a>
              </div>
            </Col>
          </Row>
          <hr />
          <br />
          <div className="text-center">
            <span className="nav-color-dull small">
              “Good is not good when better is expected.”
              <br />– Vin Scully
            </span>
          </div>
          <br />
        </Container>
      </div>
    </>
  );
};

export default connectRoute(InboxRoute, "Your Games", [
  (actions) =>
    actions.fetchGames(
      {},
      {
        query: {
          end_time__gt: moment()
            .tz("America/New_York")
            .startOf("isoWeek")
            .toISOString(),
          my_games: 1,
        },
      }
    ),
]);
