/**
 * A utility function for connected routes. Loads a game by either
 * the raw id or by an alias.
 */
export const loadGameOrAlias = (actions: any, match: any) => {
  if (/^\d+$/.test(match.params.id)) {
    return actions.getGame(match.params.id);
  } else {
    return actions.getAliasGame({}, { query: { alias: match.params.id } });
  }
};
