import { createResource } from "redux-rest-resource";

import { API_ROOT } from "jumbo/lib/constants";

export const { types, actions, rootReducer } = createResource({
  actions: {
    evaluate: { method: "POST", url: "./evaluate" },
    getMaxWagerFor: { method: "GET", url: "./max_wager" },
  },
  name: "prop",
  url: `${API_ROOT}/props/:id`,
});
