import {
  faDiscord,
  faFacebook,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { faSackDollar } from "@fortawesome/pro-solid-svg-icons";
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons";
import { faUsersCrown } from "@fortawesome/pro-solid-svg-icons";
import { faCalendarPlus } from "@fortawesome/pro-solid-svg-icons";
import { faCommentPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";

import connectRoute from "./connect-route";

// const LOGO = "/static/images/modal.png";

const RulesRoute: React.FC = () => {
  return (
    <>
      <div>
        <Container className="jumbo-info-pages rules-page">
          <h5 className="play-header">
            <Link to={`/`} className="nav-color">
              <FontAwesomeIcon icon={faChevronLeft} className="nav-color" />{" "}
            </Link>
          </h5>
          <div className="lobbytext">
            <span>
              <h2
                className="text-left binary jeopardy"
                style={{ paddingTop: 15 }}
              >
                rules
              </h2>
              <div className="text-left nav-color jeopardy big-wager-text">
                how wager.town works
              </div>
              <br />
              <br />
              <br />
              <h2
                className="text-center binary jeopardy"
                style={{ paddingBottom: 30 }}
              >
                👋&nbsp;&nbsp;hello!
              </h2>
              <b className="wager">
                Wager Town is a free-to-enter sports betting competition with
                cash prizes.
              </b>{" "}
              Every week, fans make free picks on games in the NFL, NBA, and
              more games for a shot at winning cash.
              <br />
              <br />
              You heard us right — Wager Town is 100% free and legal to play. No
              expensive credit card deposits, confusing Bitcoin transfers, or
              sketchy terms and services. We want you just to enjoy the game.
              <br />
              <br />
              It's like Jeopardy, except the clues are all about today's games —
              and written by die-hard sports fans like you!
              <br />
              <br />
              <br />
              <br />
              <h2
                className="text-center binary jeopardy"
                style={{ paddingBottom: 30 }}
              >
                🔮&nbsp;&nbsp;the basics
              </h2>
              <b className="wager">Users get $1 free to bet, per game</b>. How
              you wager it is entirely up to you. Correct picks add to your{" "}
              <b>Earnings</b>
              , and wrong picks deduct.
              <br />
              <br />
              End the week above the Weekly Cashout Minimum (usually $25),
              &nbsp;take home your balance in USD. Cashout are handled every
              Tuesday night, when we send PayPals to all eligible winners (You
              can always change your preferred cashout email in{" "}
              <b>Your Settings</b>).
              <br />
              <br />
              Don't hit the <b>Weekly Cashout Minimum</b>, or end up in the
              negative? No sweat — all player balances reset to $0 every Monday
              morning, giving you weekly chances to win.
              <br />
              <br />
              <br />
              <br />
              <h2
                className="text-center binary jeopardy"
                style={{ paddingBottom: 30 }}
              >
                🤾‍♀️&nbsp;&nbsp;the cool stuff
              </h2>
              <b className="wager">On Wager Town, fans write the props.</b> With
              a few taps, anyone can host an upcoming game for other users to
              bet on, across any league, continent, or timezone.
              <br />
              <br />
              Watching some darts on ESPN The Ocho later? Host it! Big on KBO?
              You'll find those matches on Wager Town too. Our users write all
              the questions — we like to think it's what makes Wager Town
              special.
              <br />
              <br />
              <br />
              <br />
              <h2
                className="text-center binary jeopardy"
                style={{ paddingBottom: 30 }}
              >
                🙋‍♀️&nbsp;&nbsp;faqs
              </h2>
              <br />
              <b className="td-promo">WHEN DO I GET PAID?</b>
              <br />
              <br />
              48 hours after the week ends (so, Tuesday by midnight EST). You
              can always check{" "}
              <Link className="spinner-pink" to={"/leaderboard-last-week"}>
                Last Week's Standings
              </Link>{" "}
              for up-to-date information on cashout schedule.
              <br />
              <br />
              <br />
              <b className="td-promo">DO YOU HAVE AN APP?</b>
              <br />
              <br />
              That we do! For instructions on how to install, click{" "}
              <Link to={"/how-to-install"} className="spinner-pink">
                here.
              </Link>{" "}
              <br />
              <br />
              <br />
              <b className="td-promo">HOW DO Y'ALL MAKE MONEY?</b>
              <br />
              <br />
              Didn't your mother tell you about manners? (serious answer: we're
              still experimenting!)
              <br />
              <br />
              <br />
              <b className="td-promo">I FORGOT MY PASSWORD. HELP?</b>
              <br />
              <br />
              Email us at support@wager.town to change — please include your
              username and desired (new) password.
              <br />
              <br />
              <br />
              <b className="td-promo">I LOVE WAGER TOWN! HOW CAN I HELP?</b>
              <br />
              <br />
              Thanks -- we love you too! You can help by playing, hosting, and
              sharing games with your friends and family. The more people that
              play, the more valuable the games!
              <br />
              <br />
              Any questions, issues, feedback? Shoot us a message at
              support@wager.town and we'd be happy to help.
            </span>
          </div>
          <br />
          <br />
          <div style={{ padding: 10 }} className="text-center">
            <Badge className="badge-tertiary">
              <Link to={"/"} className="wager jeopardy">
                &nbsp;&nbsp;&nbsp;&nbsp;back to home&nbsp;&nbsp;&nbsp;&nbsp;
              </Link>
            </Badge>
          </div>
          <br />

          <hr />

          <Row>
            <Col>
              {" "}
              <div className="text-center nav-footer">
                <a href="http://www.facebook.com/wagertownusa">
                  <FontAwesomeIcon
                    icon={faFacebook}
                    size="lg"
                    className="nav-color"
                  />
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a href="http://twitter.com/wagertownusa/">
                  <FontAwesomeIcon
                    icon={faTwitter}
                    size="lg"
                    className="nav-color"
                  />
                </a>
              </div>
            </Col>
          </Row>
          <hr />
          <br />
          <br />

          <p className="nav-color-dull small text-center">
            Made with love by <b>Super Jumbo</b>, <br />a collection of friends
            <br /> residing in New York, California, and Texas.
            <br />
            <br />
            <br />
            Super Jumbo, Inc © 2020
            <br />
            244 Fifth Avenue, Suite 1279
            <br />
            New York, N.Y. 10001
          </p>

          <br />
        </Container>
      </div>
    </>
  );
};

export default connectRoute(RulesRoute, "Rules");
