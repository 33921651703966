import { API_ROOT } from "./constants";
import { postAsPromise } from "./network";

interface Stat extends StatDefinition {
  value: number;
}

/** Extracts stats and converts them into a Map<name, value> */
export const extractStats = (allStats: any, findStats: any) => {
  const foundStats = allStats.filter((x: any) => {
    return findStats.some(
      (y: any) =>
        x.name === y.name &&
        x.group === y.group &&
        x.game === y.game &&
        x.user === y.user
    );
  });
  const map = new Map<string, number>();
  for (const { name, value } of foundStats) {
    map.set(name, value);
  }
  return map;
};

export const getSingleStat = (allStats: any, singleStat: any) => {
  const stat = allStats.find(
    (x: any) =>
      singleStat.name === x.name &&
      singleStat.user === x.user &&
      singleStat.group === x.group &&
      singleStat.game === x.game &&
      singleStat.prop === x.prop
  );
  return stat !== undefined ? stat.value : undefined;
};

export const getStat = async (stat: StatDefinition): Promise<Stat> => {
  // TODO: If we have a lot of stat fragments this will be a lot of API calls that
  // we could probably batch.
  const data = await postAsPromise(`${API_ROOT}/stats`, { stats: [stat] });
  return data.stats[0];
};

export const getStatsBatch = async (
  stats: StatDefinition[]
): Promise<Stat[]> => {
  const data = await postAsPromise(`${API_ROOT}/stats`, { stats });
  return data.stats;
};
