import React from "react";
import Container from "react-bootstrap/Container";

/** A simple static router that is rendered when jumbo is not available. */
const JumboUnavailableRoute: React.FC = () => {
  return (
    <div>
      {/* <Navbar /> */}
      <Container>
        <span className="text-danger">
          Sorry! Wager Town is not available right now.
        </span>
      </Container>
    </div>
  );
};

export default JumboUnavailableRoute;
