import {
  faDiscord,
  faFacebook,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faSackDollar } from "@fortawesome/pro-light-svg-icons";
import { faUserCrown } from "@fortawesome/pro-solid-svg-icons";
import { faWhistle } from "@fortawesome/pro-solid-svg-icons";
import { faHistory } from "@fortawesome/pro-solid-svg-icons";
import { faTrophy } from "@fortawesome/pro-solid-svg-icons";
import { faMobileAlt } from "@fortawesome/pro-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { faChevronDoubleUp } from "@fortawesome/pro-solid-svg-icons";
import { faChevronDoubleDown } from "@fortawesome/pro-solid-svg-icons";
import { faBullseyeArrow } from "@fortawesome/pro-solid-svg-icons";
import { faUserSecret } from "@fortawesome/pro-solid-svg-icons";
import { faUserAstronaut } from "@fortawesome/pro-solid-svg-icons";
import { faCalendarWeek } from "@fortawesome/pro-solid-svg-icons";
import { faUserCowboy } from "@fortawesome/pro-solid-svg-icons";
import { faChartLine } from "@fortawesome/pro-solid-svg-icons";
import { faSwords } from "@fortawesome/pro-solid-svg-icons";
import { faPiggyBank } from "@fortawesome/pro-light-svg-icons";
import { faStopwatch } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import React from "react";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { LinkContainer } from "react-router-bootstrap";
import { Link, Redirect } from "react-router-dom";

import Alert from "jumbo/components/alert";
import Leaderboard from "jumbo/components/leaderboard";
import { GameTimeDisplayAlt } from "jumbo/components/shared/game-time-display-alt";
import StatsFragment from "jumbo/components/stats-fragment";
import UpsellModal2 from "jumbo/components/upsell-modal2";
import { startCheckoutFlow } from "jumbo/lib/payments";

import connectRoute, { ConnectedRouteProps } from "./connect-route";

export const LeaderboardRoute: React.FC<ConnectedRouteProps> = ({ store }) => {
  const isWeekAlmostOver =
    moment().tz("America/New_York").endOf("isoWeek").toISOString() &&
    moment().toISOString() >
      moment()
        .tz("America/New_York")
        .endOf("isoWeek")
        .subtract(24, "hours")
        .toISOString();
  return (
    <div>
      {store.users.currentUser ? (
        <Link to={`/throne-room`}>
          <Alert alertKey="weekly-timer" className="alert-custom4c">
            <div
              style={{ paddingBottom: 5, paddingTop: 5 }}
              className="user-header-home"
            >
              <div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="jeopardy even-duller text-center  small-host-text2">
                  next cashout in
                </div>
                <div
                  className="text-center"
                  style={{ paddingBottom: 15, paddingTop: 3 }}
                >
                  {isWeekAlmostOver ? (
                    <>
                      <h2 className="text-warning">
                        <GameTimeDisplayAlt
                          endTime={moment()
                            .tz("America/New_York")
                            .endOf("isoWeek")
                            .toISOString()}
                        />
                      </h2>
                    </>
                  ) : (
                    <h2 className="wager">
                      <GameTimeDisplayAlt
                        endTime={moment()
                          .tz("America/New_York")
                          .endOf("isoWeek")
                          .toISOString()}
                      />
                    </h2>
                  )}

                  <div
                    className="text-center small-host-text2"
                    style={{ paddingTop: 15 }}
                  >
                    <span className="even-duller jeopardy">
                      minimum:&nbsp;
                      <small>
                        <b>$25</b>
                      </small>
                      &nbsp;&nbsp;
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Alert>
        </Link>
      ) : (
        <Link to={`/rules`}>
          <Alert alertKey="weekly-timer" className="alert-custom4c">
            <div
              style={{ paddingBottom: 5, paddingTop: 5 }}
              className="user-header-home"
            >
              <div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="jeopardy even-duller text-center  small-host-text2">
                  next cashout in
                </div>
                <div
                  className="text-center"
                  style={{ paddingBottom: 15, paddingTop: 3 }}
                >
                  {isWeekAlmostOver ? (
                    <>
                      <h2 className="text-warning">
                        <GameTimeDisplayAlt
                          endTime={moment()
                            .tz("America/New_York")
                            .endOf("isoWeek")
                            .toISOString()}
                        />
                      </h2>
                    </>
                  ) : (
                    <h2 className="wager">
                      <GameTimeDisplayAlt
                        endTime={moment()
                          .tz("America/New_York")
                          .endOf("isoWeek")
                          .toISOString()}
                      />
                    </h2>
                  )}

                  <div
                    className="text-center small-host-text2"
                    style={{ paddingTop: 15 }}
                  >
                    <span className="even-duller jeopardy">
                      minimum:&nbsp;
                      <small>
                        <b>$25</b>
                      </small>
                      &nbsp;&nbsp;
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Alert>
        </Link>
      )}
      <div className="play-header">
        {store.users.currentUser ? (
          <div>
            {" "}
            <div className="text-center">&nbsp;&nbsp;&nbsp;</div>
          </div>
        ) : (
          <Link to={`/?`}>
            <h4 className="spinner-pink">
              <FontAwesomeIcon icon={faChevronLeft} />
            </h4>
          </Link>
        )}
      </div>
      <Container>
        <div style={{ paddingBottom: 10, paddingTop: 10 }}>
          <div className="text-left">
            <div className="vertical-center text-center details-text">
              <Link to={`/leaderboard-last-week`}>
                <div style={{ padding: 4, paddingTop: 2 }}>
                  <span className="even-duller jeopardy">
                    <FontAwesomeIcon icon={faHistory} className="even-duller" />
                    &nbsp;&nbsp;see last week's winners
                  </span>
                </div>
              </Link>
            </div>
          </div>
          <div
            className="binary text-left h1-companion"
            style={{ paddingTop: 0, paddingBottom: 0 }}
          >
            <b>Standings This Week</b>
          </div>
        </div>

        <div>
          <Leaderboard
            userName={store.users.currentUser.username}
            leaderboard={store.gameScore.leaderboard}
          />
        </div>

        <br />

        <div className="text-left">
          <h6 className="even-duller" style={{ marginTop: 25 }}>
            Weekly Cashout Minimum - Rules
          </h6>
          <p className="small even-duller text-left" style={{ marginTop: 7 }}>
            All or nothing. You will receive your balance on{" "}
            {moment()
              .tz("America/New_York")
              .endOf("isoWeek")
              .add(2, "days")
              .format("dddd, MMM Do")}
            &nbsp; if you are at or over the <b>Cashout Minimum</b> ($25) when
            all games ending prior to{" "}
            {moment().endOf("isoWeek").format("dddd, MMM Do hh:mm A")} are
            graded.&nbsp;
            <a href="/rules" className="even-duller">
              Read <u>The Rules</u>
            </a>
            &nbsp;for more info. &nbsp;
            <br />
            <br />
            You can add or change your preferred cashout method in&nbsp;
            <a href="/settings" className="even-duller">
              <u>Your Settings</u>,
            </a>
            &nbsp;otherwise it will be sent as a PayPal to the email you
            registered with.
          </p>
        </div>
        <br />
        <h6 className="nav-color-dull" style={{ marginBottom: 5 }}>
          Legend
        </h6>
        <ul className="text-left">
          <li className="text-left small nav-color-dull">
            <FontAwesomeIcon icon={faWhistle} className="gold-color" />
            &nbsp;&nbsp;—&nbsp;&nbsp;Mod
          </li>
          <li className="text-left small nav-color-dull">
            GP&nbsp;&nbsp;—&nbsp;&nbsp;Games played (this week)
          </li>

          <li className="text-left small nav-color-dull">
            <FontAwesomeIcon icon={faPiggyBank} />
            &nbsp;&nbsp;—&nbsp;&nbsp;Player's balance for the week.
          </li>
          <li className="text-left small nav-color-dull">
            EPG &nbsp;&nbsp;—&nbsp;&nbsp;Earnings Per Game
          </li>
        </ul>
        <hr />
        <Row>
          <Col>
            {" "}
            <div className="text-center nav-footer">
              <a href="http://www.facebook.com/wagertownusa">
                <FontAwesomeIcon
                  icon={faFacebook}
                  size="lg"
                  className="nav-color"
                />
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <a href="http://twitter.com/wagertownusa/">
                <FontAwesomeIcon
                  icon={faTwitter}
                  size="lg"
                  className="nav-color"
                />
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <a href="mailto:support@wager.town">
                <FontAwesomeIcon
                  className="nav-color"
                  size="lg"
                  icon={faEnvelope}
                />
              </a>
            </div>
          </Col>
        </Row>
        <hr />
        <br />
        <div className="text-center">
          <span className="even-duller small">
            “Always make a total effort, <br />
            even when the odds are against you.” <br />– Arnold Palmer
          </span>
        </div>
        <br />
      </Container>
    </div>
  );
};

export default connectRoute(LeaderboardRoute, "Week", [
  (actions) =>
    actions.getLeaderboardGameScore(
      {},
      {
        query: {
          start_date: moment()
            .tz("America/New_York")
            .startOf("isoWeek")
            .toISOString(),
        },
      }
    ),
]);
