import React from "react";
import Form from "react-bootstrap/Form";

import FinalFormWrapper, { FinalFormProps } from "./final-form-wrapper";
import GenericFieldWithFeedback, {
  GenericProps,
} from "./generic-field-with-feedback";

type Props = GenericProps;

export const CheckFieldWithFeedback: React.FC<Props & FinalFormProps> = (
  props
) => {
  return (
    <GenericFieldWithFeedback
      {...props}
      hideLabel={true}
      renderInput={({ input, label }) => (
        <Form.Check inline label={label} {...input} />
      )}
    />
  );
};

export default FinalFormWrapper(CheckFieldWithFeedback, "checkbox") as React.FC<
  Props
>;
