import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Container from "react-bootstrap/Container";
import { Link, Redirect } from "react-router-dom";

import Alert from "jumbo/components/alert";
import HardcodedPropForm from "jumbo/components/forms/hardcoded-prop-form";
import { loadGameOrAlias } from "jumbo/lib/routing";

import connectRoute, { ConnectedRouteProps } from "./connect-route";

export const CreatePropRoute: React.FC<ConnectedRouteProps> = ({
  actions,
  store,
  history,
}) => {
  if (!store.users.currentUser) {
    return <Redirect to={`/login?redirect=${window.location.pathname}`} />;
  }
  const game: Game = store.games.item;
  const createProp = async (data: any) => {
    await actions.createProp({
      ...data,
      game: store.games.item.id,
    });
    history.push(`/game/${store.games.item.id}`);
  };
  return (
    <>
      <Container>
        <Link to={`/game/${store.games.item.id}/play`} className="quiet-link">
          <h4 className="text-right text-muted">
            <FontAwesomeIcon className="nav-love" icon={faTimes} />
          </h4>
        </Link>
        <h3 className="text-left binary" style={{ marginTop: 10 }}>
          Add Question
        </h3>
        <span className="even-duller lobbytext">Adding to {game.name}</span>
        <Alert
          alertKey="propt2"
          className="alert-custom"
          style={{ marginTop: 20 }}
        >
          <div className="nav-love" style={{ marginTop: 5, marginBottom: 5 }}>
            Tips for writing popular questions
          </div>

          <span className="small-host-text even-duller">
            •&nbsp;&nbsp;Only <b>Yes/No</b> questions please!
            <br />
            •&nbsp;&nbsp;Questions that split the crowd earn more
            <br />
            •&nbsp;&nbsp;Be creative and think outside the box
          </span>
        </Alert>
        <br />

        <div className="text-left">
          <span className="pulse input-lg">
            <HardcodedPropForm onSubmit={createProp} />
          </span>
          <div className="text-center">
            <span className="even-duller text-center small-host-text">
              <small>
                By clicking the "Add Question" button below, <br />
                you agree to Wager Town's&nbsp;
                <Link className="even-duller" to="/writer-terms">
                  Writer Terms.
                </Link>{" "}
                <br />
                <br />
              </small>
            </span>
          </div>
        </div>
        <br />
        <hr />
        <div
          className="even-duller small-host-text text-center small"
          style={{ marginTop: 20 }}
        >
          <b>Examples of good questions</b>
        </div>

        <div
          className="small-host-text even-duller text-center small"
          style={{ marginTop: 10 }}
        >
          •&nbsp;&nbsp;Will the Yankees beat the Cardinals?
          <br />
          •&nbsp;&nbsp;Will any Mavericks player foul out?
          <br />
          •&nbsp;&nbsp;Will Tua get benched before halftime?
        </div>
      </Container>
      <br />
    </>
  );
};

export default connectRoute(CreatePropRoute, "Add Question", [
  loadGameOrAlias,
  (actions) => actions.getFormOptions(),
]);
