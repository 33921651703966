import { createResource } from "redux-rest-resource";

import { API_ROOT } from "jumbo/lib/constants";

export const { types, actions, rootReducer } = createResource({
  actions: {
    fetch: { url: `${API_ROOT}/games_short/:id` },
    generateProp: { method: "POST", url: "./generate_prop" },
    getAlias: {
      method: "GET",
      reduce: (state: any, action: any) => {
        if (action.status !== "resolved") {
          return state;
        }
        return {
          ...state,
          item: action.body[0] || undefined,
        };
      },
      transformResponse: (res: any) => {
        if (res.code === 200 && res.body.length === 0) {
          throw new Error("Could not find any games with alias");
        }
        return res;
      },
    },
    score: { method: "POST", url: "./score" },
  },
  name: "game",
  url: `${API_ROOT}/games/:id`,
});
