import React from "react";
import Card from "react-bootstrap/Card";
import CardDeck from "react-bootstrap/CardDeck";
import { Link } from "react-router-dom";

import { getThumbnailOrDefault } from "jumbo/lib/image";

export interface Props {
  groups: JumboGroup[];
  currentGroup?: JumboGroup;
}

export const GroupList: React.FC<Props> = ({ groups, currentGroup }) => {
  // TODO: A lot of repeated code between this and the GameList. Something to think about.
  return (
    <CardDeck className="jumbo-card-deck">
      {groups.map((group, i) => (
        <Card
          className="jumbo-card"
          key={i}
          bg={
            currentGroup && group.id === currentGroup.id ? "primary" : undefined
          }
        >
          <Card.Img
            variant="top"
            src={getThumbnailOrDefault(group.thumbnail)}
            width="300"
            height="175"
          />
          <Card.Body>
            <Card.Title>
              <Link
                to={`/team/${group.id}`}
                className="stretched-link quiet-link"
              >
                {group.name}
              </Link>
            </Card.Title>
          </Card.Body>
          <Card.Footer>
            <small className="text-muted">
              {group.users.length} member
              {group.users.length !== 1 ? "s" : null}
            </small>
          </Card.Footer>
        </Card>
      ))}
    </CardDeck>
  );
};

export default GroupList;
