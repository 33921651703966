import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { faClipboardCheck } from "@fortawesome/pro-solid-svg-icons";
import { faHandshakeAlt } from "@fortawesome/pro-solid-svg-icons";
import { faSackDollar } from "@fortawesome/pro-solid-svg-icons";
import { faCommentDollar } from "@fortawesome/pro-solid-svg-icons";
import { faHandHoldingUsd } from "@fortawesome/pro-solid-svg-icons";
import { faPiggyBank } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import { Link, Redirect } from "react-router-dom";

import Alert from "jumbo/components/alert";
import { loadGameOrAlias } from "jumbo/lib/routing";
import { toWagersByProps } from "jumbo/routes/game";

import connectRoute, { ConnectedRouteProps } from "./connect-route";

export const GroupGameDetailsRoute: React.FC<ConnectedRouteProps> = ({
  store,
}) => {
  const gameScore: any = store.gameScore.item;
  const showSingleUser =
    gameScore.user !== undefined && gameScore.user !== null;
  const game: Game = store.games.item;

  if (
    game.requires_code &&
    store.gameCodes.authorizedGames.indexOf(game.id) === -1
  ) {
    return <Redirect to={`/game/${game.id}`} />;
  }

  const users: JumboUser[] = showSingleUser
    ? [gameScore.user]
    : gameScore.group.users;
  const userIds = users.map((x) => x.id);
  const allWagers: Wager[] = store.wagers.items;
  // const wagersByProp = game.props
  //   .map(toWagersByProps(store.wagers.items))
  //   .map((obj) => ({
  //     ...obj,
  //     details: gameScore.details
  //       ? gameScore.details.find((x: any) => x.prop === obj.prop.id) || {}
  //       : {},
  //   }))
  //   .filter(
  //     ({ wagers }) =>
  //       !!Object.keys(wagers).filter(
  //         (user) => userIds.indexOf(Number(user)) !== -1
  //       )
  //   );

  const wagersByProp = game.props.map((prop) => {
    // Wagers indexed by userId
    const wagers: any = {};
    for (const wager of allWagers) {
      if (wager.prop === prop.id && userIds.indexOf(wager.user) !== -1) {
        wagers[wager.user] = wager;
      }
    }
    const details = gameScore.details
      ? gameScore.details.find((x: any) => x.prop === prop.id) || {}
      : {};
    return {
      details,
      prop,
      wagers,
    };
  });
  return (
    <>
      <Container>
        <Link
          to={`/game/${store.games.item.id}/details`}
          className="quiet-link"
        >
          <h5 className="text-left spinner-pink">
            <div>
              <FontAwesomeIcon size="lg" icon={faChevronLeft} />
              &nbsp;&nbsp;All Grades
            </div>
          </h5>
        </Link>

        <h3 className="text-left" style={{ marginTop: 10 }}>
          {showSingleUser ? gameScore.user.username : gameScore.group.name}'s
          Wagers
        </h3>
        <p className="even-duller text-left titlecenter small">{game.name}</p>
        <Table className="table-sm leaderboard" style={{ marginTop: 10 }}>
          <thead>
            <tr>
              <td className="small even-duller">Question</td>
              {users.map((user, i) => (
                <React.Fragment key={i}>
                  <td>
                    <FontAwesomeIcon className="even-duller" icon={faUser} />
                  </td>
                  <td>
                    <FontAwesomeIcon
                      className="even-duller"
                      icon={faHandshakeAlt}
                    />
                  </td>
                </React.Fragment>
              ))}
              <td>
                <FontAwesomeIcon
                  className="even-duller"
                  icon={faClipboardCheck}
                />
              </td>
              <td>
                <FontAwesomeIcon className="even-duller" icon={faPiggyBank} />
              </td>
            </tr>
          </thead>
          <tbody>
            {wagersByProp.map(({ prop, wagers, details }, i) => {
              return (
                <tr key={i}>
                  <td className="content-5 binary small">{prop.prompt}</td>

                  {userIds.map((userId, j) => {
                    const wager = wagers[userId];
                    return wager === undefined ? (
                      <React.Fragment key={j}>
                        <td />
                        <td />
                      </React.Fragment>
                    ) : (
                      <React.Fragment key={j}>
                        <td className="small">
                          {wager.prediction === true ? (
                            <FontAwesomeIcon
                              className="spinner-pink"
                              icon={faThumbsUp}
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="spinner-pink"
                              icon={faThumbsDown}
                            />
                          )}
                        </td>
                        <td className="small spinner-pink">
                          <b className="spinner-pink">
                            {(wager.amount || 0) >= 0 ? (
                              <span>
                                {" "}
                                $
                                {((wager.amount || 0) / 100)
                                  .toFixed(2)
                                  .toLocaleString()}
                              </span>
                            ) : (
                              <span>
                                {" "}
                                -$
                                {(((wager.amount || 0) * -1) / 100)
                                  .toFixed(2)
                                  .toLocaleString()}
                              </span>
                            )}
                          </b>
                        </td>
                      </React.Fragment>
                    );
                  })}
                  <td className="small">
                    {prop.result ? (
                      <FontAwesomeIcon icon={faThumbsUp} />
                    ) : (
                      <FontAwesomeIcon icon={faThumbsDown} />
                    )}
                  </td>
                  <td className="small">
                    <h6
                      className={
                        (details.score || 0) >= 0
                          ? "td-positive"
                          : "td-negative"
                      }
                    >
                      {(details.score || 0) >= 0 ? (
                        <span>
                          {" "}
                          +$
                          {((details.score || 0) / 100)
                            .toFixed(2)
                            .toLocaleString()}
                        </span>
                      ) : (
                        <span>
                          {" "}
                          -$
                          {(((details.score || 0) * -1) / 100)
                            .toFixed(2)
                            .toLocaleString()}
                        </span>
                      )}
                    </h6>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>

        <hr className="cr" />
        <h5 className="nav-color jeopardy">Legend</h5>
        <ul className="text-left">
          <li className="text-left small even-duller">
            <FontAwesomeIcon icon={faUser} />
            &nbsp;&nbsp;—&nbsp;&nbsp;The player's wager.
          </li>
          <li className="text-left small even-duller">
            <FontAwesomeIcon icon={faHandshakeAlt} />
            &nbsp;&nbsp;—&nbsp;&nbsp;How much the player wagered.
          </li>
          <li className="text-left small even-duller">
            <FontAwesomeIcon icon={faClipboardCheck} />
            &nbsp;&nbsp;—&nbsp;&nbsp;How the question was graded.
          </li>
          <li className="text-left small even-duller">
            <FontAwesomeIcon icon={faPiggyBank} />
            &nbsp;&nbsp;—&nbsp;&nbsp;How much the player won/lost.
          </li>
        </ul>
      </Container>
    </>
  );
};
export default connectRoute(
  GroupGameDetailsRoute,
  (props) => `Individual details - ${props.store.games.item.name}`,
  [
    loadGameOrAlias,
    (actions, match) => actions.getGameScore(match.params.scoreId),
    (actions, match, store) =>
      actions.fetchWagers(
        {},
        {
          query: {
            prop__game: store.games.item.id,
          },
        }
      ),
  ]
);
