import { getGeoPosition } from "jumbo/lib/location";

export interface LocationState {
  location: Position | null;
  error: Error | null;
}

const SET_LOCATION = "@@jumbo/location/SET_LOCATION";
const SET_LOCATION_ERROR = "@@jumbo/location/SET_LOCATION_ERROR";

const getLocation = () => {
  return async (dispatch: any) => {
    return getGeoPosition().then(
      (location) => dispatch(setLocation(location)),
      (error) => dispatch(setLocationError(error))
    );
  };
};

const setLocation = (location: Position) => {
  return {
    location,
    type: SET_LOCATION,
  };
};

const setLocationError = (error: Error) => {
  return {
    error,
    type: SET_LOCATION_ERROR,
  };
};

const rootReducer = (
  state: LocationState = { location: null, error: null },
  action: any
) => {
  switch (action.type) {
    case SET_LOCATION:
      return { ...state, location: action.location };
    case SET_LOCATION_ERROR:
      return { ...state, error: action.error };
    default:
      return state;
  }
};

const actions = { getLocation, setLocation, setLocationError };
export { actions, rootReducer };
