import React from "react";
import Form from "react-bootstrap/Form";

import { getDisplayName } from "jumbo/lib/string";

import { FinalFormProps, SharedProps } from "./final-form-wrapper";

// These props should be extended by any component that is using
// the GenericField.
export interface GenericProps extends SharedProps {
  label?: string | JSX.Element;
  placeholder?: string;
}

// These props are exclusive to the GenericField.
export interface Props extends GenericProps, FinalFormProps {
  renderInput: (data: {
    input: any;
    label: string | JSX.Element;
    placeholder?: string;
  }) => JSX.Element | null;
  hideLabel?: boolean;
}

/**
 * A generic field with feedback embedded. This accepts generic input props
 * if you need additional customization.
 */
const GenericFieldWithFeedback: React.FC<Props> = (props) => {
  const label = props.label || getDisplayName(props.name);
  const placeholder = props.placeholder;
  const error =
    ((!props.meta.pristine || props.meta.submitFailed) && props.meta.error) ||
    props.meta.submitError;
  return (
    <Form.Group>
      {!props.hideLabel ? <Form.Label>{label}</Form.Label> : null}
      {props.renderInput({ input: props.input, label, placeholder })}
      <Form.Text className="text-danger">{error}</Form.Text>
    </Form.Group>
  );
};

export default GenericFieldWithFeedback;
