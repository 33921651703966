/**
 * Library utilities for interacting with PWA installation semantics.
 */

interface UserChoiceResult {
  outcome: "accepted" | "dismissed";
  platform: string;
}

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<UserChoiceResult>;
  prompt(): Promise<void>;
}

let BEFORE_INSTALL_PROMPT_EVENT: BeforeInstallPromptEvent | null = null;
const ON_INSTALLED_CALLBACKS: Array<() => void> = [];

/** Shows an installation prompt if one is available. */
export const showNativeInstallPrompt = (): Promise<UserChoiceResult> => {
  if (BEFORE_INSTALL_PROMPT_EVENT === null) {
    return Promise.reject("Missing beforeinstallprompt event.");
  }
  const event = BEFORE_INSTALL_PROMPT_EVENT;
  event.prompt();
  // Note that prompt can only be called once, so to prevent multiple calls
  // we clear the cached value here.
  BEFORE_INSTALL_PROMPT_EVENT = null;
  return event.userChoice;
};

/** Add a callback that will be called whenever the app is installed. */
export const onInstalled = (callback: () => void) => {
  ON_INSTALLED_CALLBACKS.push(callback);
};

/** Will the browser let us install? */
export const canInstall = (): boolean => {
  return BEFORE_INSTALL_PROMPT_EVENT !== null;
};

/** Have we shown an application level prompt before? */
export const hasShownPrompt = (): boolean => {
  return window.localStorage["has-show-installation-prompt-redux"];
};

/** Sets whether we've shown an application level prompt. */
export const setHasShownPrompt = (value: boolean) => {
  window.localStorage["has-show-installation-prompt-redux"] = value;
};

/** Attach window listeners as necessary. */
export const initializeInstallation = () => {
  // Catch the before install prompt and cache it for later use.
  window.addEventListener("beforeinstallprompt", (e) => {
    e.preventDefault();
    BEFORE_INSTALL_PROMPT_EVENT = e as BeforeInstallPromptEvent;
  });

  // Catch the app installed and notify any other callbacks.
  window.addEventListener("appinstalled", () => {
    for (const callback of ON_INSTALLED_CALLBACKS) {
      callback();
    }
  });
};
