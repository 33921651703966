import {
  faDiscord,
  faFacebook,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons";
import { faUsersCrown } from "@fortawesome/pro-solid-svg-icons";
import { faCalendarPlus } from "@fortawesome/pro-solid-svg-icons";
import { faLightbulbExclamation } from "@fortawesome/pro-solid-svg-icons";
import { faCommentPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";

import { showInstallPromptAsToastIfNecessary } from "jumbo/components/install-prompt";
import ScrollToTop from "jumbo/components/scroll-to-top";
import {
  canInstall,
  hasShownPrompt,
  setHasShownPrompt,
  showNativeInstallPrompt,
} from "jumbo/lib/installation";

import connectRoute from "./connect-route";

const LOGO = "/static/images/dice.png";

const InstallRoute: React.FC = () => {
  return (
    <>
      <div>
        <h4 className="play-header">
          <Link to={`/`} className="spinner-pink">
            <FontAwesomeIcon icon={faChevronLeft} className="spinner-pink" />{" "}
          </Link>
        </h4>
        <Container className="jumbo-info-pages rules-page">
          <div className="lobbytext">
            <span>
              <div className="text-left" style={{ paddingTop: 5 }}>
                <LinkContainer to="/" className="four-logo">
                  <img src={LOGO} />
                </LinkContainer>
              </div>

              <h2 className="text-left binary">How to Install</h2>
              <br />

              <span
                className="text-left binary details-text"
                style={{ paddingBottom: 5, paddingTop: 20 }}
              >
                Wager Town is available as a free app on mobile and desktop. It
                requires almost no storage to install and allows you to play
                instantly.
              </span>
              <br />
              <br />
              <hr />

              <div className="text-left" style={{ paddingBottom: 0 }}>
                <div
                  className="nav-lighter small-details-text-3"
                  style={{ paddingBottom: 10 }}
                >
                  <div
                    className="text-left even-duller small-details-text"
                    style={{ paddingTop: 15, paddingBottom: 10 }}
                  >
                    Coming from Facebook?
                  </div>

                  <div className="small-details-text even-duller">
                    If you're coming from an ad on Facebook or Instagram, you'll
                    need to go to wager.town in your browser first before
                    following the steps below.
                  </div>
                </div>
              </div>
              <hr />
              <br />
              <h4 className="text-left binary">Chrome users (Android)</h4>
              <div
                className="text-left binary small-details-text"
                style={{ paddingTop: 15 }}
              >
                Open the <b>Menu</b> (tap the 3 dots in the upper right of your
                screen).
                <br />
                <br />
                Then tap <b>Add to home screen.</b>
                <br />
                <br />
                <h4 className="text-left binary" style={{ paddingTop: 25 }}>
                  Safari users (iPhone)
                </h4>
                <div
                  className="text-left binary small-details-text"
                  style={{ paddingTop: 15 }}
                >
                  Tap the <b>Share</b> icon (bottom center of your screen)
                  <br />
                  <br />
                  Then tap <b>Add to home screen.</b>
                  <br />
                  <br />
                  <br />
                </div>
                <h4 className="text-left binary">Launching the app</h4>
                <div
                  className="text-left binary small-details-text"
                  style={{ paddingTop: 15 }}
                >
                  After you've followed the steps above, just tap the Wager Town
                  icon on your device's home screen to play instantly. That
                  easy!
                </div>
                <br />
              </div>
            </span>
          </div>

          <br />

          <hr />
          <Row>
            <Col>
              {" "}
              <div className="text-center nav-footer">
                <a href="http://www.facebook.com/wagertownusa">
                  <FontAwesomeIcon
                    icon={faFacebook}
                    size="lg"
                    className="even-duller"
                  />
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a href="http://twitter.com/wagertownusa/">
                  <FontAwesomeIcon
                    icon={faTwitter}
                    size="lg"
                    className="even-duller"
                  />
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a href="https://discord.gg/VC7bzvdYGD">
                  <FontAwesomeIcon
                    className="even-duller"
                    size="lg"
                    icon={faDiscord}
                  />
                </a>
              </div>
            </Col>
          </Row>
          <hr />
          <br />
          <br />
          <p className="nav-color-dull small text-center">
            Super Jumbo, Inc © 2020
          </p>

          <br />
        </Container>
      </div>
    </>
  );
};

export default connectRoute(InstallRoute, "How to Install");
