export const getAuthHeaders = () => {
  const token = window.localStorage["api-token"];
  return {
    Authorization: token ? `Token ${token}` : "",
  };
};

export const postAsPromise = async (endpoint: string, data: any) => {
  const response = await fetchWithHeaders(endpoint, {
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
  });
  if (response.status === 204) {
    return undefined;
  }
  const responseBody = await response.json();
  return response.status < 400 ? responseBody : Promise.reject(responseBody);
};

export const fetchWithHeaders = (endpoint: string, options: any = {}) => {
  const extraHeaders = getAuthHeaders();
  const headers = options.headers
    ? { ...options.headers, ...extraHeaders }
    : extraHeaders;
  return fetch(endpoint, {
    ...options,
    headers,
  });
};
