import { createResource } from "redux-rest-resource";

import { API_ROOT } from "jumbo/lib/constants";

export const { types, actions, rootReducer } = createResource({
  actions: {
    getLeaderboard: {
      method: "GET",
      reduce: (state: any, action: any) => {
        if (action.status !== "resolved") {
          return state;
        }
        return {
          ...state,
          leaderboard: action.body.leaderboard || undefined,
        };
      },
      url: "./leaderboard",
    },
  },
  name: "gameScore",
  url: `${API_ROOT}/game_scores/:id`,
});
