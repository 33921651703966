import React from "react";

import GenericForm from "./generic-form";

export interface Props {
  group?: JumboGroup;
  onSubmit: (data: any) => void;
}

const GroupForm: React.FC<Props> = ({ onSubmit, group }) => {
  return (
    <GenericForm
      onSubmit={onSubmit}
      initialValues={group}
      submitLabel="Create Team"
      fields={[
        { name: "name", label: "Pool Name" },
        { name: "thumbnail", type: "image", label: "Pool Photo or Gif" },
        {
          label: "Make private?",
          name: "private",
          type: "check",
        },
        {
          label: "Team Type",
          name: "scoring",
          options: [
            {
              display: "Socialist",
              value: "avg",
            },
            {
              display: "Representative",
              value: "med",
            },
            {
              display: "Anarchy",
              value: "rnd",
            },
          ],
          type: "select",
        },
        { name: "location", type: "location" },
      ]}
    />
  );
};

export default GroupForm;
