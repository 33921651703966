import { faCheckCircle as falCheckCircle } from "@fortawesome/pro-light-svg-icons";
import { faCheckCircle as fasCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { LinkContainer } from "react-router-bootstrap";

interface Props {
  wagers?: Wager[];
  game: Game;
  current: Prop;
}
const GameNavigation: React.FC<Props> = ({ game, current, wagers }) => {
  return (
    <div>
      {wagers ? (
        <ButtonGroup vertical>
          {game.props
            .sort((a, b) => b.multiplier - a.multiplier)
            .map((o, i) => {
              const hasWager = !!wagers?.find((x) => x.prop === o.id);
              return (
                <>
                  {hasWager ? (
                    <>
                      <LinkContainer
                        to={`/game/${game.id}/play?prop=${o.id}`}
                        key={i}
                      >
                        <Button variant="dark" active={current.id === o.id}>
                          {(o.multiplier || 0) >= 1 ? (
                            <div className="user-header play-header">
                              +{(o.multiplier * 100).toFixed(0)}
                              &nbsp;&nbsp;&nbsp;
                              <FontAwesomeIcon
                                className="td-promo"
                                size="xs"
                                icon={fasCheckCircle}
                              />
                            </div>
                          ) : (
                            <div className="user-header play-header">
                              -
                              {(
                                ((o.multiplier + 1) / o.multiplier - 1) *
                                100
                              ).toFixed()}
                              &nbsp;&nbsp;&nbsp;
                              <FontAwesomeIcon
                                size="xs"
                                className="td-promo"
                                icon={fasCheckCircle}
                              />
                            </div>
                          )}
                        </Button>
                      </LinkContainer>
                    </>
                  ) : (
                    <>
                      <LinkContainer
                        to={`/game/${game.id}/play?prop=${o.id}`}
                        key={i}
                      >
                        <Button variant="light" active={current.id === o.id}>
                          {(o.multiplier || 0) >= 1 ? (
                            <div className="user-header play-header">
                              +{(o.multiplier * 100).toFixed()}
                              &nbsp;&nbsp;&nbsp;
                              <FontAwesomeIcon
                                size="xs"
                                className="even-duller"
                                icon={falCheckCircle}
                              />
                            </div>
                          ) : (
                            <div className="user-header play-header">
                              -
                              {(
                                ((o.multiplier + 1) / o.multiplier - 1) *
                                100
                              ).toFixed()}
                              &nbsp;&nbsp;&nbsp;
                              <FontAwesomeIcon
                                size="xs"
                                className="even-duller"
                                icon={falCheckCircle}
                              />
                            </div>
                          )}
                        </Button>
                      </LinkContainer>
                    </>
                  )}
                </>
              );
            })}
        </ButtonGroup>
      ) : (
        <ButtonGroup vertical>
          <span></span>
        </ButtonGroup>
      )}
    </div>
  );
};

export default GameNavigation;
