import React from "react";
import { Redirect } from "react-router";

import userService from "jumbo/lib/user-service";

import connectRoute, { ConnectedRouteProps } from "./connect-route";

export const LogoutRoute: React.FC<ConnectedRouteProps> = ({ actions }) => {
  return <Redirect to="/"></Redirect>;
};

export default connectRoute(LogoutRoute, "Logout", [
  (actions) => userService.logout().then(actions.getCurrentUser),
]);
