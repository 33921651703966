import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";

import { rootReducer as formOptionsReducer } from "jumbo/store/form-options";
import { rootReducer as gameCodesReducer } from "jumbo/store/game-codes";
import { rootReducer as gameReducer } from "jumbo/store/game-resource";
import { rootReducer as gameScoreReducer } from "jumbo/store/game-score-resource";
import { rootReducer as groupReducer } from "jumbo/store/group-resource";
import { rootReducer as locationReducer } from "jumbo/store/location";
import { rootReducer as propReducer } from "jumbo/store/prop-resource";
import { rootReducer as statsReducer } from "jumbo/store/stats";
import { rootReducer as userProfileReducer } from "jumbo/store/user-profile-resource";
import { rootReducer as userReducer } from "jumbo/store/user-resource";
import { rootReducer as wagerReducer } from "jumbo/store/wager-resource";
import { rootReducer as deviceReducer } from "jumbo/store/device";

const rootReducer = combineReducers({
  formOptions: formOptionsReducer,
  gameCodes: gameCodesReducer,
  gameScore: gameScoreReducer,
  games: gameReducer,
  groups: groupReducer,
  location: locationReducer,
  props: propReducer,
  stats: statsReducer,
  userProfiles: userProfileReducer,
  users: userReducer,
  wagers: wagerReducer,
  device: deviceReducer,
});

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
