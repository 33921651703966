import React from "react";
import ReactDom from "react-dom";

import App from "jumbo/app";
import { initializeInstallation } from "jumbo/lib/installation";

const installServiceWorker = async () => {
  if ("serviceWorker" in navigator) {
    try {
      await navigator.serviceWorker.register("/worker-bundle.js");
    } catch (e) {
      return;
    }
  }
};

const renderReactApp = () => {
  let root = document.getElementById("root");
  if (!root) {
    root = document.createElement("div");
    document.body.append(root);
  }
  ReactDom.render(<App />, root);
};

renderReactApp();
installServiceWorker();
initializeInstallation();
