import {
  faFacebook,
  faPaypal,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faUserCrown } from "@fortawesome/pro-solid-svg-icons";
import { faLock } from "@fortawesome/pro-solid-svg-icons";
import { faWhistle } from "@fortawesome/pro-solid-svg-icons";
import { faSackDollar } from "@fortawesome/pro-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { faUsersCrown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { LinkContainer } from "react-router-bootstrap";
import { Link, Redirect } from "react-router-dom";

import Alert from "jumbo/components/alert";
import Leaderboard from "jumbo/components/leaderboard";
import ShareButton from "jumbo/components/share-button";
import { GameTimeDisplay } from "jumbo/components/shared/game-time-display";

import connectRoute, { ConnectedRouteProps } from "./connect-route";

export const LeaderboardAllTimeRoute: React.FC<ConnectedRouteProps> = ({
  store,
}) => {
  return (
    <div>
      <h4 className="text-left spinner-pink play-header">
        <Link to={`/leaderboard`} className="quiet-link">
          <FontAwesomeIcon icon={faChevronLeft} className="spinner-pink" />
        </Link>
        <h4 className="spinner-pink">&nbsp;&nbsp;&nbsp;&nbsp;</h4>
      </h4>
      <Container>
        <div style={{ paddingBottom: 15 }}>
          <div className="text-center" style={{ paddingTop: 5 }}>
            <FontAwesomeIcon className="wager" size="2x" icon={faUsersCrown} />
            <h4 className="binary" style={{ paddingTop: 10 }}>
              All Time Standings
            </h4>
            <span className="text-center nav-color-dull">
              Wager Town's all-time 🐐's
            </span>
            <br />
          </div>
        </div>
        {store.users.currentUser ? (
          <Leaderboard
            userName={store.users.currentUser.username}
            leaderboard={store.gameScore.leaderboard}
          />
        ) : (
          <Leaderboard leaderboard={store.gameScore.leaderboard} />
        )}
        <br />
        <hr className="cr" />

        <h6 className="nav-color-dull">Legend</h6>
        <ul className="text-left">
          <li className="text-left small nav-color-dull">
            <FontAwesomeIcon className="wager" icon={faUserCrown} />
            &nbsp;&nbsp;—&nbsp;&nbsp;Pro member
          </li>
          <li className="text-left small nav-color-dull">
            <FontAwesomeIcon className="mod" icon={faWhistle} />
            &nbsp;&nbsp;—&nbsp;&nbsp;Community Mod
          </li>
          <li className="text-left small nav-color-dull">
            <FontAwesomeIcon icon={faSackDollar} />
            &nbsp;&nbsp;—&nbsp;&nbsp;Player's balance for the week.
          </li>
        </ul>
        <hr className="cr" />
        <section className="text-center home-footer">
          {" "}
          <div className="text-center nav-footer">
            <a href="http://www.facebook.com/wagertownusa">
              <FontAwesomeIcon
                icon={faFacebook}
                className="even-duller"
                size="lg"
              />
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a href="http://twitter.com/wagertownusa/">
              <FontAwesomeIcon
                icon={faTwitter}
                className="even-duller"
                size="lg"
              />
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a href="mailto:hello@wager.town">
              <FontAwesomeIcon
                icon={faEnvelope}
                className="even-duller"
                size="lg"
              />
            </a>
          </div>
        </section>
        <hr />
        <br />
        <div className="text-center">
          <span className="even-duller small">
            “Always make a total effort, <br />
            even when the odds are against you.” <br />– Arnold Palmer
          </span>
        </div>
        <br />
      </Container>
    </div>
  );
};

export default connectRoute(LeaderboardAllTimeRoute, "All Time", [
  (actions) =>
    actions.getLeaderboardGameScore(
      {},
      {
        query: {
          end_date: moment()
            .subtract(1, "weeks")
            .endOf("isoWeek")
            .toISOString(),
          start_date: moment()
            .subtract(24, "weeks")
            .startOf("isoWeek")
            .toISOString(),
        },
      }
    ),
]);
