import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faCommentPlus } from "@fortawesome/pro-light-svg-icons";
import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import { faHandHoldingUsd } from "@fortawesome/pro-solid-svg-icons";
import { faTvRetro } from "@fortawesome/pro-solid-svg-icons";
import { faUserPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { LinkContainer } from "react-router-bootstrap";
import { Link, Redirect } from "react-router-dom";

import Alert from "jumbo/components/alert";
import WagerForm from "jumbo/components/forms/wager-form";
import GameNavigation from "jumbo/components/game-navigation";
import { showInstallPromptAsToastIfNecessary } from "jumbo/components/install-prompt";
import PropDetails from "jumbo/components/prop-details";
import ShareButtonLong from "jumbo/components/share-button-long";
import { GameTimeDisplay } from "jumbo/components/shared/game-time-display";
import { loadGameOrAlias } from "jumbo/lib/routing";
import { getSingleStat } from "jumbo/lib/stats";

import connectRoute, { ConnectedRouteProps } from "./connect-route";

export interface State {
  maxWagerAmount: number;
}

export class PlayGameRoute extends React.Component<ConnectedRouteProps, State> {
  constructor(props: ConnectedRouteProps) {
    super(props);
    this.state = {
      maxWagerAmount: 100,
    };
  }

  public componentDidMount() {
    this.getMaxWagerAmount();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const referredBy = urlSearchParams.get("referred_by");
    localStorage.setItem("referredBy", referredBy ? referredBy : "");
  }

  public componentDidUpdate(prevProps: ConnectedRouteProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.getMaxWagerAmount();
    }
  }

  public render() {
    const { actions, store, history } = this.props;

    const game: Game = store.games.item;
    if (
      (game.requires_code &&
        store.gameCodes.authorizedGames.indexOf(game.id) === -1) ||
      moment(game.end_time).isBefore(moment())
    ) {
      return <Redirect to={`/game/${game.id}`} />;
    }

    const user: JumboUser | undefined = store.users.currentUser;

    const wagers: Wager[] = store.wagers.items;
    const propIndex: number | undefined = this.getPropIndex();
    const isGameAlmostOver =
      game.end_time &&
      moment().toISOString() >
        moment(game.end_time).subtract(1, "hours").toISOString();

    const warningTime =
      game.end_time &&
      moment().toISOString() >
        moment(game.end_time).subtract(3, "hours").toISOString();

    if (propIndex === undefined) {
      return (
        <div>
          <Container>
            <Link to={`/`}>
              <h2 className="text-right special-link">
                <FontAwesomeIcon icon={faTimes} />
              </h2>
            </Link>
            <Container>
              <br />
              <br />
              <br />
              <br />
              <br />
              <h1 className="four-logo mx-auto">
                <FontAwesomeIcon
                  className="spinner-pink"
                  icon={faCommentPlus}
                />
              </h1>
              <br />
              <br />
              <p className="text-center">
                Looks like you're early to the party. <br />
                Add a question so others can play!
              </p>

              <LinkContainer
                to={`/game/${game.id}/create-prop`}
                className="quiet-link"
              >
                <ButtonGroup className="w-100">
                  <Button className="btn logout-button btn-lg">
                    <span className="binary">Let's go!</span>
                  </Button>
                </ButtonGroup>
              </LinkContainer>
              <br />
              <br />
              <Link to={`/`} className="quiet-link">
                <h6 className="special-link text-center">No thanks</h6>
              </Link>
            </Container>
          </Container>
        </div>
      );
    }

    // Which prop this page has currently loaded.
    const prop = game.props[propIndex];

    // Find the associated wager (if one exists).
    const wager = user
      ? wagers.find((w) => w.prop === prop.id && w.user === user.id)
      : undefined;

    return (
      <>
        {store.users.currentUser ? (
          <div>
            <Alert
              alertKey="how-to-plssadassssaay-gasasssdamsase"
              className="alert-custom4c"
              dismissible
            >
              <h5 style={{ marginTop: 5 }}>
                <b className="wager user-header" style={{ marginTop: 10 }}>
                  <FontAwesomeIcon
                    className="wager"
                    size="lg"
                    icon={faHandHoldingUsd}
                  />
                  &nbsp;&nbsp;&nbsp;You've received free play!
                </b>
              </h5>
              <div
                className="nav-love small-host-text"
                style={{ marginTop: 15, marginBottom: 8 }}
              >
                Wager Town players get&nbsp;
                <span>100¢ of free play, per game</span>. Rumor has it you're a
                sharp, {store.users.currentUser.username}. Show us what you got
                below!
              </div>
            </Alert>
          </div>
        ) : null}
        <div style={{ marginLeft: 0 }}>
          <div className="play-header">
            <Link to={`/game/${store.games.item.id}`} className="quiet-link">
              <span className="text-center even-duller">
                <div className="user-header">
                  <div
                    className="even-duller content-game small"
                    style={{ marginBottom: 4 }}
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <b>{game.name}</b> &nbsp;&nbsp;
                  </div>
                  {user ? (
                    <small>
                      <FontAwesomeIcon
                        className="even-duller"
                        size="sm"
                        icon={faChevronDown}
                      />
                    </small>
                  ) : (
                    <small>
                      <FontAwesomeIcon
                        className="black"
                        size="sm"
                        icon={faChevronDown}
                      />
                    </small>
                  )}
                </div>
                <div>
                  {isGameAlmostOver ? (
                    <div
                      className="small-host-text text-left"
                      style={{ marginLeft: 0 }}
                    >
                      <small className="user-header">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Starting in{" "}
                        <GameTimeDisplay endTime={game.end_time} />
                      </small>
                    </div>
                  ) : (
                    <div
                      className="small-host-text text-left"
                      style={{ marginLeft: 0 }}
                    >
                      <small className="user-header">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Starting in{" "}
                        <GameTimeDisplay endTime={game.end_time} />
                      </small>
                    </div>
                  )}
                </div>
              </span>
            </Link>

            <div>
              <div>
                <ShareButtonLong
                  game={game}
                  link={`${window.location.protocol}//${window.location.hostname}/game/${game.id}/play`}
                ></ShareButtonLong>
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>

        <Container fluid style={{ paddingRight: 40, marginTop: 0 }}>
          <Row noGutters>
            <Col>
              <PropDetails
                game={game}
                prop={prop}
                user={store.users.currentUser ? store.users.currentUser : null}
                stats={
                  getSingleStat(store.stats.stats, {
                    game: game.id,
                    name: "pct_positive_amount_wagered",
                    prop: prop.id,
                  }) || 0
                }
              />

              <WagerForm
                user={user}
                wager={wager}
                multiplier={prop.multiplier}
                amountRemaining={this.state.maxWagerAmount}
                submitLabel={!user ? "Log In to Save" : undefined}
                onSubmit={async ({ prediction, amount }) => {
                  if (!user) {
                    history.push(`/login?redirect=${window.location.pathname}`);
                    return;
                  }

                  await (wager
                    ? actions.updateWager({
                        ...wager,
                        amount,
                        prediction,
                      })
                    : actions.createWager({
                        amount,
                        prediction,
                        prop: prop.id,
                      }));

                  if (propIndex === game.props.length - 1) {
                    history.push(`/game/${game.id}/`);
                    showInstallPromptAsToastIfNecessary();
                  } else {
                    const nextProp = game.props[propIndex + 1];
                    history.push(`/game/${game.id}/play?prop=${nextProp.id}`);
                  }
                }}
              />
            </Col>

            <Col xs={1} style={{ marginTop: 10, marginLeft: 30 }}>
              <br />
              <br />

              <br />
              <br />

              <GameNavigation
                game={game}
                current={prop}
                wagers={wagers.filter((x) => x.user === user?.id)}
              />
              <br />

              <br />
              <br />
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  private getMaxWagerAmount = async () => {
    const prop = this.getProp();
    if (prop !== undefined) {
      const result = await this.props.actions.getMaxWagerForProp(prop);
      // TODO: This should probably go through redux.
      this.setState({
        maxWagerAmount: result.body.max_amount,
      });
    }
  };

  private getPropIndex = () => {
    const game: Game = this.props.store.games.item;
    if (game.props.length === 0) {
      return undefined;
    }

    const params = new URLSearchParams(this.props.location.search);
    const propId = params.get("prop");
    if (propId === null) {
      return 0;
    }

    const propIndex = game.props.findIndex((o) => o.id === +propId);
    return propIndex > -1 ? propIndex : 0;
  };

  private getProp = () => {
    const propIndex = this.getPropIndex();
    if (propIndex === undefined) {
      return undefined;
    }
    const game: Game = this.props.store.games.item;
    return game.props[propIndex];
  };
}

const getStatsForProp = (game: number, prop: number) => {
  return [{ name: "pct_positive_amount_wagered", game, prop }];
};

const getAllPropStats = (game: Game) => {
  let stats: any = [];
  for (const prop of game.props) {
    stats = stats.concat(getStatsForProp(game.id, prop.id));
  }
  return stats;
};

export default connectRoute(
  PlayGameRoute,
  (props) => `Play ${props.store.games.item.name}`,
  [
    (actions) => actions.getCurrentUser(),
    loadGameOrAlias,
    (actions, match, store) =>
      actions.getStats(getAllPropStats(store.games.item)),
    (actions, match, store) =>
      store.users.currentUser
        ? actions.fetchWagers(
            {},
            {
              query: {
                prop__game: store.games.item.id,
                user: store.users.currentUser.id,
              },
            }
          )
        : Promise.resolve(),
  ]
);
