import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faCrosshairs } from "@fortawesome/pro-solid-svg-icons";
import { faSackDollar } from "@fortawesome/pro-solid-svg-icons";
import { faWhistle } from "@fortawesome/pro-solid-svg-icons";
import { faChevronDoubleUp } from "@fortawesome/pro-solid-svg-icons";
import { faChevronDoubleDown } from "@fortawesome/pro-solid-svg-icons";
import { faPiggyBank } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Badge from "react-bootstrap/Badge";
import Table from "react-bootstrap/Table";
import { Link, Redirect } from "react-router-dom";

import StatsFragment from "./stats-fragment";

interface LeaderboardItem {
  user: JumboUser;
  score: number;
}
interface Props {
  userName?: string;
  leaderboard: LeaderboardItem[];
}

const Leaderboard: React.FC<Props> = ({ userName, leaderboard }) => {
  return (
    <div>
      <Table className="leaderboard table-sm" style={{ padding: 5 }}>
        <thead>
          <tr className="small tr-small">
            <th className="small star-opacity">
              <span className="star-opacity">#</span>
            </th>
            <th className="star-opacity small">
              <FontAwesomeIcon className="star-opacity" icon={faUser} />
            </th>

            <th>
              <FontAwesomeIcon className="star-opacity" icon={faPiggyBank} />
            </th>
          </tr>
        </thead>
        <tbody className="nav-love">
          {leaderboard.map((item, index) => (
            <tr
              className={
                item.user.username === userName
                  ? "leaderboard-highlight-user"
                  : "winner"
              }
              key={index}
            >
              <td className="small column-med">
                <div className="small">
                  {index === 0 ? (
                    <h3 className="wager">🐐</h3>
                  ) : (
                    <span className="even-duller">{index + 1}</span>
                  )}
                </div>
              </td>

              <td
                className={
                  item.user.username === userName
                    ? "leaderboard-highlight-user"
                    : ""
                }
              >
                {item.user.profile.is_subscriber ? (
                  <>
                    {item.user.profile.is_baller ? (
                      <div>
                        <div className="nav-love big-wager-text user-header">
                          <b>{item.user.username}</b>
                          <div>
                            &nbsp;&nbsp;&nbsp;
                            <FontAwesomeIcon
                              size="sm"
                              className="gold-color"
                              icon={faWhistle}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="nav-love big-wager-text user-header">
                        <b>{item.user.username}</b>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {item.user.profile.is_baller ? (
                      <div>
                        <div className="nav-love big-wager-text user-header">
                          <b>{item.user.username}</b>
                          <div>
                            &nbsp;&nbsp;&nbsp;
                            <FontAwesomeIcon
                              size="sm"
                              className="gold-color"
                              icon={faWhistle}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="user-header nav-love big-wager-text">
                        <div>
                          <b>{item.user.username}</b>
                        </div>
                      </div>
                    )}
                  </>
                )}
                <div
                  className="text-left small-host-text user-header"
                  style={{ paddingTop: 5 }}
                >
                  <StatsFragment
                    statDefinition={{
                      name: "hit_ratio_this_week",
                      user: item.user.id,
                    }}
                    render={(value) => {
                      return (
                        <div>
                          <span className="small even-duller user-header">
                            <span className="even-duller">
                              <b>{value}%</b>
                            </span>
                            &nbsp;&nbsp;|&nbsp;&nbsp;
                          </span>
                        </div>
                      );
                    }}
                  />

                  <StatsFragment
                    statDefinition={{
                      name: "score_per_game_this_week",
                      user: item.user.id,
                    }}
                    render={(value) => {
                      return (
                        <span className="even-duller small">
                          ${((value || 0) / 100).toFixed(2)} EPG
                        </span>
                      );
                    }}
                  />
                  <span className="even-duller small">
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                  </span>
                  <StatsFragment
                    statDefinition={{
                      name: "weekly_games_total",
                      user: item.user.id,
                    }}
                    render={(value) => {
                      return <span className="even-duller small">{value}</span>;
                    }}
                  />
                  <span className="even-duller small">&nbsp;&nbsp;GP</span>
                </div>
              </td>

              <td>
                <div>
                  {(item.score || 0) >= 2500 ? (
                    <span className="td-positive small-details-text">
                      <b>
                        ${((item.score || 0) / 100).toFixed(2).toLocaleString()}
                      </b>
                    </span>
                  ) : (
                    <>
                      {(item.score || 0) >= 0 ? (
                        <span className="nav-love small-details-text">
                          $
                          {((item.score || 0) / 100)
                            .toFixed(2)
                            .toLocaleString()}
                        </span>
                      ) : (
                        <span className="nav-love small-details-text">
                          -$
                          {(((item.score || 0) * -1) / 100)
                            .toFixed(2)
                            .toLocaleString()}
                        </span>
                      )}
                    </>
                  )}
                  <StatsFragment
                    statDefinition={{
                      name: "yesterday_change_pct",
                      user: item.user.id,
                    }}
                    render={(value) => {
                      return (
                        <>
                          {(value || 0) >= 25 ? (
                            <span className="td-promo small">
                              &nbsp;
                              <FontAwesomeIcon
                                className="td-promo"
                                size="xs"
                                icon={faChevronDoubleUp}
                              />
                            </span>
                          ) : (
                            <>
                              {(value || 0) >= 25 ? (
                                <span className="td-promo small">
                                  &nbsp;
                                  <FontAwesomeIcon
                                    className="td-promo"
                                    size="xs"
                                    icon={faChevronDoubleUp}
                                  />
                                </span>
                              ) : (
                                <>
                                  {(value || 0) >= 5 ? (
                                    <span className="td-promo small">
                                      &nbsp;
                                      <FontAwesomeIcon
                                        className="td-promo"
                                        size="xs"
                                        icon={faChevronUp}
                                      />
                                    </span>
                                  ) : (
                                    <>
                                      {(value || 0) <= -5 ? (
                                        <span className="red-text small">
                                          &nbsp;
                                          <FontAwesomeIcon
                                            className="td-negative"
                                            size="xs"
                                            icon={faChevronDown}
                                          />
                                        </span>
                                      ) : (
                                        <>
                                          {(value || 0) <= -25 ? (
                                            <span className="red-text small">
                                              &nbsp;
                                              <FontAwesomeIcon
                                                className="td-negative"
                                                size="xs"
                                                icon={faChevronDoubleDown}
                                              />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      );
                    }}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default Leaderboard;
