import { createResource } from "redux-rest-resource";

import { API_ROOT } from "jumbo/lib/constants";

const isAdmin = (user: JumboUser) =>
  user &&
  (user.id === 2 ||
    user.id === 5 ||
    user.id === 159 ||
    user.id === 17531 ||
    user.id === 28550 ||
    user.id === 29068 ||
    user.id === 29068 ||
    user.id === 28878 ||
    user.id === 31461);

export const { types, actions, rootReducer } = createResource({
  actions: {
    getCurrent: {
      method: "GET",
      reduce: (state: any, action: any) => {
        if (action.status !== "resolved") {
          return state;
        }

        return {
          ...state,
          currentUser: action.body
            ? {
                ...action.body,
                profile: {
                  ...action.body.profile,
                  is_admin: isAdmin(action.body),
                },
              }
            : undefined,
        };
      },
      url: "./current",
    },
  },
  name: "user",
  url: `${API_ROOT}/users/:id`,
});
