export interface DeviceState {
  userAgent?: string;
}

const SET_USER_AGENT = "@@jumbo/device/SET_USER_AGENT";
const SET_OPERATING_SYSTEM = "@@jumbo/device/SET_OPERATING_SYSTEM";

export enum OperatingSystems {
  Android = "Android",
  iOS = "iOS",
  macOS = "macOS",
  WinPhone = "Windows Phone",
  unknown = "unknown",
}

function getOperatingSystem(userAgent: string) {
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return OperatingSystems.WinPhone;
  }

  if (/android/i.test(userAgent)) {
    return OperatingSystems.Android;
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return OperatingSystems.iOS;
  }

  if (/Mac OS X/i.test(userAgent)) {
    return OperatingSystems.macOS;
  }

  return OperatingSystems.unknown;
}

export function rootReducer(
  state: DeviceState = { userAgent: "" },
  action: any
) {
  switch (action.type) {
    case SET_USER_AGENT:
      return { ...state, userAgent: action.userAgent };
    case SET_OPERATING_SYSTEM:
      return { ...state, os: getOperatingSystem(action.userAgent) };
    default:
      return state;
  }
}

export const actions = {
  setUserAgent: (userAgent: string) => {
    return {
      userAgent,
      type: SET_USER_AGENT,
    };
  },
  setOperatingSystem: (userAgent: string) => {
    return {
      userAgent,
      type: SET_OPERATING_SYSTEM,
    };
  },
};

export default { actions, rootReducer };
