import { API_ROOT, VAPID_SERVER_PUBLIC_KEY } from "jumbo/lib/constants";
import { postAsPromise } from "jumbo/lib/network";

const requestNotificationPermission = async () => {
  const result = await Notification.requestPermission();
  return result === "granted";
};

const getRegistration = async () => {
  const hasPermission = await requestNotificationPermission();

  if (!hasPermission) {
    throw new Error("Does not have notification permissions");
  }

  const registration = await navigator.serviceWorker.getRegistration();
  if (!registration) {
    throw new Error("Could not find service worker registration");
  }
  return registration;
};

const getSubscription = async (create: boolean) => {
  const registration = await getRegistration();
  let subscription = await registration.pushManager.getSubscription();
  if (!subscription && create) {
    const options = {
      applicationServerKey: VAPID_SERVER_PUBLIC_KEY,
      userVisibleOnly: true,
    };
    subscription = await registration.pushManager.subscribe(options);
  }
  return subscription;
};

const notifyServer = async (
  subscription: PushSubscription,
  shouldCreate: boolean
) => {
  const browser = "chrome";
  const data = {
    browser,
    subscription: subscription.toJSON(),
  };

  await postAsPromise(
    `${API_ROOT}/push/${shouldCreate ? "subscribe" : "unsubscribe"}`,
    data
  );
};

export const subscribe = async () => {
  const subscription = await getSubscription(true);
  if (!subscription) {
    throw new Error("Could not get subscription");
  }
  await notifyServer(subscription, true);
};

export const unsubscribe = async () => {
  const subscription = await getSubscription(false);
  if (subscription) {
    await notifyServer(subscription, false);
    await subscription.unsubscribe();
  }
};

export const hasSubscription = async (): Promise<boolean> => {
  if (!navigator.serviceWorker) {
    return false;
  }
  const registration = await navigator.serviceWorker.getRegistration();
  return !!registration && !!(await registration.pushManager.getSubscription());
};

export const sendPushTest = async () => {
  await postAsPromise(`${API_ROOT}/push/send_test`, {});
};
