import React from "react";

import GenericForm from "./generic-form";

export interface Props {
  onSubmit: (data: Map<number, boolean>) => void;
  game: Game;
}

const GradeGameForm: React.FC<Props> = ({ onSubmit, game }) => {
  const fields = game.props.map((prop) => {
    return {
      label: prop.prompt,
      name: `prop-${prop.id}`,
      options: [
        { value: "", display: "-----" },
        { value: "true", display: "Yes" },
        { value: "false", display: "No" },
      ],
      type: "select",
    };
  });

  const initialValues: any = {};
  for (const prop of game.props) {
    if (prop.result !== null) {
      initialValues[`prop-${prop.id}`] = prop.result ? "true" : "false";
    }
  }

  return (
    <GenericForm
      onSubmit={(data) => {
        // Convert from string 'true/false' to boolean values and
        // put into a map via prop ID
        const processedData = new Map<number, boolean>();
        for (const prop of game.props) {
          if (
            data[`prop-${prop.id}`] !== "" &&
            data[`prop-${prop.id}`] !== undefined
          ) {
            processedData.set(prop.id, data[`prop-${prop.id}`] === "true");
          }
        }
        return onSubmit(processedData);
      }}
      submitLabel="Grade Game"
      fields={fields}
      initialValues={initialValues}
    />
  );
};

export default GradeGameForm;
