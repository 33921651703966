import { faThumbsUp } from "@fortawesome/pro-solid-svg-icons";
import { faHandshakeAlt } from "@fortawesome/pro-solid-svg-icons";
import { faUserAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import React from "react";

import ShareButtonShort3 from "jumbo/components/share-button-short3";

export interface Props {
  game?: Game;
  user?: boolean;
  prop: Prop;
  stats?: number;
}

const PropDetails: React.FC<Props> = ({ game, user, prop, stats }) => {
  return (
    <div>
      <div>
        <div
          className="flex-container text-left"
          style={{ marginTop: 20, marginBottom: 5 }}
        >
          <div>
            <div className="user-header">
              <div className="even-duller content-game">
                <small>
                  <b>{prop.user.username}</b> asks
                </small>
              </div>
            </div>

            <div style={{ marginTop: 7, marginBottom: 12 }}>
              <h3>
                <b>{prop.prompt}</b>{" "}
              </h3>
            </div>
            <div>
              {stats ? (
                <div className="user-header small-host-text">
                  <div className="user-header">
                    <small>
                      <FontAwesomeIcon className="td-promo" icon={faThumbsUp} />
                    </small>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <small className="nav-love">
                      <b>{stats}%</b>
                    </small>
                  </div>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {game ? (
                    <div className="text-danger">
                      <ShareButtonShort3
                        game={game}
                        link={`${window.location.protocol}//${window.location.hostname}/game/${game.id}/play?prop=${prop.id}`}
                        prop={prop.prompt}
                      />
                    </div>
                  ) : null}
                </div>
              ) : (
                <div className="user-header even-duller">
                  <small style={{ marginTop: 3 }}>
                    Be the first to bet on this!
                  </small>
                </div>
              )}
            </div>
          </div>
        </div>

        <div style={{ marginTop: 15, marginBottom: 8 }}>
          <div className="even-duller play-header">
            <div className="user-header" style={{ paddingBottom: 5 }}>
              <small>What's your prediction?</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropDetails;
