import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faUserAstronaut } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Container from "react-bootstrap/Container";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Link, Redirect } from "react-router-dom";

import Alert from "jumbo/components/alert";
import GameForm from "jumbo/components/forms/game-form";
import ScrollToTop from "jumbo/components/scroll-to-top";

import connectRoute, { ConnectedRouteProps } from "./connect-route";

export const CreateGameRoute: React.FC<ConnectedRouteProps> = ({
  actions,
  history,
  store,
}) => {
  const user: JumboUser | null = store.users.currentUser;

  return (
    <div>
      <ScrollToTop />
      <Container>
        <div>
          <Link to={`/`} className="quiet-link">
            <h3 className="text-right text-muted">
              <FontAwesomeIcon className="even-duller" icon={faTimes} />
            </h3>
          </Link>
          <h2 className="text-left">
            <b>Host a Game</b>
          </h2>
        </div>

        <Alert
          alertKey="propt2"
          className="alert-custom"
          style={{ marginTop: 20, marginBottom: 10 }}
        >
          <div
            className="nav-love small"
            style={{ marginTop: 5, marginBottom: 5 }}
          >
            <b>Tips for hosting popular games</b>
          </div>

          <span className="small-host-text even-duller">
            •&nbsp;&nbsp;Host for live events you plan on watching
            <br />
            •&nbsp;&nbsp;Grade your game soon after the event is over
            <br />
            •&nbsp;&nbsp;Be creative and try to stump the audience!
          </span>
        </Alert>
        <br />
        <div className="text-left">
          <GameForm
            onSubmit={async (data) => {
              const response = await actions.createGame(data);
              const game = response.body;
              history.push(`/game/${game.id}`);
            }}
            allowCode={user ? user.profile.is_subscriber : false}
            allowPrize={user ? user.profile.is_subscriber : false}
          />
        </div>
        <br />
        <h6 className="text-center small even-duller">
          Next: Add Questions
          <br />
          <br />
          <span className="even-duller">1 of 4</span>
        </h6>
        <br />
      </Container>
      <br />
    </div>
  );
};

export default connectRoute(CreateGameRoute, "Create Game");
