import React from "react";
import Form from "react-bootstrap/Form";

import FinalFormWrapper, { FinalFormProps } from "./final-form-wrapper";
import GenericFieldWithFeedback, {
  GenericProps,
} from "./generic-field-with-feedback";

export interface SelectOption {
  value: string;
  display: string;
}

interface Props extends GenericProps {
  options: SelectOption[];
  includeBlank?: boolean;
  multiple?: boolean;
}

export const SelectFieldWithFeedback: React.FC<Props & FinalFormProps> = (
  props
) => {
  return (
    <GenericFieldWithFeedback
      {...props}
      renderInput={({ input }) => (
        <Form.Control
          {...input}
          value={!input.value && props.multiple ? [] : input.value}
          as="select"
          multiple={props.multiple}
        >
          {props.includeBlank ? <option /> : null}
          {props.options.map((option, i) => (
            <option key={i} value={option.value}>
              {option.display}
            </option>
          ))}
        </Form.Control>
      )}
    />
  );
};

export default FinalFormWrapper(SelectFieldWithFeedback, "select") as React.FC<
  Props
>;
