import React from "react";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/ListGroupItem";

import GenericForm from "./forms/generic-form";

interface Props {
  users: SimpleProfile[];
  addUser?: (user: string) => void; // Takes in a username
  removeUser?: (user: number) => void; // Takes in a user ID
}

/**
 * Renders a list of users, with optional controls.
 */
const UserList: React.FC<Props> = ({ addUser, removeUser, users }) => {
  return (
    <>
      <ListGroup>
        {users.map((user, i) => {
          return (
            <ListGroupItem key={i}>
              {user.username}&nbsp;&nbsp;
              {removeUser ? (
                <Button className="button" onClick={() => removeUser(user.id)}>
                  ❌ Remove
                </Button>
              ) : null}
            </ListGroupItem>
          );
        })}
      </ListGroup>
      {addUser ? (
        <GenericForm
          fields={[{ name: "username" }]}
          submitLabel="Add to Your Team"
          onSubmit={({ username }) => addUser(username)}
        />
      ) : null}
    </>
  );
};

export default UserList;
