import { API_ROOT } from "jumbo/lib/constants";
import { fetchWithHeaders } from "jumbo/lib/network";

/**
 * Some forms are filled in from the server. This file
 * allows components to request form data from the server
 * which will be loaded into redux state. Note that the filled
 * out forms are stored in react-final-form; this is only
 * for the shape of the form.
 */
export interface FormOptionsState {
  prop: {
    structured?: any;
  };
  error?: any;
}

const SET_FORM_OPTIONS = "@@jumbo/location/SET_FORM_OPTIONS";
const SET_FORM_OPTIONS_ERROR = "@@jumbo/location/SET_FORM_OPTIONS_ERROR";

const getFormOptions = () => {
  return async (dispatch: any) => {
    return fetchWithHeaders(`${API_ROOT}/props/create_structured`, {
      method: "OPTIONS",
    })
      .then((response) => response.json())
      .then((options) => dispatch(setFormOptions(options)))
      .catch((error) => {
        dispatch(setFormOptionsError(error));
        // Propegate the error for anyone who is waiting on this event.
        return Promise.reject(error);
      });
  };
};

const setFormOptions = (options: any) => {
  return {
    options,
    type: SET_FORM_OPTIONS,
  };
};

const setFormOptionsError = (error: Error) => {
  return {
    error,
    type: SET_FORM_OPTIONS_ERROR,
  };
};

const rootReducer = (state: FormOptionsState = { prop: {} }, action: any) => {
  switch (action.type) {
    case SET_FORM_OPTIONS:
      return { ...state, prop: { structured: action.options } };
    case SET_FORM_OPTIONS_ERROR:
      return { ...state, error: action.error };
    default:
      return state;
  }
};

const actions = { getFormOptions, setFormOptions, setFormOptionsError };
export { actions, rootReducer };
