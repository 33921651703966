import {
  faDiscord,
  faFacebook,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { faHandHoldingUsd } from "@fortawesome/pro-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { faTrophy } from "@fortawesome/pro-solid-svg-icons";
import { faWhistle } from "@fortawesome/pro-solid-svg-icons";
import { faPiggyBank } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { LinkContainer } from "react-router-bootstrap";
import { Link, Redirect } from "react-router-dom";

import Alert from "jumbo/components/alert";
import LeaderboardLastWeek from "jumbo/components/leaderboard-last-week";

import connectRoute, { ConnectedRouteProps } from "./connect-route";

export const LeaderboardLastWeekRoute: React.FC<ConnectedRouteProps> = ({
  store,
}) => {
  return (
    <div>
      <Alert className="alert-success-mod text-left" dismissible>
        <h5 style={{ marginTop: 15 }}>
          <b className="td-promo" style={{ marginTop: 20 }}>
            <FontAwesomeIcon icon={faHandHoldingUsd} className="td-promo" />{" "}
            &nbsp;&nbsp;Cashouts on{" "}
            {moment()
              .tz("America/New_York")
              .endOf("isoWeek")
              .subtract(1, "weeks")
              .add(2, "days")
              .format("ddd, MMM Do")}
          </b>
        </h5>
        <div
          className="binary details-text"
          style={{ marginTop: 15, marginBottom: 5 }}
        >
          Cashouts for the week ending{" "}
          {moment()
            .tz("America/New_York")
            .endOf("isoWeek")
            .subtract(1, "weeks")
            .format("dddd, MMMM Do")}{" "}
          will be paid out via PayPal on&nbsp;
          <b className="wager">
            {moment()
              .tz("America/New_York")
              .endOf("isoWeek")
              .subtract(1, "weeks")
              .add(2, "days")
              .format("dddd, MMMM Do")}{" "}
            by midnight EST.{" "}
          </b>
        </div>
      </Alert>

      <h4 className="text-left spinner-pink play-header">
        <Link to={`/leaderboard`} className="quiet-link">
          <FontAwesomeIcon icon={faChevronLeft} className="spinner-pink" />
        </Link>
        <h4 className="spinner-pink">&nbsp;&nbsp;&nbsp;</h4>
      </h4>
      <Container>
        <div style={{ paddingBottom: 15 }}>
          <div className="text-center" style={{ paddingTop: 0 }}>
            <div className="binary h1-companion" style={{ paddingTop: 10 }}>
              Last Week's Standings
            </div>
            <div className="nav-color-dull small-details-text text-center">
              {moment()
                .tz("America/New_York")
                .startOf("isoWeek")
                .subtract(1, "weeks")
                .format("dddd, MMM Do")}
              &nbsp;-&nbsp;
              {moment()
                .tz("America/New_York")
                .endOf("isoWeek")
                .subtract(1, "weeks")
                .format("dddd, MMM Do")}
            </div>

            <br />
          </div>
        </div>
        {store.users.currentUser ? (
          <LeaderboardLastWeek
            userName={store.users.currentUser.username}
            leaderboard={store.gameScore.leaderboard}
          />
        ) : (
          <LeaderboardLastWeek leaderboard={store.gameScore.leaderboard} />
        )}
        <br />

        <div className="text-left">
          <h6 className="even-duller" style={{ marginTop: 15 }}>
            Weekly Cashout Minimum Rules
          </h6>
          <p className="small even-duller text-left">
            All or nothing. You will receive your entire balance in USD on{" "}
            {moment()
              .tz("America/New_York")
              .endOf("isoWeek")
              .add(2, "days")
              .format("ddd, MMM DD")}
            &nbsp; if you are at or over the <b>Weekly Cashout Minimum</b> ($25)
            for games ending before{" "}
            {moment().endOf("isoWeek").format("ddd, MMM DD hh:mm A")} EST.
            &nbsp;
            <u>
              <a href="/rules" className="even-duller">
                Learn more.
              </a>
            </u>
          </p>
        </div>
        <h6 className="even-duller" style={{ marginTop: 15 }}>
          Legend
        </h6>
        <ul className="text-left">
          <li className="text-left small even-duller">
            <FontAwesomeIcon icon={faWhistle} className="gold-text" />
            &nbsp;&nbsp;—&nbsp;&nbsp;Mod
          </li>
          <li className="text-left small even-duller">
            GP&nbsp;&nbsp;—&nbsp;&nbsp;Games played (this week)
          </li>

          <li className="text-left small even-duller">
            <FontAwesomeIcon icon={faPiggyBank} />
            &nbsp;&nbsp;—&nbsp;&nbsp;Player's balance for the week.
          </li>
          <li className="text-left small nav-color-dull">
            EPG &nbsp;&nbsp;—&nbsp;&nbsp;Earnings Per Game
          </li>
        </ul>
        <hr className="cr" />
        <Row>
          <Col>
            {" "}
            <div className="text-center nav-footer">
              <a href="http://www.facebook.com/wagertownusa">
                <FontAwesomeIcon
                  icon={faFacebook}
                  size="lg"
                  className="star-opacity2"
                />
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <a href="http://twitter.com/wagertownusa/">
                <FontAwesomeIcon
                  icon={faTwitter}
                  size="lg"
                  className="star-opacity2"
                />
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <a href="https://discord.gg/VC7bzvdYGD">
                <FontAwesomeIcon
                  className="star-opacity2"
                  size="lg"
                  icon={faDiscord}
                />
              </a>
            </div>
          </Col>
        </Row>
        <hr />
        <br />
        <div className="text-center">
          <span className="even-duller small">
            “Always make a total effort, <br />
            even when the odds are against you.” <br />– Arnold Palmer
          </span>
        </div>
        <br />
      </Container>
    </div>
  );
};

export default connectRoute(LeaderboardLastWeekRoute, "Last Week", [
  (actions) =>
    actions.getLeaderboardGameScore(
      {},
      {
        query: {
          end_date: moment()
            .tz("America/New_York")
            .subtract(1, "weeks")
            .endOf("isoWeek")
            .toISOString(),
          start_date: moment()
            .tz("America/New_York")
            .subtract(1, "weeks")
            .startOf("isoWeek")
            .toISOString(),
        },
      }
    ),
]);
