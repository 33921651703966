import React from "react";

import GenericForm from "./generic-form";

export interface Props {
  onSubmit: (data: any) => void;
}
const HardcodedPropForm: React.FC<Props> = ({ onSubmit }) => {
  return (
    <GenericForm
      submitLabel="add question"
      onSubmit={(data) => {
        return onSubmit({
          config: data,
          prop_type: "hrd",
        });
      }}
      fields={[
        {
          label: "You asked:",
          name: "prompt",
          placeholder: "Start your question with 'Will'",
        },
      ]}
    />
  );
};

export default HardcodedPropForm;
