import { getStatsBatch } from "jumbo/lib/stats";

const START_GET_STATS = "@@jumbo/stats/START_GET_STATS";
const SET_STATS = "@@jumbo/stats/SET_STATS";

const getStats = (stats: any) => {
  return async (dispatch: any) => {
    dispatch(startGetStats());
    const data = await getStatsBatch(stats);
    dispatch(setStats(data));
  };
};

const setStats = (data: any) => {
  return {
    data,
    type: SET_STATS,
  };
};

const startGetStats = () => {
  return {
    type: START_GET_STATS,
  };
};

const setStatsReducer = (state: any, action: any) => {
  return {
    ...state,
    isFetching: false,
    stats: action.data,
  };
};

const startGetStatsReducer = (state: any) => {
  return {
    ...state,
    isFetching: true,
  };
};

const rootReducer = (
  state = {
    isFetching: false,
    stats: {},
  },
  action: any
) => {
  switch (action.type) {
    case SET_STATS:
      return setStatsReducer(state, action);
    case START_GET_STATS:
      return startGetStatsReducer(state);
  }
  return state;
};

const actions = { getStats };
export { actions, rootReducer };
