import { faWrench as falWrench } from "@fortawesome/pro-light-svg-icons";
import { faClipboardCheck as falClipboardCheck } from "@fortawesome/pro-light-svg-icons";
import { faGavel as falGavel } from "@fortawesome/pro-light-svg-icons";
import { faLock } from "@fortawesome/pro-solid-svg-icons";
import { faUserAlt } from "@fortawesome/pro-solid-svg-icons";
import { faCheckCircle as fasCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { faCheckCircle as falCheckCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import moment, { Moment } from "moment";
import React from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Card from "react-bootstrap/Card";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";

// import ShareButtonLong2 from "jumbo/components/share-button-long2";

import { GameTimeDisplay } from "./shared/game-time-display";
import StatsFragment from "./stats-fragment";

export interface Props {
  game: Game;
  stats?: Map<string, number>;
  user?: JumboUser;
  location?: {
    lat: number;
    lon: number;
  };
}

const GameListItem: React.FC<Props> = ({ game, user, location, stats }) => {
  if (game.game_scores.length > 1) {
    const sortedScores = game.game_scores
      .map((x) => x.score)
      .sort((a, b) => b - a);
  }

  const isSubscriber = user && user.profile.is_subscriber === true;
  const isGameRunning = game.end_time
    ? new Date(game.end_time) > new Date()
    : true;

  const isGameAlmostOver =
    game.end_time &&
    moment().toISOString() >
      moment(game.end_time).subtract(1, "hours").toISOString();

  const date = Date.now();
  const modTime = moment(game.end_time).add(6, "hours").toISOString();

  const isModEligible =
    game.end_time &&
    moment(game.end_time).add(1, "hours").toISOString() >
      moment().toISOString();

  const isOwner = user && game.user.id === user.id;
  const isAdmin = user && user.profile.is_admin;

  return (
    <Card className="jumbo-card">
      {user ? (
        <>
          {game.is_scored ? (
            <>
              <div className="text-center graded-container">
                <StatsFragment
                  statDefinition={{
                    game: game.id,
                    name: "my_game_score",
                    user: user.id,
                  }}
                  render={(value) => {
                    return (
                      <>
                        {value > 0 ? (
                          <div>
                            <br />
                            <div className="binary text-center small-details-text">
                              <span
                                className="nav-color"
                                style={{ marginTop: 3 }}
                              >
                                <div
                                  className="nav-love"
                                  style={{ marginLeft: 5, marginRight: 5 }}
                                >
                                  <FontAwesomeIcon
                                    className="nav-love"
                                    icon={falGavel}
                                  />{" "}
                                  &nbsp;&nbsp;{game.name}
                                </div>
                              </span>

                              <div style={{ marginTop: 5 }}>
                                <span>
                                  <div className="wager-text td-promo">
                                    <b>
                                      +$
                                      {((1 * value) / 100)
                                        .toFixed(2)
                                        .toLocaleString()}
                                    </b>
                                  </div>
                                  <div
                                    className="small-host-text nav-color-dull"
                                    style={{ marginTop: 2 }}
                                  >
                                    was added to <b>Your Balance</b>!
                                  </div>
                                </span>
                              </div>
                              <br />
                              <div>
                                <Button
                                  className="logout-button9 w-75"
                                  style={{ padding: 7, marginTop: 5 }}
                                >
                                  <LinkContainer
                                    to={`/game/${game.id}/details`}
                                  >
                                    <div className="nav-love jeopardy small">
                                      see how it was graded
                                    </div>
                                  </LinkContainer>
                                </Button>
                              </div>
                            </div>

                            <div
                              style={{ marginTop: 7 }}
                              className="text-center small"
                            >
                              {isAdmin ? (
                                <>
                                  {isOwner ? (
                                    <LinkContainer
                                      to={`/game/${game.id}/grade`}
                                    >
                                      <span className="star-opacity2 small text-center jeopardy">
                                        regrade
                                      </span>
                                    </LinkContainer>
                                  ) : (
                                    <LinkContainer
                                      to={`/game/${game.id}/grade`}
                                    >
                                      <span className="star-opacity2 small text-center jeopardy">
                                        regrade
                                      </span>
                                    </LinkContainer>
                                  )}
                                </>
                              ) : (
                                <>
                                  {isOwner ? (
                                    <LinkContainer
                                      to={`/game/${game.id}/grade`}
                                    >
                                      <span className="star-opacity2 small text-center jeopary">
                                        regrade
                                      </span>
                                    </LinkContainer>
                                  ) : null}
                                </>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className="binary">
                              <br />
                              <div className="binary text-center small-details-text">
                                <span
                                  className="nav-color-dull"
                                  style={{ marginTop: 3 }}
                                >
                                  <div
                                    className="nav-love"
                                    style={{ marginLeft: 5, marginRight: 5 }}
                                  >
                                    <FontAwesomeIcon
                                      className="nav-love"
                                      icon={falGavel}
                                    />{" "}
                                    &nbsp;&nbsp;
                                    {game.name}
                                  </div>
                                </span>

                                <div style={{ marginTop: 5 }}>
                                  <span>
                                    <div className="wager-text td-negative">
                                      <b>
                                        -$
                                        {((-1 * value) / 100)
                                          .toFixed(2)
                                          .toLocaleString()}
                                      </b>
                                    </div>
                                    <div
                                      className="small-host-text nav-color-dull"
                                      style={{ marginTop: 2 }}
                                    >
                                      was deducted from <b>Your Balance</b>...
                                    </div>
                                  </span>
                                </div>
                                <br />
                                <div>
                                  <Button
                                    className="logout-button9 w-75"
                                    style={{ padding: 7, marginTop: 5 }}
                                  >
                                    <LinkContainer
                                      to={`/game/${game.id}/details`}
                                    >
                                      <div className="nav-love jeopardy small">
                                        see how it was graded
                                      </div>
                                    </LinkContainer>
                                  </Button>
                                </div>
                              </div>

                              <div
                                style={{ marginTop: 7 }}
                                className="text-center small"
                              >
                                {isAdmin ? (
                                  <>
                                    {isOwner ? (
                                      <LinkContainer
                                        to={`/game/${game.id}/grade`}
                                      >
                                        <span className="star-opacity2 small text-center jeopardy">
                                          regrade
                                        </span>
                                      </LinkContainer>
                                    ) : (
                                      <LinkContainer
                                        to={`/game/${game.id}/grade`}
                                      >
                                        <span className="star-opacity2 small text-center jeopardy">
                                          regrade
                                        </span>
                                      </LinkContainer>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {isOwner ? (
                                      <LinkContainer
                                        to={`/game/${game.id}/grade`}
                                      >
                                        <span className="star-opacity2 small text-center jeopardy">
                                          regrade
                                        </span>
                                      </LinkContainer>
                                    ) : null}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  }}
                />
              </div>
            </>
          ) : null}
        </>
      ) : null}
      {!game.is_scored ? (
        <>
          {!isGameRunning ? (
            <>
              <LinkContainer to={`/game/${game.id}/`}>
                <div className="text-center scored-container">
                  <br />
                  <br />

                  <span
                    className="spinner-border spinner-border spinner-pink"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <br />
                  <br />
                  <div
                    className="small-details-text even-duller"
                    style={{ paddingLeft: 10, paddingRight: 10 }}
                  >
                    <b className="nav-love">{game.name}</b>
                    <br />
                    <span className="nav-color-dull">
                      &nbsp;by&nbsp;<b>{game.user.username}</b>&nbsp;is now
                      underway!
                    </span>
                  </div>
                </div>
              </LinkContainer>
            </>
          ) : null}
        </>
      ) : null}

      <Card.Body className="card-body " style={{ marginBottom: 0 }}>
        <Link to={`/game/${game.id}/play`}>
          <div style={{ paddingTop: 5 }}>
            <div>
              <div className="binary text-left" style={{ paddingTop: 5 }}>
                <div
                  className="binary text-center small-details-text"
                  style={{ marginTop: 10 }}
                ></div>
                <h5
                  className="text-center"
                  style={{ paddingBottom: 3, paddingTop: 5 }}
                >
                  {game.name}
                </h5>
                <div className="text-center even-duller">
                  {isGameAlmostOver ? (
                    <div className="red-text">
                      <small>
                        <b>
                          Last call! <GameTimeDisplay endTime={game.end_time} />
                        </b>
                      </small>
                    </div>
                  ) : (
                    <small>
                      {game.theme === "🏈 NFL" ? (
                        "Kickoff in"
                      ) : (
                        <>
                          {game.theme === "🏈 NCAA" ? (
                            "Kickoff in"
                          ) : (
                            <>
                              {game.theme === "🏀 NBA" ? (
                                "Tipoff in"
                              ) : (
                                <>
                                  {game.theme === "🏀 NCAA" ? (
                                    "Tipoff in"
                                  ) : (
                                    <>
                                      {game.theme === "⚾ MLB" ? (
                                        "First pitch in"
                                      ) : (
                                        <>
                                          {game.theme === "⚾ NCAA" ? (
                                            "First pitch in"
                                          ) : (
                                            <>
                                              {game.theme === "🏒 NHL" ? (
                                                "Puck drop in"
                                              ) : (
                                                <>
                                                  {game.theme === "🏒 NCAA" ? (
                                                    "Puck drop in"
                                                  ) : (
                                                    <>
                                                      {game.theme ===
                                                      "⛳ Golf" ? (
                                                        "Tee time in"
                                                      ) : (
                                                        <>Starting in</>
                                                      )}
                                                    </>
                                                  )}
                                                </>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}{" "}
                      <GameTimeDisplay endTime={game.end_time} />
                    </small>
                  )}
                </div>
              </div>
            </div>
          </div>
          <br />
        </Link>
        <div className="text-right" style={{ paddingTop: 5 }}>
          {user ? (
            <StatsFragment
              statDefinition={{
                game: game.id,
                name: "total_amount_wagered",
                user: user.id,
              }}
              render={(value) => {
                return (
                  <div className="text-right">
                    <>
                      {value !== 0 ? (
                        <div>
                          <Link to={`/game/${game.id}/play`}>
                            <Button className="logout-button-i text-center w-100">
                              <div
                                className="wager lobbytext  text-center"
                                style={{ padding: 5, paddingTop: 8 }}
                              >
                                <b>Your picks are in!</b>
                              </div>
                            </Button>
                          </Link>
                        </div>
                      ) : (
                        <div>
                          <Link to={`/game/${game.id}/play`}>
                            <Button className="logout-button-p text-center  w-100">
                              <div
                                className=" lobbytext text-center nav-color"
                                style={{ padding: 5, paddingTop: 8 }}
                              >
                                <b>Make your free picks</b>
                              </div>
                            </Button>
                          </Link>
                        </div>
                      )}
                    </>
                  </div>
                );
              }}
            />
          ) : (
            <div>
              <Link to={`/game/${game.id}/play`}>
                <Button className="logout-button-green text-right  w-100">
                  <div
                    className="  text-center"
                    style={{ padding: 5, paddingTop: 8 }}
                  >
                    <b>Make your free picks</b>
                  </div>
                </Button>
              </Link>
            </div>
          )}
        </div>

        <div style={{ marginBottom: 15, marginTop: 15 }}>
          <div className="small-host-text2 text-center even-duller">
            <div className="even-duller text-center">
              <span className="even-duller small-host-text">
                <FontAwesomeIcon className="even-duller" icon={faUserAlt} />
                &nbsp;&nbsp;&nbsp;
                {game.user.username}
              </span>
            </div>
          </div>
        </div>
      </Card.Body>

      {!game.is_scored ? (
        <>
          {isGameRunning ? (
            <div></div>
          ) : (
            <>
              {isAdmin ? (
                <>
                  {isOwner ? (
                    <ButtonGroup
                      className="w-100 leaderboard-header"
                      style={{
                        paddingBottom: 0,
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 0,
                      }}
                    >
                      <Button className="gli-button w-50">
                        <LinkContainer to={`/game/${game.id}/grade`}>
                          <b className="wager lobbytext">
                            <FontAwesomeIcon
                              className="wager"
                              icon={falGavel}
                            />{" "}
                            &nbsp;Grade
                          </b>
                        </LinkContainer>
                      </Button>
                      <Button className="gli-button w-50">
                        <LinkContainer to={`/game/${game.id}/edit`}>
                          <b className="nav-jumbo-link-2 lobbytext">
                            <FontAwesomeIcon
                              className="nav-jumbo-link-2"
                              icon={falWrench}
                            />{" "}
                            &nbsp;Modify
                          </b>
                        </LinkContainer>
                      </Button>
                    </ButtonGroup>
                  ) : (
                    <>
                      {!isModEligible ? (
                        <>
                          <ButtonGroup
                            className="w-100 leaderboard-header"
                            style={{
                              paddingBottom: 0,
                              paddingLeft: 10,
                              paddingRight: 10,
                              paddingTop: 0,
                            }}
                          >
                            <Button className="gli-button w-50">
                              <LinkContainer to={`/game/${game.id}/grade`}>
                                <b className="spinner-pink lobbytext">
                                  <FontAwesomeIcon
                                    className="spinner-pink"
                                    icon={falGavel}
                                  />{" "}
                                  &nbsp;Grade
                                </b>
                              </LinkContainer>
                            </Button>

                            <Button className="gli-button w-50">
                              <LinkContainer to={`/game/${game.id}/edit`}>
                                <b className="spinner-pink lobbytext">
                                  <FontAwesomeIcon
                                    className="spinner-pink"
                                    icon={falWrench}
                                  />{" "}
                                  &nbsp;Modify
                                </b>
                              </LinkContainer>
                            </Button>
                          </ButtonGroup>
                        </>
                      ) : (
                        <ButtonGroup
                          className="w-100 leaderboard-header"
                          style={{
                            paddingBottom: 0,
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 0,
                          }}
                        >
                          <Button className="gli-button w-100">
                            <b className="even-duller lobbytext">
                              <FontAwesomeIcon
                                className="even-duller"
                                icon={faLock}
                              />
                              &nbsp;&nbsp;
                              <GameTimeDisplay endTime={modTime} />
                            </b>
                          </Button>
                        </ButtonGroup>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {isOwner ? (
                    <ButtonGroup
                      className="w-100 leaderboard-header"
                      style={{
                        paddingBottom: 0,
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 0,
                      }}
                    >
                      <Button className="gli-button w-50">
                        <LinkContainer to={`/game/${game.id}/grade`}>
                          <b className="spinner-pink lobbytext">
                            <FontAwesomeIcon
                              className="spinner-pink"
                              icon={falGavel}
                            />{" "}
                            &nbsp;Grade
                          </b>
                        </LinkContainer>
                      </Button>
                      <Button className="gli-button w-50">
                        <LinkContainer to={`/game/${game.id}/edit`}>
                          <b className="spinner-pink lobbytext">
                            <FontAwesomeIcon
                              className="spinner-pink"
                              icon={falWrench}
                            />{" "}
                            &nbsp;Modify
                          </b>
                        </LinkContainer>
                      </Button>
                    </ButtonGroup>
                  ) : (
                    <ButtonGroup
                      className="w-100 leaderboard-header"
                      style={{
                        paddingBottom: 0,
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 5,
                      }}
                    ></ButtonGroup>
                  )}
                </>
              )}
            </>
          )}
        </>
      ) : null}
    </Card>
  );
};

export default GameListItem;
