import moment from "moment";
import React from "react";

import GenericForm from "./generic-form";

export interface Props {
  onSubmit: (data: any) => void;
  allowPrize: boolean;
  allowCode: boolean;
  game?: Game;
}

const GameForm: React.FC<Props> = ({
  onSubmit,
  game,
  allowPrize,
  allowCode,
}) => {
  const initialValues =
    game !== undefined
      ? game
      : {
          bar_owned: true,
          end_time: moment().add(1, "day").toDate(),
        };
  return (
    <GenericForm
      onSubmit={onSubmit}
      submitLabel="publish game"
      initialValues={initialValues}
      fields={[
        {
          label: "Name your game",
          name: "name",
          placeholder: "e.g. Niners vs. Dolphins",
        },
        {
          label: "Pick the category for your game",
          name: "theme",
          options: [
            { value: "🏈 NFL", display: "🏈 NFL" },
            { value: "🏈 NCAA", display: "🏈 NCAA" },
            { value: "🏀 NBA", display: "🏀 NBA" },
            { value: "🏀 NCAA", display: "🏀 NCAA" },
            { value: "⚾ MLB", display: "⚾ MLB" },
            { value: "⚾ NCAA", display: "⚾ NCAA" },
            { value: "🏒 NHL", display: "🏒 NHL" },
            { value: "🏒 NCAA", display: "🏒 NCAA" },
            { value: "🐎 Horse Racing", display: "🐎 Horse Racing" },
            { value: "🎾 Tennis", display: "🎾 Tennis" },
            { value: "🥊 Boxing", display: "🥊 Boxing" },
            { value: "⛳ Golf", display: "⛳ Golf" },
            { value: "⚽ Soccer", display: "⚽ Soccer" },
            { value: "🎮 Sports Sims", display: "🎮 Sports Sims" },
            { value: "🏎️ Auto Racing", display: "🏎️ Auto Racing" },
            { value: "🎯 Darts", display: "🎯️ Darts" },
            { value: "🚴 Cycling", display: "🚴 Cycling" },
            { value: "🤼 MMA", display: "🤼 MMA" },
            { value: "🤼 WWE", display: "🤼 WWE" },
            { value: "🎰 Lottery", display: "🎰 Lottery" },
            { value: "📺 TV Shows", display: "📺 TV Shows" },
            { value: "📈 Markets", display: "📈 Markets" },
            { value: "🤣 Just for Fun", display: "🤣 Just for Fun" },
          ],
          type: "select",
        },
        {
          label: "When should last wagers be accepted? (EST)",
          name: "end_time",
          type: "datetime",
        },
      ]}
    />
  );
};

export default GameForm;
