import React from "react";
import { Field } from "react-final-form";

// Props that are not exposed on the wrapped component.
export interface FinalFormProps {
  input: any;
  meta: any;
}

// Props that are exposed on the wrapped component, and used by the wrapper.
export interface SharedProps {
  name: string;
  validate?: (value: any) => undefined | string;
}

/**
 * This is a higher order component that will wrap a GenericField into something that
 * react-final-form can use.
 */
function FinalFormWrapper<T>(
  FieldComponent: React.ComponentType<T & FinalFormProps & SharedProps>,
  type?: string
) {
  const WrappedComponent: React.FC<T & SharedProps> = (props) => {
    return (
      <Field name={props.name} type={type} validate={props.validate}>
        {({ input, meta }) => (
          <FieldComponent input={input} meta={meta} {...props} />
        )}
      </Field>
    );
  };
  return WrappedComponent;
}

export default FinalFormWrapper;
