import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import FinalFormWrapper, { FinalFormProps } from "./final-form-wrapper";
import GenericFieldWithFeedback, {
  GenericProps,
} from "./generic-field-with-feedback";

type Props = GenericProps;

const getUrlString = (value: string | File): string => {
  if (typeof value === "string") {
    return value;
  }
  return URL.createObjectURL(value);
};

/**
 * The image field can take in either a file upload or a URL. The input.value
 * will be set to either File or string, and it's up to the form to properly handle
 * that when processing.
 */
export const ImageFieldWithFeedback: React.FC<Props & FinalFormProps> = (
  props
) => {
  return (
    <GenericFieldWithFeedback
      {...props}
      renderInput={({ input }) => {
        const isString = input.value && typeof input.value === "string";
        const previewURL = input.value ? getUrlString(input.value) : null;
        return (
          <>
            <Form.Row>
              <Col className="custom-file">
                <Form.Control
                  type="file"
                  className="custom-file-input"
                  onChange={({ target }: any) => {
                    if (target.files) {
                      input.onChange(target.files[0]);
                    }
                  }}
                />
                <Form.Label className="custom-file-label text-left">
                  {input.value && !isString ? input.value.name : "Choose file"}
                </Form.Label>
              </Col>
            </Form.Row>
            {previewURL ? (
              <div className="text-center">
                <img
                  className="preview-image"
                  src={previewURL}
                  alt="Can't Load Image"
                />
              </div>
            ) : null}
          </>
        );
      }}
    />
  );
};

export default FinalFormWrapper(ImageFieldWithFeedback) as React.FC<Props>;
