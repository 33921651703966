import React from "react";
import BootstrapForm from "react-bootstrap/Form";
import { Form } from "react-final-form";
import { Link } from "react-router-dom";

import userService from "jumbo/lib/user-service";

import InputFieldWithFeedback from "./fields/input-field-with-feedback";
import { convertDjangoErrors } from "./utils";

export interface Props {
  onRegistrationComplete?: () => void;
}
const RegisterForm: React.FC<Props> = ({ onRegistrationComplete }) => {
  return (
    <Form
      onSubmit={(data: {
        email: string;
        username: string;
        password1: string;
        password2: string;
      }) => {
        return userService
          .register(data.email, data.username, data.password1, data.password2)
          .then(onRegistrationComplete, convertDjangoErrors);
      }}
      render={({ handleSubmit, submitError }) => {
        return (
          <form
            onSubmit={handleSubmit}
            id="registration-form"
            className="binary small-details-text"
          >
            <InputFieldWithFeedback
              name="email"
              label="Email"
              placeholder="Email"
            />
            <InputFieldWithFeedback
              name="username"
              label="What should everyone call you?"
              placeholder="Username"
            />
            <InputFieldWithFeedback
              label="Password"
              name="password1"
              type="password"
            />
            <InputFieldWithFeedback
              label="Confirm Password"
              name="password2"
              type="password"
            />
            <br />
            <button
              type="submit"
              className="btn btn-primary w-100 font-weight-bold btn-lg pink-bet-button2 jeopardy"
            >
              register
            </button>
            <br />
            <br />

            <BootstrapForm.Text className="text-danger">
              {submitError}
            </BootstrapForm.Text>
          </form>
        );
      }}
    />
  );
};

export default RegisterForm;
