import React from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Container from "react-bootstrap/Container";
import { LinkContainer } from "react-router-bootstrap";

import GroupDetails from "jumbo/components/group-details";
import UserList from "jumbo/components/user-list";

import connectRoute, { ConnectedRouteProps } from "./connect-route";

export const GroupRoute: React.FC<ConnectedRouteProps> = ({
  store,
  actions,
}) => {
  const group: JumboGroup = store.groups.item;
  const user: JumboUser | undefined = store.users.currentUser;

  const members = group.users;
  const isInGroup = user
    ? members.filter((x) => x.id === user.id).length > 0
    : false;
  const isGroupOwner = user ? group.user === user.id : false;

  const addUser = async (username?: string) => {
    await actions.joinGroup(group.id, { body: JSON.stringify({ username }) });
    actions.getGroup(group.id);
    actions.getCurrentUser();
  };

  const removeUser = async (userId: number) => {
    await actions.leaveGroup(group.id, { body: JSON.stringify({ userId }) });
    actions.getGroup(group.id);
    actions.getCurrentUser();
  };

  return (
    <>
      <Container>
        {isGroupOwner ? (
          <Alert variant="success">
            Add friends to your team to pool your 💎
          </Alert>
        ) : null}
        <h4>{group.name}</h4>
        <ButtonGroup>
          {user ? (
            !isInGroup ? (
              <Button
                className="bet-button"
                onClick={() => addUser(user.username)}
              >
                Join Team
              </Button>
            ) : (
              <Button className="button" onClick={() => removeUser(user.id)}>
                Leave Team
              </Button>
            )
          ) : null}
          {isGroupOwner ? (
            <LinkContainer to={`/team/${group.id}/edit`}>
              <Button className="button">Edit Team Rules</Button>
            </LinkContainer>
          ) : null}
        </ButtonGroup>
        <br />
        <br />
        <h5>Team members</h5>
        <UserList
          addUser={isGroupOwner ? addUser : undefined}
          removeUser={isGroupOwner ? removeUser : undefined}
          users={members}
        />
        <br />
        <br />
        <h5>Team rules</h5>
        <GroupDetails group={group} />
        <br />
      </Container>
    </>
  );
};

export default connectRoute(
  GroupRoute,
  (props) => `Group ${props.store.groups.item.name}`,
  [(actions, match) => actions.getGroup(+match.params.id)]
);
