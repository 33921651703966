/**
 * Sends an event with a timeout.
 * Returns a promise that will resolve when either the timeout has elapsed or
 * the event has been fired.
 */
export const sendEventWithTimeout = (
  evt: string,
  params: any,
  timeout: number
) => {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout);
    gtag("event", evt, {
      ...params,
      event_callback: resolve,
    });
  });
};

export const sendEvent = (evt: string, params: any) => {
  gtag("event", evt, {
    ...params,
  });
};
